import Field from './Field';
import { FieldType, FieldDataType } from '../reporting/types';
import TemporalDimension from './TemporalDimension';
import BulkItem from './BulkItem';
import moment from 'moment';
import BulkItemFactory from '@sprinklr/stories/analytics/BulkItemFactory';
import PostItem from '@sprinklr/stories/analytics/PostItem';
import LookupItem from '@sprinklr/stories/analytics/LookupItem';
import { AnalyticsEngine } from './AnalyticsRequest';
import { normalizeCasingAndSpacing } from '@sprinklr/display-builder/utils/NormalizeLabel/NormalizeLabel';

export default class Dimension extends Field<string> {
    // used if name is overridden by  a display name
    dimensionName: string;
    reportingEngine: AnalyticsEngine;

    constructor(name: string, type?: FieldType, dataType?: FieldDataType) {
        super(
            name as any,
            typeof type !== 'undefined' && type !== null ? type : 'STRING',
            dataType
        );
    }

    isDate(): boolean {
        return this.type === 'DATE' || this.type === 'TIMESTAMP';
    }

    cast(value: any): any {
        switch (
            normalizeCasingAndSpacing(this.dimensionName) ||
            normalizeCasingAndSpacing(this.name)
        ) {
            case 'MONTH_OF_YEAR':
            case 'DAY_OF_WEEK':
            case 'TIME_OF_DAY':
                let stringValue = value;
                if (value instanceof BulkItem && value.toString) {
                    stringValue = value.toString();
                } else if (value.name) {
                    stringValue = value.name;
                }
                const name = this.dimensionName || this.name;
                return new TemporalDimension(stringValue, stringValue, name);
            default:
            // No action
        }

        if (value instanceof BulkItem) {
            return value;
        }

        if (typeof value === 'object' && !(value instanceof Date)) {
            const instance = PostItem.fromObject(value);
            if (instance) {
                return instance;
            }

            if (this.dimensionName) {
                const bulkItem = BulkItemFactory.factory(
                    value,
                    this.dimensionName,
                    undefined,
                    this.reportingEngine
                );
                if (bulkItem instanceof BulkItem) {
                    return bulkItem;
                }
            }

            // Fallback
            // It seems this can't be added to BulkItemFactory.factory because it would subvert
            // all the crazy in SprinklrAPIService.applyDimensionLookups...
            const lookupItem = LookupItem.fromObject(value);
            if (lookupItem) {
                return lookupItem;
            }
        }

        return super.cast(value);
    }

    stringify(value: any, timeFormat?: string, timeZone?: string): string {
        if (value === undefined || value === null || value === '') {
            return '';
        }

        if (value instanceof BulkItem) {
            return value.toString();
        }

        // Override format for quarters and years
        if (timeFormat) {
            if (this.name.indexOf('_1q') !== -1) {
                timeFormat = '[Q] Q, YY';
            } else if (this.name.indexOf('_1y') !== -1) {
                timeFormat = 'YYYY';
            }
        }

        switch (this.type) {
            case 'DATE':
            case 'TIMESTAMP': // Must be handled as date string for linechart, groupby weekly
                if (value instanceof Date || moment.isMoment(value)) {
                    if (!timeFormat) {
                        return value.toISOString();
                    } else {
                        return moment.tz(value, timeZone).format(timeFormat);
                    }
                }

            // fall through
            default:
            case 'TIME_INTERVAL':
            case 'NUMBER':
            case 'DECIMAL':
            case 'INTEGER':
            case 'STRING':
                return value + '';
        }
    }

    isTimeSeries(): boolean {
        if (this.type === 'DATE' || this.type === 'TIMESTAMP') {
            return true;
        }

        return false;
    }

    isClientSortDimension(): boolean {
        const name = this.dimensionName || this.name;

        switch (name.toUpperCase()) {
            case 'MONTH_OF_YEAR':
            case 'DAY_OF_WEEK':
            case 'DAY_OF_TIME_RANGE':
            case 'MONTH OF YEAR':
            case 'DAY OF WEEK':
            case 'DAY OF TIME RANGE':
                return true;
            default:
                return false;
        }
    }
}
