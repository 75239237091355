import {
    AxisOptions,
    BaseXYAxisChartOptions,
    Formats,
    LegendOptions,
    MetaInfoOptions,
} from '@sprinklr/stories/hyperspaceWidgets/types';
import { DataLabelOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import { action, observable } from 'mobx';
import { BubbleOptions } from './HyperSpaceBubblePlotChartWidget/types';

export class LegendOptionsImpl implements LegendOptions {
    @observable enabled = true;
    @observable maxWidth = 300;
    @observable metric: LegendOptions['metric'] = {
        enabled: true,
        numberFormat: '0.[00]a',
        // leaving these commented out as we currently use the defaults and don't expose tunables
        // key: string;
        // aggregation: 'sum' | 'max';
    };
    @observable position: LegendOptions['position'] = {
        auto: true,
        selectedPosition: 'top',
    };
    @observable size = 20;
    @observable glyph: LegendOptions['glyph'] = {
        shape: 'square',
        size: 20,
        border: {
            enabled: false,
            size: 3,
        },
        metaInfo: new MetaInfoOptionsImpl(),
    };
    @observable formats: LegendOptions['formats'] = {
        numberFormat: {
            useShortened: true,
            decimals: 2,
        },
    };
    @action
    setValues(opts?: any): this {
        if (opts) {
            Object.assign(<any>this, opts);
        }
        return this;
    }
}

export class BaseXYAxisChartOptionsImpl implements BaseXYAxisChartOptions {
    @observable dataLabels: DataLabelOptions = new DataLabelsOptionsImpl();
    @observable overflow = true;
    @observable grid = {
        enabled: false,
        color: '',
        size: 1,
    };
    @observable xAxis = new XAxisImpl();
    @observable yAxis = new YAxisImpl();
    setValues(opts?: any): this {
        if (opts) {
            Object.assign(<any>this, opts);
        }
        return this;
    }
}

export class DataLabelsOptionsImpl implements DataLabelOptions {
    @observable enabled = false;
    @observable formats = {
        numberFormat: {
            decimals: 0,
            useShortened: true,
            percentage: false,
        },
    };
    @observable fontSize = 13;
    @observable opacity = 100;
    @observable alwaysShow = false;
}

export class BubbleOptionsImpl implements BubbleOptions {
    @observable enabled = true;
    @observable opacity = 100;
    @observable border = {
        enabled: true,
        size: 4,
    };
    @observable radius = {
        min: 10,
        max: 50,
    };
}

export class MetaInfoOptionsImpl implements MetaInfoOptions {
    @observable color = {
        enabled: false,
    };
    @observable image = {
        enabled: false,
        opacity: 100,
    };
}
export class XAxisImpl implements AxisOptions {
    @observable ticks = {
        enabled: true,
        formats: new FormatsImpl(),
        numTicks: 10,
        size: 20,
        angle: {
            auto: true,
            enabled: true,
            degrees: 45,
        },
    };
    @observable enabled = true;
    @observable label = {
        enabled: true,
        size: 25,
        padding: 15,
    };
    @observable line = {
        enabled: false,
        stroke: '',
        strokeWidth: 1,
    };
}
export class YAxisImpl implements AxisOptions {
    @observable ticks = {
        enabled: true,
        formats: new FormatsImpl(),
        numTicks: 5,
        size: 20,
    };
    @observable enabled = true;
    @observable label = {
        enabled: true,
        size: 25,
        padding: 15,
    };
    @observable line = {
        enabled: false,
        stroke: '',
        strokeWidth: 1,
    };
}

export class FormatsImpl implements Formats {
    @observable numberFormat = {
        decimals: 0,
        useShortened: true,
        percentage: false,
    };
    @observable dateFormat = {
        autoFormat: true,
        formatString: 'dd MMM',
    };
}
