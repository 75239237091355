import BulkItem from './BulkItem';

export default class SLAFrequency extends BulkItem {
    // e.g., 1 hour to 3 hours
    // 'less than' and 'greater than' values represented by 0 and '*', respectively.
    objective: number;

    constructor(
        id: string,
        name: string,
        public from: number = null, // microseconds
        public to: number | '*' = null // microseconds or '*'
    ) {
        super(id, name);
    }

    public static fromObject(object: any): SLAFrequency {
        if (!object.from) {
            const interval = object.id.split('-');
            if (interval.length !== 2) {
                console.warn(`SLAFrequency ${JSON.stringify(object)} missing interval in id`);
            } else {
                const from = parseInt(interval[0], 10);
                if (!isNaN(from)) {
                    object.from = from;
                }
                const to = parseInt(interval[1], 10);
                if (!isNaN(to)) {
                    object.to = to;
                }
            }
        }

        return new SLAFrequency(object.id, object.name, object.from, object.to);
    }

    toString(): string {
        return this.name;
    }

    get sortValue(): number {
        return this.from;
    }

    get hasObjective(): boolean {
        return !!this.objective;
    }

    get objectiveMet(): boolean {
        const from = typeof this.from === 'number' ? this.from : parseInt(this.from);
        return this.objective > from;
    }
}
