import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import {
    ClassOverrides,
    PanelOrientation,
    SmartSize,
    TimeStampOptions,
    VAlign,
} from '@sprinklr/stories/widget/types';
import { Clamp, ProfileMetric, ProfileMetricItem, ProfileMetrics, Rank } from './types';
import { observable } from 'mobx';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { FontCase } from 'models/Theme/Theme';

export interface ProfileWidgetOptions extends WidgetOptions {
    classNames?: string;
    numbProfiles?: number;
    primaryMetric?: ProfileMetric;
    primaryMetricLabel?: string;
    secondaryMetric?: ProfileMetric;
    secondaryMetricLabel?: string;
    profileMetrics?: ProfileMetrics;
    showDescription?: boolean;
    showIcon?: boolean;
    panelOrientation?: PanelOrientation;
    showMetricValue?: boolean;
    showMetricName?: boolean;
    socialIconBgColor?: boolean;
    rank?: Rank;
    clamp?: Clamp;
    verticalAlign?: VAlign;
}

export class ProfileMetricsImpl implements ProfileMetrics {
    @observable enabled = false;
    @observable hideZero = false;
    @observable items: ProfileMetricItem[] = [
        {
            label: 'Favorites',
            value: 'favCount',
            alternateLabel: {
                enabled: false,
                value: 'Favorites',
            },
        },
        {
            label: 'Followers',
            value: 'followers',
            alternateLabel: {
                enabled: false,
                value: 'Followers',
            },
        },
    ];
}

export class ProfileWidgetOptionsImpl extends WidgetOptionsImpl implements ProfileWidgetOptions {
    @observable labelSize = 20;

    @observable showMetricName = true;
    @observable metricNameSize = 15;

    @observable showMetricValue = true;
    @observable metricValueSize = 50;
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';

    @observable showMetricChange = true;
    @observable metricPercentChangeSize = 20;
    @observable highlightPercentChange: boolean;

    @observable showUserGroup = true;
    @observable showUserAvatar = true;
    @observable showUserName = true;
    @observable showUserScreenName = true;
    @observable rank: Rank = {
        enabled: false,
        size: 20,
    };
    @observable userGroupIconSize = 10;
    @observable userGroupAvatarSize = 10;
    @observable userGroupNameSize = 14;
    @observable userGroupScreenNameSize = 10;
    @observable showProfileDescription = true;
    @observable profileDescriptionSize = 19;
    @observable verticalAlign: VAlign = 'middle';

    @observable profileMetrics: ProfileMetrics = new ProfileMetricsImpl();
    @observable clamp: Clamp = {
        enabled: false,
        lines: 5,
    };

    @observable showTimeStamp = false;
    @observable timeStampSize = 15;
    @observable timeStampFormat: TimeStampOptions = 'timeAgo';
    @observable endianTime = 'D MMM YYYY, h:mm a';
    @observable socialIconBgColor = false;
    @observable classOverrides: ClassOverrides = {
        profileInfo: 'p-1 pl-2',
        profileMetrics: 'pb-1 pt-2',
    };
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            metricPercentChangeSize: 20,
            metricValueSize: 50,
            metricNameSize: 15,
            timeStampSize: 15,
            labelSize: 20,
            userGroupIconSize: 10,
            userGroupAvatarSize: 70,
            userGroupNameSize: 14,
            userGroupScreenNameSize: 10,
            profileDescriptionSize: 19,
            rank: {
                size: 20,
            },
        },
        optionDefaults: {
            clamp: {
                lines: 5,
                enabled: true,
            },
            classOverrides: {
                profileInfo: 'p-1 pl-2',
                profileMetrics: 'pb-1 pt-2',
            },
        },
        breakpoints: {
            xs: {
                options: {
                    clamp: {
                        lines: 3,
                        enabled: true,
                    },
                    classOverrides: {
                        profileInfo: 'p-05',
                        profileMetrics: 'p-05',
                    },
                },
            },
            s: {
                options: {
                    clamp: {
                        lines: 3,
                        enabled: true,
                    },
                    classOverrides: {
                        profileInfo: 'p-05',
                        profileMetrics: 'p-05',
                    },
                },
            },
            m: {
                options: {
                    clamp: {
                        lines: 4,
                        enabled: true,
                    },
                    classOverrides: {
                        profileInfo: 'p-05 pl-1',
                        profileMetrics: 'pb-05 pt-1',
                    },
                },
            },
            ml: {
                options: {
                    clamp: {
                        lines: 4,
                        enabled: true,
                    },
                },
            },
        },
    };
}
