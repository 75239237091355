import BulkItem from './BulkItem';

export default class WorkQueue extends BulkItem {
    constructor(
        public workQueueId: string = null,
        public workQueueName: string = null,
        public workQueueType: string = null,
        public order: number = null,
        public slaTargetDuration: number = null,
        public slaTargetInTimeRangeTimeUnit: string = null, // "SECONDS"
        public slaTargetInTimeRangeDuration: number = null,
        public slaTargetPercentage: number = null
    ) {
        super(workQueueId, workQueueName);
    }

    public static fromObject(object: any) {
        return new WorkQueue(
            object.workQueueId,
            object.workQueueName,
            object.workQueueType,
            object.order,
            object.slaTargetDuration,
            object.slaTargetInTimeRangeTimeUnit,
            object.slaTargetInTimeRangeDuration,
            object.slaTargetPercentage
        );
    }

    toString() {
        if (this.workQueueName) {
            return this.workQueueName;
        }

        return this.workQueueId;
    }

    get sortValue() {
        return this.order ? this.order : this.toString();
    }
}
