import { default as DataSet, DimensionDataGroup } from '@sprinklr/stories/analytics/DataSet';
import { FieldType } from '@sprinklr/stories/reporting/types';
import { LineChartSeries, SeriesDataPoints } from 'components/_charts/LineChart/types';
import { normalizeLabel } from 'utils/NormalizeLabel/NormalizeLabel';
import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import moment from 'moment';
import { ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';
import { Theme } from 'models/Theme/Theme';
import { GenerateSafeString } from 'utils/StringUtils/StringUtils';

export const getMetricDataSet = (dataSet: DataSet, options, metricIndex) => {
    const firstDim = dataSet.getFirstDimension();
    let groupByDimensionIndex = 0;
    const type: FieldType = dataSet.metrics[metricIndex].type;

    switch (firstDim && firstDim.type) {
        case 'DATE':
        case 'TIMESTAMP':
            groupByDimensionIndex = 1;
            break;

        case 'STRING':
        default:
            groupByDimensionIndex = 0;
            break;
    }

    let data: LineChartSeries[] = [];

    if (dataSet.dimensions.length === 2) {
        data = dataSet.groupBy(dataSet.dimensions[groupByDimensionIndex]).map(
            (series: DimensionDataGroup): LineChartSeries => {
                return {
                    name: normalizeLabel(series.value),
                    data: series.data.toXYN(metricIndex),
                    type,
                };
            }
        );
    } else if (dataSet.dimensions.length === 1) {
        data = [
            {
                name: normalizeLabel(dataSet.metrics[metricIndex].getName() + ''),
                data: dataSet.toXYN(metricIndex),
                type,
            },
        ];
    }

    data.forEach((series: LineChartSeries) => {
        series.data.sort((a: SeriesDataPoints, b: SeriesDataPoints) => {
            if (
                a.x &&
                b.x &&
                ((a.x as any) as BulkItem).sortValue &&
                ((b.x as any) as BulkItem).sortValue
            ) {
                return (
                    parseInt(((a.x as any) as BulkItem).sortValue) -
                    parseInt(((b.x as any) as BulkItem).sortValue)
                );
            }
            return moment(a.x).valueOf() - moment(b.x).valueOf();
        });
    });
    return data.slice(0, options.maxItems);
};

export const getPrevPeriodDataSet = (dataSet: DataSet, options, metricIndex) => {
    const type: FieldType = dataSet.metrics[metricIndex].type;

    let data: LineChartSeries[] = [];
    data = [
        {
            name: normalizeLabel(dataSet.metrics[metricIndex].getName() + ' (Previous Period)'),
            data: dataSet.toXYN(metricIndex),
            type,
        },
    ];
    data.forEach((series: LineChartSeries) => {
        series.data.sort((a: SeriesDataPoints, b: SeriesDataPoints) => {
            if (
                a.x &&
                b.x &&
                ((a.x as any) as BulkItem).sortValue &&
                ((b.x as any) as BulkItem).sortValue
            ) {
                return (
                    parseInt(((a.x as any) as BulkItem).sortValue) -
                    parseInt(((b.x as any) as BulkItem).sortValue)
                );
            }
            return moment(a.x).valueOf() - moment(b.x).valueOf();
        });
    });
    return data.slice(0, options.maxItems);
};

export const transformPrevPeriodData = (dataSet: DataSet, options) => {
    if (dataSet.metrics.length === 1) {
        return getPrevPeriodDataSet(dataSet, options, 0);
    } else {
        return dataSet.metrics.map((metric, index) => {
            return getPrevPeriodDataSet(dataSet, options, index)[0];
        });
    }
};
export const transformData = (dataSet: DataSet, options) => {
    if (dataSet.metrics.length === 1) {
        return getMetricDataSet(dataSet, options, 0);
    } else {
        return dataSet.metrics.map((metric, index) => {
            return getMetricDataSet(dataSet, options, index)[0];
        });
    }
};
export const getCompareModeLegendLabels = (data, prevPeriodData) => {
    const labels = [];
    data.forEach((line, index) => {
        labels.push([data[index].name, prevPeriodData[index].name]);
    });
    return labels;
};

export const lineChartOptionStyles = ({
    lineWidth,
    labelSize,
    valueSize,
    iconSize,
    orbSize,
    pointMarkersSize,
    topPointMarkerSize,
    showTopPointDot,
    legendNameSize,
}) => {
    const computedStyles: ComputedStyle[] = [
        {
            selector: '.line',
            styles: {
                strokeWidth: styler(lineWidth, 1, 'px', ''),
            },
        },
        {
            selector: '.prev_period_line',
            styles: {
                opacity: '50%',
            },
        },
        {
            selector: '.line_label',
            styles: {
                fontSize: styler(labelSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.line_value .metric_value_inner',
            styles: {
                fontSize: styler(valueSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.post_time_stamp',
            styles: {
                fontSize: styler(valueSize, 0.05, 'em', ''),
            },
        },
        {
            selector: '.point_icon .icon__container',
            styles: {
                fontSize: styler(iconSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.point_spike .point_secondary',
            styles: {
                fontSize: styler(orbSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.point_marker .point_inner',
            styles: {
                fontSize: styler(pointMarkersSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.prev_period_circle',
            styles: {
                opacity: '50%',
            },
        },
        {
            selector: '.point_spike .point_inner:not(.point_secondary)',
            styles: {
                fontSize: styler(showTopPointDot ? topPointMarkerSize : 0, 0.1, 'em', ''),
            },
        },
        {
            selector: '.legend_item_name_size ',
            styles: {
                fontSize: styler(legendNameSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.prev_period_item',
            styles: {
                opacity: '50%',
            },
        },
    ];

    return computedStyles;
};

export const getLineChartCss = (mergedTheme: Theme, dataSet, widget, prefix) => {
    let output = '';

    if (!mergedTheme || !dataSet || !widget.options) {
        return output;
    }
    const { useThemeColor } = widget.options;
    const data: any[] = transformData(dataSet, widget.options);

    data.forEach((line, index) => {
        if (useThemeColor && line.name && line.name.color) {
            output += `${prefix} .line_label_${GenerateSafeString(
                line.name.toLowerCase()
            )} { stroke: ${line.name.color} !important }`;
            output += `${prefix} .line_tag_${GenerateSafeString(
                line.name.toLowerCase()
            )} { stroke: ${line.name.color} !important }`;
            output += `${prefix} .label_${GenerateSafeString(
                line.name.toLowerCase()
            )} { background-color: ${line.name.color} !important }`;
            output += `${prefix} .points_label_${GenerateSafeString(
                line.name.toLowerCase()
            )} .point_inner { background-color: ${line.name.color} !important }`;
            output += `${prefix} .legend_item_label_${GenerateSafeString(
                line.name.toLowerCase()
            )} .legend_item_swatch { background-color: ${line.name.color} }`;
        }
    });

    return output;
};
