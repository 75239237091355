// This is used to return Post items within Analytics results
import BulkItem from './BulkItem';
import { ExtendedPostMedia, PostMedia } from '../post/Post';
import { AnalyticsGroupBy } from './AnalyticsRequest';

// TODO: these @sprinklr/display-builder imports need to go away
import PostsTransform from '@sprinklr/stories-services/PostsService/PostsTransform';

export type BulkLookup = { [dimension: string]: any[] };

export default class PostItem extends BulkItem {
    snMsgId: string = null;

    constructor(
        id: string = null,
        public snType: string = null,
        public message: string = '',
        public senderProfile: any = null,
        public snCreatedTime: number = null,
        public textEntities: any = [],
        public highLightEntities: any = [],
        public images: ExtendedPostMedia[] = [],
        public videos: ExtendedPostMedia[] = []
    ) {
        super(id);

        this.snMsgId = id ?? null;
    }

    get sortValue() {
        return this.message;
    }

    toString() {
        return this.message || this.id;
    }

    static canCreate(groupBy: AnalyticsGroupBy): boolean {
        switch (groupBy?.dimensionName) {
            case 'POST_ID':
            case 'CASE':
            case 'ES_MESSAGE_ID':
            case 'REPLY_POST_ID':
            case 'UNIFIED_MESSAGE_ID':
            case 'SIGNAL_ID':
            case 'PARENT_SIGNAL_ID':
                return true;
        }

        return false;
    }

    static create(id: string, post: any, values: any) {
        const isCase = !!post.universalCase;

        if (!post.senderProfile && values.ACCOUNT_ID) {
            const account = values.ACCOUNT_ID[post.accountId];
            if (account) {
                post.senderProfile = {
                    profileImgUrl: account.profileImgUrl,
                    screenName: account.screenName || account.displayName,
                    name: account.displayName || account.screenName,
                };
            }
        }

        // Overload CASE values to PostItems
        if (isCase) {
            if (!!post.universalCase.latestFanAssociatedMessage) {
                post = post.universalCase.latestFanAssociatedMessage;
            } else if (post.universalCase.sub) {
                return new PostItem(
                    post.universalCase.id,
                    post.profile?.snType,
                    post.universalCase.sub,
                    {
                        profileImgUrl: post.profile?.profileImgUrl,
                        screenName: post.profile?.screenName || post.profile?.name,
                        name: post.profile?.name || post.profile?.screenName,
                    },
                    post.universalCase.cCT,
                    {},
                    {},
                    [],
                    []
                );
            } else {
                return post;
            }
        }

        PostsTransform.transformPost(post);

        // For CASE, Sprinklr UI uses name instead of screenName
        if (isCase && post.senderProfile?.name) {
            post.senderProfile.screenName = post.senderProfile.name;
        }

        return new PostItem(
            id,
            post.snType,
            post.message,
            post.senderProfile,
            post.snCreatedTime,
            post.textEntities,
            post.highLightEntities,
            isCase ? [] : post.images,
            isCase ? [] : post.videos
        );
    }

    static createUnknown(id: string) {
        return new PostItem(id, '', '-', { screenName: 'Unknown' }, null, null, null, [], []);
    }

    static addToLookup(post: any, lookup: BulkLookup) {
        // If no senderProfile, need to do an bulkLookup
        // on accountId
        if (!post?.senderProfile && post.accountId) {
            if (!lookup.ACCOUNT_ID) {
                lookup.ACCOUNT_ID = [];
            }

            if (lookup.ACCOUNT_ID.indexOf(post.accountId) === -1) {
                lookup.ACCOUNT_ID.push(post.accountId);
            }
        }
    }

    public static fromObject(object: any) {
        const allProps = [
            'snType',
            'message',
            'senderProfile',
            'snCreatedTime',
            // 'textEntities', // removed as fix for https://sprinklr.atlassian.net/browse/DISPLAY-4557
        ].every(prop => object.hasOwnProperty(prop));

        if (allProps && (object.hasOwnProperty('id') || object.hasOwnProperty('snMsgId'))) {
            return new PostItem(
                object.snMsgId || object.id,
                object.snType,
                object.message,
                object.senderProfile,
                object.snCreatedTime,
                object.textEntities,
                object.highLightEntities,
                object.images || [],
                object.videos || []
            );
        }

        return null;
    }
}
