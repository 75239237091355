import { FilterType } from '@sprinklr/modules/research/widget/types';
import { ApplicationIdentifier } from '@sprinklr/display-builder/config';
import { TimePeriodWeekStart } from '@sprinklr/display-builder/models/TimePeriod/TimePeriod';
import { ViralContributor } from '@sprinklr/stories/analytics/ViralTrends';

export type TimePeriodKey =
    | 'this_week'
    | 'this_month'
    | 'this_quarter'
    | 'this_year'
    | 'yesterday'
    | 'day_before_yesterday'
    | 'today'
    | 'last_week'
    | 'second_last_week'
    | 'last_month'
    | 'second_last_month'
    | 'last_quarter'
    | 'last_year'
    | 'second_last_year'
    | 'next_week'
    | 'week_to_date'
    | 'month_to_date'
    | 'quarter_to_date'
    | 'year_to_date'
    | 'last_60_minutes'
    | 'last_2_to_1_hours'
    | 'last_12_hours'
    | 'last_24_to_12_hours'
    | 'last_24_hours'
    | 'last_48_to_24_hours'
    | 'last_7_days'
    | 'last_14_to_8_days'
    | 'last_28_days'
    | 'last_56_to_29_days'
    | 'last_30_days'
    | 'last_60_to_31_days'
    | 'last_60_days'
    | 'last_120_to_61_days'
    | 'last_90_days'
    | 'last_180_to_91_days'
    | 'last_120_days'
    | 'last_240_to_121_days'
    | 'last_180_days'
    | 'last_360_to_181_days'
    | 'last_365_days'
    | 'last_730_to_366_days'
    | 'last_x_months'
    | 'last_x_weeks'
    | 'last_x_hours'
    | 'last_x_minutes'
    | 'all_time'
    | 'custom'
    | 'custom_duration'
    | 'custom_to_date'
    | 'x_days_ago'
    | 'x_weeks_ago'
    | 'x_months_ago'
    | 'x_quarters_ago';

export type AnalyticsFilterType = FilterType | 'RANK';

// Created this from CustomPropertyDimension.java
export type AnalyticsFieldType =
    | 'ACCOUNT_CUSTOM_PROPERTY'
    | 'AD_SET_CUSTOM_PROPERTY'
    | 'AD_VARIANT_CUSTOM_PROPERTY'
    | 'BENCHMARKING_ACCOUNT_CUSTOM_PROPERTY'
    | 'BRAND_CUSTOM_PROPERTY'
    | 'CHANNEL_CUSTOM_PROPERTY'
    | 'CAMPAIGN_CUSTOM_PROPERTY'
    | 'COMPANY_CUSTOM_PROPERTY'
    | 'EVENT_CUSTOM_PROPERTY'
    | 'INBOUND_CUSTOM_PROPERTY'
    | 'INBOUND_CHANNEL_CUSTOM_PROPERTY'
    | 'MEDIA_ASSET_CUSTOM_PROPERTY'
    | 'OUTBOUND_CUSTOM_PROPERTY'
    | 'OUTBOUND_CHANNEL_CUSTOM_PROPERTY'
    | 'PAID_INITIATIVE_CUSTOM_PROPERTY'
    | 'POST_TARGETED_PROFILE_CUSTOM_PROPERTY'
    | 'PROFILE_CUSTOM_PROPERTY'
    | 'SPR_TASK_CUSTOM_PROPERTY'
    | 'UNIVERSAL_CASE_CUSTOM_PROPERTY'
    | 'USER_CUSTOM_PROPERTY'
    | 'HISTORIC_TREND_SOURCE';

export type AnalyticsGroupType =
    | 'HISTOGRAM'
    | 'RANGE'
    | 'TOP_HITS'
    | 'FILTER'
    | 'SMART_CLUSTER'
    | 'DATE_HISTOGRAM'
    | 'DATETIME'
    | 'TIME_OF_DAY'
    | 'DAY_OF_WEEK'
    | 'MONTH_OF_YEAR'
    | 'FIELD';

export type AnalyticsTimeInterval = '1m' | '1h' | '1d' | '1w' | '1M' | '1q' | '1y';
export type AnalyticsTimeIntervalSuffix = 'm' | 'h' | 'd' | 'w' | 'M' | 'q' | 'y';

export interface AnalyticsFilter {
    dimensionName: string;
    filterType: AnalyticsFilterType;
    allValues?: boolean;
    values: any[];
    field?: AnalyticsFieldType;
    details?: Record<string, any> & {
        type?: string;
        OLD_DIM_NAME?: string;
        EXIST_FILTER?: boolean;
        srcType?: 'CUSTOM' | 'CALCULATED';
        fieldName?: string;
        clientId?: number | string;
        allValues?: boolean;
        alternateHeading?: string;
        percentiles?: number[];
        lookupKey?: string;
    };
}

export type NamedFilter = {
    name: string;
    filters: AnalyticsFilter[];
};
export interface AnalyticsGroupBy {
    heading: string;
    dimensionName: string;
    groupType: AnalyticsGroupType;
    limit?: number;
    limitType?: 'NONE' | 'TOP' | 'BOTTOM';
    resolveName?: boolean;
    includeOthers?: boolean;
    field?: AnalyticsFieldType;
    details?: Record<string, any> & {
        srcType?: 'CUSTOM' | 'CALCULATED';
        fieldName?: string;
        interval?: AnalyticsTimeInterval;
        alternateHeading?: string;
    };
    namedFilters?: NamedFilter[];
}

export type AggregationFunction =
    | 'SUM'
    | 'CUMULATIVE_SUM'
    | 'AVG'
    | 'MIN'
    | 'MAX'
    | 'COUNT'
    | 'STATS'
    | 'EXTERNAL_VALUE'
    | 'CARDINALITY'
    | 'TOP_HITS'
    | 'CHANGE'
    | 'PERCENTAGE_CHANGE'
    | 'PERCENTILE'
    | 'PERCENTAGE';

export interface AnalyticsProjection {
    heading: string;
    measurementName: string;
    // CHANGE, PERCENTAGE_CHANGE and CHANGE are only supported in backend API via projectionDecorators
    aggregateFunction: AggregationFunction;

    details?: ProjectionDetails;
}

export type ProjectionDetails = Record<string, any> & {
    origReport?: string;
    alternateHeading?: string;
    percentChangeAggregation?: AggregationFunction;
};

export type AnalyticsSortOrder = 'ASC' | 'DESC';

export interface AnalyticsSort {
    order: AnalyticsSortOrder;
    heading: string;
    isDimension?: boolean;
    details?: Record<string, any> & {
        origReport?: string;
    };
}

export interface AnalyticsTimePeriod {
    key?: TimePeriodKey;
    previousPeriod?: boolean;
    duration?: number;
    startTime?: number;
    endTime?: number;
    timeZone?: string;
    wholePeriods?: boolean;
    xValue?: number;
    rollingStart?: number;
    rollingEnd?: number;
    name?: string;
    filters?: AnalyticsFilter[];
    weekStart?: TimePeriodWeekStart;
}

export type AnalyticsAdditional = Record<string, any> & {
    lst_keyword_list?: string;
    TARGET_LANG_CODE?: string;
    slaConfig?: any;
    Currency?: string;
    showRolloverTrends?: boolean | 'true' | 'false';

    widgetId?: string;
    panelId?: string;
    sceneId?: string;
    storyboardId?: string;
    originatingApp?: ApplicationIdentifier;
    TABULAR?: boolean | 'true' | 'false';
};

export type AnalyticsEngine =
    | 'PLATFORM'
    | 'INBOUND_MESSAGE'
    | 'LISTENING'
    | 'SELF_SERVE_LISTENING'
    | 'BENCHMARKING'
    | 'UNIFIED_ANALYTICS_REPORTING_ENGINE'
    | 'PAID'
    | 'CAMPAIGN'
    | 'TWITTER'
    | 'HISTORIC_TRENDS'
    | 'ADVOCACY'
    | 'RDB_FIREHOSE'
    | 'STORY_MESSAGE'
    | 'MONITORING_DASHBOARD'
    | 'TRENDING_EVENT'
    | 'UNIVERSAL_PROFILE'
    | 'SPR_TASK'
    | 'CUSTOM_ENTITY'
    | 'VOICE'
    | 'COMMUNITY'
    | 'SAM'
    | 'AUDIENCE_ACTIVITY'
    | 'OUTBOUND_MESSAGE'
    | 'CONSUMPTION';

export const AnalyticsEngines = [
    'PLATFORM',
    'INBOUND_MESSAGE',
    'LISTENING',
    'SELF_SERVE_LISTENING',
    'BENCHMARKING',
    'UNIFIED_ANALYTICS_REPORTING_ENGINE',
    'PAID',
    'CAMPAIGN',
    'TWITTER',
    'HISTORIC_TRENDS',
    'ADVOCACY',
    'RDB_FIREHOSE',
    'STORY_MESSAGE',
    'MONITORING_DASHBOARD',
    'TRENDING_EVENT',
    'UNIVERSAL_PROFILE',
    'SPR_TASK',
    'CUSTOM_ENTITY',
    'VOICE',
    'COMMUNITY',
    'SAM',
    'AUDIENCE_ACTIVITY',
    'OUTBOUND_MESSAGE',
    'CONSUMPTION',
];

export interface AnalyticsRequest {
    reportingEngine: AnalyticsEngine;
    report: string;

    projections: AnalyticsProjection[];
    groupBys: AnalyticsGroupBy[];
    filters: AnalyticsFilter[];
    sorts?: AnalyticsSort[];
    additional?: AnalyticsAdditional;

    timePeriods: AnalyticsTimePeriod[];

    limit?: number;

    projectionDecorations?: string[];
    projectionFilters?: AnalyticsFilter[];
    viralTrends?: ViralContributor[];

    slaConfig?: any;

    includeTotal?: boolean;
    timeField?: string;
}
