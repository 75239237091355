import { LegendOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import merge from 'lodash/merge';
import { observable } from 'mobx';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import {
    DataLabelsOptionsImpl,
    LegendOptionsImpl,
    MetaInfoOptionsImpl,
    XAxisImpl,
    YAxisImpl,
} from '../options';

import { HyperSpaceAreaChartWidgetOptions } from './types';

export class HyperSpaceAreaChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceAreaChartWidgetOptions {
    @observable baseXYChartOptions: HyperSpaceAreaChartWidgetOptions['baseXYChartOptions'] = {
        grid: {
            enabled: false,
            color: '',
            size: 1,
        },
        fill: {
            type: 'solid',
            opacity: 70,
            gradient: { to: 50, from: 10 },
        },
        xAxis: new XAxisImpl(),
        yAxis: new YAxisImpl(),
        dataLabels: merge(new DataLabelsOptionsImpl(), { enabled: false }), // TODO  make false for backwards compat
    };
    @observable zeroFill = true;
    @observable legend: LegendOptions = new LegendOptionsImpl();
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
}
