import DataSet from '@sprinklr/stories/analytics/DataSet';
import { WorldHeatMapWidgetOptions } from './options';
import { GeoDataSlices } from 'models/Geo/GeoDataSlices';
import { GeoData } from 'models/Geo/GeoData';
import { ComputedStyle, fadedFontColor } from 'utils/GenerateStyles/GenerateStyles';
import LookupItem from '@sprinklr/stories/analytics/LookupItem';
import Direction from '@sprinklr/stories/analytics/Direction';
import philippinesCitiesByProvince from 'components/_charts/WorldHeatMap/regions/philippinesCities';
import { getType } from 'models/Geo/helpers';
// How much to scale-in when a state is zoomed-in
export const STATE_SCALE_BY = 6;

export const shouldScaleIn = options => {
    const { regionState, region } = options;
    return regionState && regionState !== 'all';
};

export const countWorldHeatMap = (dataSet: DataSet, options: WorldHeatMapWidgetOptions): number => {
    const regionType = getType(options.region);

    if (options.animationOverTime?.enabled && dataSet.dimensions.length === 2) {
        return GeoDataSlices.create(dataSet, regionType).length;
    } else {
        return GeoData.create(dataSet, regionType).length;
    }
};

export const worldHeatMapOptionStyles = (options, theme, mergedTheme) => {
    const { offsetX, offsetY, zoom, cityMarkers } = options;
    const { opacity, border } = cityMarkers;
    const { enabled: strokeEnabled, color: strokeColor, size } = border;

    let scale = 1.0;

    if (options.region === 'philippines') {
        scale = 2.0;
    }

    // If we're zoomed into a state, reduce our border size
    if (shouldScaleIn(options)) {
        scale *= STATE_SCALE_BY;
    }
    const { typography: { color = null } = {} } = mergedTheme;
    const colorFontFaded = fadedFontColor(color);

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.world_heat_map svg',
            styles: {
                transform: `translate(${offsetX}%, ${offsetY}%) scale(${zoom * 0.02 * scale})`,
                position: 'absolute',
            },
        },
        {
            selector: '.world_heat_map .legend_item_swatch',
            styles: {
                border: strokeEnabled && `${size * 0.0025}em solid ${strokeColor}`,
            },
        },
    ];

    return computedStyles;
};

export const getPhilippinesProvinceDataSet = (dataSet: DataSet) => {
    const data = dataSet?.rows;
    const provinces = philippinesCitiesByProvince;

    const copyDataSet = JSON.parse(JSON.stringify(dataSet));

    const dataByProvince = Object.keys(provinces).map(provinceCode => {
        const { cities } = provinces[provinceCode];

        const cityTotals = cities.map(city => {
            const cityItem = data.find(
                item =>
                    item[0]?.name?.toUpperCase() === city.toUpperCase() ||
                    (typeof item?.[0] === 'string' && item[0].toUpperCase() === city.toUpperCase())
            );
            if (cityItem) {
                return cityItem[1];
            }
            return 0;
        });

        const provinceTotal = cityTotals.reduce((acc, currentValue) => acc + currentValue, 0);

        const provinceLookupItem: LookupItem = new LookupItem(provinceCode, provinceCode);
        return [provinceLookupItem, provinceTotal];
    });

    copyDataSet.rows = dataByProvince;
    if (copyDataSet?.dimensions?.[0]) {
        copyDataSet.dimensions[0].name = 'STATE';
    }
    if (copyDataSet.fields?.[0]) {
        copyDataSet.fields[0].name = 'STATE';
    }

    const finalDataSet = DataSet.create(copyDataSet).sortByMetricDirection(
        dataSet.metrics[0],
        Direction.DESC
    );

    return finalDataSet;
};
