import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { RankedItem } from 'components/_charts/RankedList/RankedList';
import { observable } from 'mobx';
import { FontCase } from 'models/Theme/Theme';
import { DecimalFormat } from 'utils/Number/NumberUtils';

export interface RankedListWidgetOptions extends WidgetOptions {
    data?: RankedItem[];
    avatar?: boolean;
    country?: string;
    countryCodeMap?: any;
    subType?: string;
    themeClass?: string;
    wrapperClasses?: string;
    containerClasses?: string;
    highlightPercentChange?: boolean;

    labelSize?: number;
    showMetricValue?: boolean;
    showMetricName?: boolean;
    showMetricChange?: boolean;

    colorRanking?: boolean;
}

export class RankedListWidgetOptionsImpl extends WidgetOptionsImpl
    implements RankedListWidgetOptions {
    @observable labelSize = 12;

    @observable showMetricName = true;
    @observable metricNameSize = 12;
    @observable metricNameCase: FontCase = 'lowercase';

    @observable showMetricValue = true;
    @observable metricValueSize = 45;
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';

    @observable showMetricChange = true;
    @observable metricPercentChangeSize = 10;
    @observable highlightPercentChange: boolean;

    @observable showTimeRemainder = true;
}
