import { LanguageDirection, WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { PercentChangeColor, SmartSize, XAxisDateFormats } from '@sprinklr/stories/widget/types';
import { observable } from 'mobx';
import { FontCase } from 'models/Theme/Theme';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { Borders, MetricDesignOptions } from './types';
import { RankedTableProps } from 'components/_charts/rankedTable/RankedTable';
import { colors } from 'components/spaceKit';

export interface RankedTableWidgetOptions extends WidgetOptions {
    labelSize?: number;
    showRowSize?: boolean;
    rowSize?: number;
    showMetricValue?: boolean;
    showMetricName?: boolean;
    showMetricChange?: boolean;
    legacyMode?: boolean;
    autoRowHeight?: boolean;
    alignment?: any;
    rowNumbers?: boolean;
    columnNameFormat?: XAxisDateFormats;
    borders: Borders;
    metricDesignOptions?: MetricDesignOptions;
}

export class RankedTableWidgetOptionsImpl extends WidgetOptionsImpl
    implements RankedTableWidgetOptions {
    @observable languageDirection: LanguageDirection = 'auto';
    @observable labelSize = 20;
    @observable labelName = 'Row Name';

    @observable showAccountIcon = true;

    @observable showRowSize?: boolean = false;
    @observable rowSize?: number = 50;

    @observable showColumnName = true;
    @observable columnNameSize = 20;
    @observable columnNameFormat: XAxisDateFormats = 'auto';
    @observable dimensionSize = 15;

    @observable alignment = {
        rowName: 'left',
        columnName: 'center',
        metric: 'center',
    };

    @observable showMetricName = true;
    @observable metricNameSize = 13;
    @observable metricNameCase: FontCase = 'lowercase';
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';

    @observable showMetricValue = true;
    @observable metricValueSize = 50;

    @observable showMetricChange = true;
    @observable metricPercentChangeSize = 16;
    @observable highlightPercentChange: boolean;

    @observable rowNumbers = false;

    @observable showTimeRemainder = true;
    @observable orientation = 'vertical';
    @observable autoRowHeight = false;
    @observable invertDataSet = false;
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            labelSize: 20,
            metricNameSize: 13,
            columnNameSize: 20,
            dimensionSize: 15,
            metricValueSize: 50,
            metricPercentChangeSize: 16,
        },
        optionDefaults: {},
        breakpoints: {},
    };
    @observable legacyMode = true;
    @observable borders: Borders = {
        cell: {
            enabled: false,
            size: 20,
            color: '',
        },

        top: {
            enabled: false,
            size: 20,
            color: '',
        },
        bottom: {
            enabled: false,
            size: 20,
            color: '',
        },
    };
    @observable expandedTextCell: RankedTableProps['expandedTextCell'] = {
        enabled: true,
        characterThreshold: 30,
        lineClamp: {
            enabled: true,
            lines: 3,
        },
    };
    @observable percentChangeColor: PercentChangeColor = {
        enabled: false,
        up: colors.sentiments.fern,
        zero: '',
        down: colors.sentiments.blush,
    };
}
