import {
    ComputedStyle,
    getMergedBackgroundColor,
    imageFilterStyles,
    styler,
} from 'utils/GenerateStyles/GenerateStyles';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Measurements } from 'components/Widget/WidgetComponent/types';
import { GenerateTransitionStyles } from 'utils/GenerateTransitionStyles/GenerateTransitionStyles';
import { HyperlinkButtonWidgetOptionsImpl } from './options';
import { Theme } from 'models/Theme/Theme';
import { AlignmentOptions } from '../StackedBarChartWidget/types';

export const hyperlinkWidgetOptionStyles = (
    options: HyperlinkButtonWidgetOptionsImpl,
    theme: Theme,
    mergedTheme: Theme
) => {
    const {
        backgroundColor,
        colorFont,
        fontSize,
        buttonBorder,
        font,
        buttonTextAlignment,
    } = options;

    const computedStyles: ComputedStyle[] = [];
    computedStyles.push({
        selector: '.hyperlink_button',
        styles: {
            background: `${backgroundColor || getMergedBackgroundColor(mergedTheme)}`,
            color: `${colorFont || mergedTheme.typography?.color}`,
            borderRadius: `${buttonBorder.radius + 'px'}`,
            borderWidth: `${buttonBorder.size + 'px'}`,
            borderColor: `${buttonBorder.color}`,
            fontFamily: `${font.family || mergedTheme?.typography?.primary?.family}`,
            fontSize: `${styler(fontSize, 0.1, 'em', '')}`,
            textAlign: `${buttonTextAlignment}` as AlignmentOptions,
        },
    });
    return [...computedStyles];
};

export const getHyperlinkCss = (
    mergedTheme,
    dataSet,
    widget: Widget,
    cssPrefix: string,
    measurements?: Measurements
) => {
    const { animationLength, animationDelay } = widget.options;

    return GenerateTransitionStyles(
        '.hyperlink_button',
        undefined,
        1,
        animationLength,
        animationDelay,
        cssPrefix
    );
};
