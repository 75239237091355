import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { HAlign, PercentChangeColor, SmartSize } from '@sprinklr/stories/widget/types';
import { Threshold } from 'components/Threshold/Thresholds';
import { observable } from 'mobx';
import { FontCase } from 'models/Theme/Theme';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { colors } from 'components/spaceKit';
import { StatusTag } from '../utils/getStatusTag';

export interface MetricWidgetOptions extends WidgetOptions {
    showBackgroundCircle?: boolean;
    widgetStyle?: string;
    metricValueType?: string;
    primaryClasses?: string;
    highlightPercentChange?: boolean;
    showIcon?: boolean;
    orientation?: string;
    alignment?: HAlign;
    animationDelay?: number;
    animationEnabled?: boolean;
    animationLength?: number;
    thresholds?: Threshold[];
    thresholdsEnabled: boolean;
    thresholdBarHeight?: number;
    thresholdBarLength?: number;
    thresholdsPercent?: boolean;
    maxThresholds?: number;
    showProgressBar?: boolean;
    useMetricAsHeader?: boolean;
    useSourceAsHeader?: boolean;
    statusTags?: StatusTag[];
}

export class MetricWidgetOptionsImpl extends WidgetOptionsImpl implements MetricWidgetOptions {
    @observable showMetricName = true;
    @observable metricNameSize = 15;
    @observable metricNameCase: FontCase = 'lowercase';

    @observable showMetricValue = true;
    @observable metricValueSize = 60;
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';
    @observable metricValueType = 'primary';

    @observable showMetricChange = true;
    @observable metricPercentChangeSize = 20;
    @observable highlightPercentChange: boolean;

    @observable showMetricPreviousValue?: boolean = false;
    @observable metricPreviousValueSize?: number = 8;
    @observable previousValueFormat?: DecimalFormat = '1k';

    @observable useMetricAsHeader?: boolean = false;
    @observable useSourceAsHeader?: boolean = false;

    @observable showTimeRemainder = true;

    @observable thresholds: Threshold[] = [];
    @observable thresholdsEnabled = false;
    @observable thresholdBarHeight = 2;
    @observable thresholdBarLength = 50;
    @observable thresholdsPercent = false;
    @observable maxThresholds = 1;
    @observable percentChangeColor: PercentChangeColor = {
        enabled: false,
        up: colors.sentiments.fern,
        zero: '',
        down: colors.sentiments.blush,
    };

    @observable showBackgroundCircle = false;
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            metricValueSize: 60,
            metricNameSize: 15,
            metricPercentChangeSize: 20,
        },
        optionDefaults: {
            showBackgroundCircle: true,
        },
        breakpoints: {
            xs: {
                options: {
                    showBackgroundCircle: false,
                },
            },
            s: {
                options: {
                    showBackgroundCircle: false,
                },
            },
            m: {
                options: {
                    showBackgroundCircle: false,
                },
            },
            l: {
                options: {
                    showBackgroundCircle: false,
                },
            },
        },
    };
    @observable statusTags: StatusTag[];
}
