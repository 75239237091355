import { ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';
import { AnalyticsProjection } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { normalizeLabel } from 'utils/NormalizeLabel/NormalizeLabel';
import { HAlign } from '@sprinklr/stories/widget/types';

export const BASE_BORDER_COLOR = 'rgba(109, 109, 109, 0.58)';

// coverage looks good for this one
// https://caniuse.com/mdn-css_properties_text-align_flow_relative_values_start_and_end
const resolveAlign = (alignment: HAlign): 'start' | 'end' | 'center' => {
    switch (alignment) {
        case 'left':
            return 'start';
        case 'right':
            return 'end';
        default:
            return 'center';
    }
};
export const dataTableOptionStyles = (options, mergedTheme, theme) => {
    const { layout, base, rows } = options;

    const { padding, autoRowHeight } = layout;
    const computedStyles: ComputedStyle[] = [];
    const fallBackColor = theme?.typography?.color ?? mergedTheme?.typography?.color;

    if (autoRowHeight) {
        computedStyles.push({
            selector: '.data_table',
            styles: {
                height: '100%',
            },
        });
    }

    if (padding) {
        if (autoRowHeight) {
            computedStyles.push({
                selector: '.data_table td, .data_table th',
                styles: {
                    paddingRight: styler(padding, 0.025, 'em', ''),
                    paddingLeft: styler(padding, 0.025, 'em', ''),
                },
            });
        } else {
            computedStyles.push({
                selector: '.data_table td, .data_table th',
                styles: {
                    padding: styler(padding, 0.025, 'em', ''),
                },
            });
        }
    }
    if (base?.size) {
        computedStyles.push({
            selector: '.data_table',
            styles: {
                fontSize: styler(base.size, 0.025, 'em', ''),
            },
        });
    }
    if (base?.alignment) {
        computedStyles.push({
            selector: '.data_table td',
            styles: {
                textAlign: resolveAlign(base.alignment),
            },
        });
        computedStyles.push({
            selector: '.data_table th',
            styles: {
                textAlign: resolveAlign(base.alignment),
            },
        });
    }

    const baseBorder = rows?.borders?.base;
    const topBorder = rows?.borders?.top;
    const bottomBorder = rows?.borders?.bottom;

    const baseBorderColor = baseBorder.color || fallBackColor;

    if (baseBorder?.enabled) {
        computedStyles.push({
            selector: '.data_table td',
            styles: {
                borderBottom: `${baseBorder.size * 0.1}px solid ${baseBorderColor}`,
            },
        });

        if (!topBorder?.enabled) {
            computedStyles.push({
                selector: '.data_table th:not(.placeholder)',
                styles: {
                    borderBottom: `${baseBorder.size * 0.1}px solid ${baseBorder.color ||
                        baseBorderColor}`,
                },
            });
        }
    }

    if (topBorder?.enabled) {
        computedStyles.push({
            selector: '.data_table th:not(.placeholder)',
            styles: {
                borderBottom: `${topBorder.size * 0.1}px solid ${topBorder.color ||
                    baseBorderColor}`,
            },
        });
    }

    if (bottomBorder?.enabled) {
        computedStyles.push({
            selector: '.data_table tbody tr:last-child td',
            styles: {
                borderBottom: `${bottomBorder.size * 0.1}px solid ${bottomBorder.color ||
                    baseBorderColor}`,
            },
        });
    }

    return computedStyles;
};

export const getDataTableCss: GetWidgetTypeStyles = (mergedTheme: Theme) => {
    return '';
};

export function getPrettyMetricLabel(metric: AnalyticsProjection): string {
    return `${normalizeLabel(metric.details?.label ?? metric.heading)} (${
        metric.aggregateFunction
    })`;
}
