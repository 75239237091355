import { LanguageDirection, WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { Border, CurrencyFormat, HAlign } from '@sprinklr/stories/widget/types';
import { DataTableOptions, Formatters, Layout } from 'components/_charts/DataTable/types';
import { observable } from 'mobx';
import { XAxisDateFormats } from '@sprinklr/stories/widget/types';
import { NumberFormat } from './types';
import { BASE_BORDER_COLOR } from './helpers';

export interface DataTableWidgetOptions extends WidgetOptions, DataTableOptions {}

export class FormattersImpl implements Formatters {
    @observable size = 0;
    @observable numberFormat: NumberFormat = {
        decimals: null,
        usePretty: false,
    };
    @observable timeFormat: XAxisDateFormats = null;
    @observable currencyFormat: CurrencyFormat = null;
    @observable alignment: HAlign = null;
}

export class DefaultFormattersImpl implements Formatters {
    @observable size = 50;
    @observable numberFormat: NumberFormat = {
        decimals: 2,
        usePretty: false,
    };
    @observable timeFormat: XAxisDateFormats = 'MMM Do';
    @observable currencyFormat: CurrencyFormat = null;
    @observable alignment: HAlign = 'left';
}
export class BorderImpl implements Border {
    @observable size = 10;
    @observable enabled = false;
    @observable color = '';
}

export class BaseBorderImpl implements Border {
    @observable size = 10;
    @observable enabled = true;
    @observable color = BASE_BORDER_COLOR;
}

export class DataTableWidgetOptionsImpl extends WidgetOptionsImpl
    implements DataTableWidgetOptions {
    @observable base = new DefaultFormattersImpl();
    @observable header = {
        enabled: true,
        ...new FormattersImpl(),
    };
    @observable rows = {
        borders: {
            base: new BaseBorderImpl(),
            top: new BorderImpl(),
            bottom: new BorderImpl(),
        },
        ...new FormattersImpl(),
    };
    @observable layout: Layout = {
        autoRowHeight: true,
        padding: 50,
    };
    @observable languageDirection: LanguageDirection = 'auto';
}
