import { ShapeWidgetOptionsImpl } from './options';
import { MergedLegacyTheme } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import { ComputedStyle, getMergedBackgroundColor } from 'utils/GenerateStyles/GenerateStyles';

export const shapeOptionStyles = (
    options: ShapeWidgetOptionsImpl,
    theme: MergedLegacyTheme,
    mergedTheme: Theme
) => {
    const { shape } = options;
    const computedStyles: ComputedStyle[] = [];

    computedStyles.push({
        selector: '.shape',
        styles: {
            backgroundColor: shape.background.color || getMergedBackgroundColor(mergedTheme),
            fill: shape.background.color || getMergedBackgroundColor(mergedTheme),
        },
    });

    if (shape.type === 'ellipse' || shape.type === 'rectangle') {
        computedStyles.push({
            selector: '.shape',
            styles: {
                backgroundImage: shape.background.image
                    ? `url("${shape.background.image}")`
                    : 'none',
                backgroundSize: shape.background.imageFit,
                backgroundPosition: `${shape.background.verticalAlign} ${shape.background.horizontalAlign}`,
            },
        });
    }

    if (shape.border && shape.border.enabled) {
        computedStyles.push({
            selector: '.shape',
            styles: {
                border: `${shape.border.size}px solid ${shape.border.color ||
                    mergedTheme.typography.color}`,
                stroke: shape.border.color || mergedTheme.typography.color,
                strokeWidth: `${shape.border.size * 0.1}`,
            },
        });
    }

    return computedStyles;
};
