import { BaseXYAxisChartOptions, LegendOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import { observable } from 'mobx';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import {
    BaseXYAxisChartOptionsImpl,
    BubbleOptionsImpl,
    LegendOptionsImpl,
    MetaInfoOptionsImpl,
} from '../options';

import { HyperSpaceQuadrantMatrixWidgetOptions, QuadrantConfig } from './types';

export class HyperSpaceQuadrantMatrixWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceQuadrantMatrixWidgetOptions {
    @observable bubble = new BubbleOptionsImpl();
    @observable baseXYChartOptions: BaseXYAxisChartOptions = new BaseXYAxisChartOptionsImpl();
    @observable zeroFill = true;
    @observable legend: LegendOptions = new LegendOptionsImpl();
    @observable metaInfoOptions = new MetaInfoOptionsImpl();

    @observable quadrantConfig: QuadrantConfig = {
        separators: {
            enabled: true,
            color: '#4A4A4A',
            size: 1,
            x: 0,
            y: 0,
        },
        labels: {
            enabled: true,
            size: 25,
            topLeft: 'Top Left',
            topRight: 'Top Right',
            bottomLeft: 'Bottom Left',
            bottomRight: 'Bottom Right',
        },
    };
}
