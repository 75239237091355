import merge from 'lodash/merge';
import { LegendOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import { observable } from 'mobx';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import {
    DataLabelsOptionsImpl,
    LegendOptionsImpl,
    MetaInfoOptionsImpl,
    XAxisImpl,
    YAxisImpl,
} from '../options';

import { HyperSpaceComboChartWidgetOptions } from './types';

export class HyperSpaceComboChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceComboChartWidgetOptions {
    @observable baseXYChartOptions: HyperSpaceComboChartWidgetOptions['baseXYChartOptions'] = {
        grid: {
            enabled: false,
            color: '',
            size: 1,
        },
        series: [
            {
                type: 'line',
            },
            {
                type: 'column',
            },
        ],
        xAxis: new XAxisImpl(),
        yAxis: new YAxisImpl(),
        dataLabels: new DataLabelsOptionsImpl(),
    };
    @observable zeroFill = true;
    @observable legend: LegendOptions = merge(new LegendOptionsImpl(), {
        glyph: { shape: undefined }, // disable glyph shape
    });
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
}
