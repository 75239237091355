import { default as DataSet, DimensionDataGroup } from '@sprinklr/stories/analytics/DataSet';
import { FieldType, FieldDataType } from '@sprinklr/stories/reporting/types';
import { LineChartSeries, SeriesDataPoints } from 'components/_charts/LineChart/types';
import { normalizeLabel } from 'utils/NormalizeLabel/NormalizeLabel';
import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import moment from 'moment';
import { ChartTypes, ComboLayer } from '@sprinklr/stories/widget/WidgetOptions';
import { toJS } from 'mobx';
import merge from 'deepmerge';
import { generateColorPalette } from 'utils/GenerateColors/GenerateColors';
import { ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';
import { ColorOverride, Theme } from 'models/Theme/Theme';
import { sum } from 'd3-array';
import {
    ComboAreaOptionsImpl,
    ComboChartWidgetOptions,
    ComboColumnOptionsImpl,
    ComboLineOptionsImpl,
} from './options';

export const getFormattedData = (currentDataSet: DataSet, options: ComboChartWidgetOptions) => {
    if (currentDataSet.dimensions.length === 2) {
        return getMetricDataSet(currentDataSet, options, 0);
    }
    return currentDataSet.metrics.map(
        (metric, index) => getMetricDataSet(currentDataSet, options, index)[0]
    );
};

export const getMetricDataSet = (
    dataSet: DataSet,
    options: ComboChartWidgetOptions,
    metricIndex: number
) => {
    const firstDim = dataSet.getFirstDimension();
    let groupByDimensionIndex = 0;
    const type: FieldType = dataSet.metrics[metricIndex].type;
    const dataType: FieldDataType = dataSet.metrics[metricIndex].dataType || 'NUMERIC';
    switch (firstDim && firstDim.type) {
        case 'DATE':
        case 'TIMESTAMP':
        case 'STRING':
            groupByDimensionIndex = 1;
            break;

        case 'NUMBER':
        default:
            groupByDimensionIndex = 0;
            break;
    }

    let data: LineChartSeries[] = [];

    if (dataSet.dimensions.length === 2) {
        data = dataSet.groupBy(dataSet.dimensions[groupByDimensionIndex]).map(
            (series: DimensionDataGroup, index): LineChartSeries => {
                return {
                    name: normalizeLabel(series.value),
                    data: series.data.toXYN(metricIndex),
                    type,
                    dataType,
                    fields: [dataSet.dimensions[0], dataSet.dimensions[1]],
                };
            }
        );
    } else if (dataSet.dimensions.length === 1) {
        data = [
            {
                name: dataSet.metrics[metricIndex].getNamePretty('capitalize'),
                data: dataSet.toXYN(metricIndex),
                type,
                dataType,
                fields: [dataSet.dimensions[0], dataSet.metrics[metricIndex]],
            },
        ];
    }

    data.forEach((series: LineChartSeries) => {
        series.data.sort((a: SeriesDataPoints, b: SeriesDataPoints) => {
            if (((a.x as any) as BulkItem).sortValue && ((b.x as any) as BulkItem).sortValue) {
                return (
                    parseInt(((a.x as any) as BulkItem).sortValue) -
                    parseInt(((b.x as any) as BulkItem).sortValue)
                );
            }
            return moment(a.x).valueOf() - moment(b.x).valueOf();
        });
    });
    return data;
};

export function getImportChartOptionsImpl(chartType: ChartTypes) {
    if (!chartType) {
        return new ComboLineOptionsImpl();
    }
    switch (chartType) {
        case 'comboColumn':
            return new ComboColumnOptionsImpl();
        case 'comboArea':
            return new ComboAreaOptionsImpl();
        default:
            return new ComboLineOptionsImpl();
    }
}

export const getComboLayers = (
    data: LineChartSeries[],
    mergedTheme: Theme,
    options: ComboChartWidgetOptions,
    theme: Theme,
    hasMultipleDimensions: boolean
) => {
    const comboChartLayers: any[] = [];
    let optionComboLayers = (toJS(options) as ComboChartWidgetOptions).comboLayers;
    if (hasMultipleDimensions) {
        optionComboLayers = data.map(
            (datum): ComboLayer => {
                return {
                    ...datum,
                    displayName: datum?.name + '',
                    chartType: options.comboLayers[0].chartType,
                    options: merge(
                        getImportChartOptionsImpl(options.comboLayers[0].chartType) as unknown,
                        options.comboLayers[0].options
                    ),
                    metrics: [''],
                    fields: datum.fields,
                };
            }
        );
    }

    optionComboLayers.map((item, index) => {
        const colorCount = data?.length;
        const colors: string[] = generateColorPalette(mergedTheme, colorCount, false, theme);
        const overrides: ColorOverride[] = mergedTheme?.colorPalette?.overrides;
        const colorOverride: ColorOverride = overrides?.find(
            override =>
                normalizeLabel(override.label.toLowerCase()) ===
                normalizeLabel((item?.name + '')?.toLowerCase())
        );

        switch (item.chartType) {
            case 'comboColumn':
            case 'comboArea':
                if (item.options.color === '') {
                    item.options.color = colorOverride?.color ?? colors[index];
                }
                break;
            case 'comboLine':
                if (item.options.stroke.color === '') {
                    item.options.stroke.color = colorOverride?.color ?? colors[index];
                }
            default:
                break;
        }
        switch (item.chartType) {
            case 'comboLine':
            case 'comboArea':
            case 'comboScatterPlot':
                if (item.options.points.marker.color === '') {
                    item.options.points.marker.color = colorOverride?.color ?? colors[index];
                }
                if (item.options.points.marker.stroke.color === '') {
                    item.options.points.marker.stroke.color = colorOverride?.color ?? colors[index];
                }
            default:
                break;
        }
        if (item.options.points.value.color === '') {
            item.options.points.value.color = mergedTheme.typography.color;
        }
        if (data[index]) {
            comboChartLayers.push(Object.assign(item, { ...data[index] }));
        }
    });

    return comboChartLayers;
};

export const comboChartOptionStyles = options => {
    const { legend } = options;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.legend_item ',
            styles: {
                fontSize: styler(legend.size, 0.1, 'em', ''),
            },
        },
    ];
    return computedStyles;
};

export const getComboChartCss = (mergedTheme: Theme, dataSet, widget, cssPrefix: string) => {
    let output = '';
    if (dataSet) {
        const layers = getComboLayers(
            getFormattedData(dataSet, widget.options),
            mergedTheme,
            widget.options,
            widget.theme,
            dataSet.dimensions.length > 1
        );
        const merged = getMergedLayers(layers, 'chartType', 'comboColumn');
        merged.forEach((layer, index) => {
            if (layer.options.points?.value?.color) {
                output += `${cssPrefix} .chart_index_${index + 1} text { fill: ${
                    layer.options.points.value.color
                } }`;
            }
            if (layer.options.color) {
                output += `${cssPrefix} .chart_index_${index + 1} .vx-bar:not(.bar_group) { fill: ${
                    layer.options.color
                } }`;
            }
            if (layer.options.colors) {
                layer.options.colors.forEach((color, barIndex) => {
                    output += `${cssPrefix} .chart_index_${index + 1} .vx-bar.bar_index_${barIndex +
                        1} { fill: ${color} }`;
                });
            }
            if (layer.options.points?.marker?.color) {
                output += `${cssPrefix} .chart_index_${index + 1} .vx-glyph-dot { fill: ${
                    layer.options.points.marker.color
                } }`;
            }

            if (
                layer.options.points?.marker?.stroke?.enabled &&
                layer.options.points.marker.stroke.color
            ) {
                output += `${cssPrefix} .chart_index_${index + 1} .vx-glyph-dot { stroke: ${
                    layer.options.points.marker.stroke.color
                } }`;
            }

            if (layer.options.stroke && layer.options.stroke.color) {
                output += `${cssPrefix} .chart_index_${index + 1} .vx-linepath { stroke: ${
                    layer.options.stroke.color
                } }`;
            }
        });
        layers.forEach((layer, index) => {
            if (layer.options.points?.marker?.color || layer.options.color) {
                output += `${cssPrefix} .legend_item_index_${index +
                    1} .vx-glyph-dot { fill: ${layer.options.points?.marker?.color ??
                    layer.options.color} }`;
            }
        });
    }
    return output;
};

export const getFlattenedData = data => {
    const flatData = [];

    // generate array of x keys
    data.forEach((series, index) => {
        series.forEach(item => {
            const xKey: string = item.x;
            const xIndex = flatData.findIndex(resultItem => resultItem.x + '' === xKey + '');
            if (xIndex === -1) {
                flatData.push({
                    x: xKey,
                    z: [],
                    y: 0,
                });
            }
        });
    });

    // hydrate z values
    data.forEach((dataItem, index) => {
        flatData.forEach((flatItem, itemIndex) => {
            const matchIndex = dataItem.findIndex(matchItem => {
                const xIndex: string = matchItem.x + '';
                return xIndex === flatItem.x + '';
            });
            const myValue = matchIndex !== -1 ? dataItem[matchIndex].y : 0;
            flatItem.z.push(myValue);
        });
    });

    // hydrate y values
    data.forEach((dataItem, index) => {
        flatData.forEach((flatItem, itemIndex) => {
            flatItem.y = sum(flatItem.z);
        });
    });

    // if x values were originally date objects, change them back to date objects
    if (moment.isDate(data[0][0].x)) {
        flatData.forEach(item => {
            item.x = new Date(item.x);
        });
    }
    return flatData;
};

const chartTypes: ChartTypes[] = [
    'comboArea',
    'comboColumn',
    'comboColumnGroup',
    'comboScatterPlot',
    'comboLine',
];

export const getMergedLayers = (raw: ComboLayer[], key: string, value: string) => {
    let result = raw.slice();
    const matches = raw.filter(item => item.data?.length && item[key] === value);
    if (matches.length > 1) {
        const group: any = {
            chartType: 'comboColumnGroup',
            options: Object.assign(matches[0].options, {
                colors: matches.map(match => match.options.color),
            }),
            name: [...matches.map(item => item.name)],
            type: matches[0].type,
            dataTypes: [...matches.map(item => item.dataType)],
            data: getFlattenedData(matches.map(item => item.data)),
            fields: [...new Set(...matches.map(item => item.fields))],
        };
        result = raw.filter(item => item[key] !== value);
        result.push(group);
    }

    return chartTypes.reduce((prev, curr) => {
        const filtered = result.filter(item => item.chartType === curr);
        if (filtered.length) {
            return [...prev, ...filtered];
        } else {
            return [...prev];
        }
    }, []);
};
