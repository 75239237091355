import Dimension from './Dimension';
import Metric from './Metric';
import Direction from './Direction';
import Field from './Field';
import moment, { unitOfTime } from 'moment';
import 'moment-timezone';
import BulkItem from './BulkItem';
import PostItem from './PostItem';
import { AnalyticsFilter, AnalyticsRequest } from './AnalyticsRequest';
import { compare } from 'natural-orderby';

// TODO: these @sprinklr/display-builder imports need to go away
import TimePeriod from '@sprinklr/display-builder/models/TimePeriod/TimePeriod';
import { usStatesNametoTwo } from '@sprinklr/display-builder/utils/CountryCodeTransformer/CountryCodeTransformer';
import { ViralInsight } from '@sprinklr/stories/analytics/ViralTrends';
import { Post } from '@sprinklr/stories/post/Post';
import TimePeriodService from '@sprinklr/display-builder/services/TimePeriodService/TimePeriodService';
import { normalizeCasingAndSpacing } from '@sprinklr/display-builder/utils/NormalizeLabel/NormalizeLabel';
import { earlierIndexValuesAreLarger } from './helpers';

type SortFunc<T> = (a: T, b: T) => number;

export type FlatDataSetObject = { [key: string]: string | Date | number };
export interface DimensionDataGroup {
    dimension: Dimension;
    value: any;
    data: DataSet;
}

export type GroupedData = {
    dimension: Dimension;
    value: any;
    data: DataSet;
};

type MappedData =
    | number
    | {
          [a: string]: MappedData;
      };

export type DataSetRow = any[] & { isViral?: boolean };
export type ReadonlyDataSetRow = ReadonlyArray<any> & { readonly isViral?: boolean };

export type ViralRowData = {
    rowIndex: number;
    rowValues: ReadonlyDataSetRow;
    insights: ViralInsight[];
    posts: Post[];
};

/**
 * Immutable object containing analytics data
 */
export default class DataSet {
    readonly dimensions: readonly Dimension[];

    readonly metrics: readonly Metric[];

    readonly fields: ReadonlyArray<Field<string>>;

    readonly rows: ReadonlyArray<ReadonlyDataSetRow>;

    readonly totals: readonly number[];

    readonly additional: readonly {}[];

    readonly viralRows?: readonly number[];

    readonly viralTrends?: ViralRowData[];

    constructor(
        dimensions: Dimension[] | readonly Dimension[],
        metrics: Metric[] | readonly Metric[],
        rows: DataSetRow[] | ReadonlyArray<ReadonlyDataSetRow>,
        totals?: readonly number[],
        viralRows?: number[] | readonly number[],
        viralTrends?: ViralRowData[],
        cast = true
    ) {
        this.dimensions = dimensions.slice() as readonly Dimension[];
        this.metrics = metrics.slice() as readonly Metric[];
        // @ts-ignore
        this.fields = dimensions.concat(metrics) as ReadonlyArray<Field<string>>;

        const newViralRows = new Set<number>();

        rows = rows || [];
        this.rows = rows.map((row, rowIndex) => {
            const castRow: DataSetRow = this.fields.map((field: Field<string>, index) => {
                if (!cast) {
                    return row[index];
                }

                // If type is "STRING", check to see if it's actually a timestamp
                if (typeof row[index] === 'string' && row[index].match(/^\d{13}$/)) {
                    field.type = 'TIMESTAMP';
                }

                const castValue = field.cast(row[index]);

                // fix for benchmarking sentiment
                if (field.name === 'SENTIMENT') {
                    if (castValue === '-2') {
                        return 'Negative';
                    } else if (castValue === '2') {
                        return 'Positive';
                    }
                }

                return castValue;
            });

            const isViral = row.isViral ?? viralRows?.includes(rowIndex);
            if (isViral) {
                castRow.isViral = true;
                newViralRows.add(rowIndex);
            }
            return castRow;
        });

        this.viralRows = Array.from(newViralRows.values());
        this.viralTrends = viralTrends;

        this.totals = totals || [];
    }

    toJSON() {
        return {
            dimensions: this.dimensions,
            metrics: this.metrics,
            rows: this.rows.map(row => {
                return row.map(value => {
                    if (value instanceof Date) {
                        return value.getTime();
                    }
                    return value;
                });
            }),
            viralRows: this.viralRows,
            viralTrends: this.viralTrends?.map(trendRowData => {
                return {
                    ...trendRowData,
                    rowValues: trendRowData.rowValues.map(value => {
                        if (value instanceof Date) {
                            return value.getTime();
                        }
                        return value;
                    }),
                };
            }),
        };
    }

    static create(dataSet: any) {
        return new DataSet(
            dataSet.dimensions.map((dimension: Dimension) => {
                return new Dimension(dimension.name, dimension.type, dimension.dataType);
            }),
            dataSet.metrics.map((metric: Metric) => {
                return new Metric(
                    metric.name,
                    metric.type,
                    metric.dataType,
                    metric.aggregateFunction,
                    metric.alternateHeading
                );
            }),
            dataSet.rows,
            dataSet.totals,
            dataSet.viralRows,
            dataSet.viralTrends
        );
    }

    getFirstMetric(): Metric | undefined {
        return this.metrics.length > 0 ? this.metrics[0] : undefined;
    }
    getSecondMetric(): Metric | undefined {
        return this.metrics?.[1];
    }

    getFirstDimension(): Dimension | undefined {
        return this.dimensions.length > 0 ? this.dimensions[0] : undefined;
    }
    getSecondDimension(): Dimension | undefined {
        return this.dimensions?.[1];
    }

    getMetricIndex(m: Metric): number {
        if (!m) {
            return -1;
        }

        const foundIndex = this.metrics.findIndex(
            metric =>
                metric.name === m.name && metric.type === m.type && metric.dataType === m.dataType
        );

        if (foundIndex === -1) {
            return foundIndex;
        }

        return foundIndex + this.dimensions.length;
    }

    getDimensionIndex(d: Dimension): number {
        if (!d) {
            return -1;
        }

        const normalizeName = string => string?.toUpperCase().replace(/[^\w]/g, '_');

        return this.dimensions.findIndex(
            dimension =>
                (normalizeName(dimension.name) === normalizeName(d.name) ||
                    normalizeName(dimension.dimensionName) === normalizeName(d.name)) &&
                dimension.type === d.type
        );
    }

    valueAt(offset: number): any {
        const rows = this.rows;
        const row = (rows && rows.length && rows[0].length && rows[0]) || null;
        let value = null;

        if (row && offset < row.length) {
            value = row[offset];
        }

        return value;
    }

    getTotals(): readonly number[] {
        return this.totals;
    }

    getTotal(metric: Metric): number {
        return this.totals[this.assertMetricIndex(metric)];
    }

    indexedTotals(): { [a: string]: number } {
        const values = {};
        this.totals.forEach((value, index) => {
            values[this.metrics[index].name] = value;
        });
        return values;
    }

    getFirstTotal(): number | undefined {
        return this.totals?.length > 0 ? this.totals[0] : undefined;
    }

    /**
     * Returns a copy of the given AnalyticsResult, sorted by the given Metric
     *
     * @param metric The Metric to sort by
     * @param fn The sort function
     * @returns {DataSet}
     */
    sortByMetric(metric: Metric, fn: SortFunc<number>): DataSet {
        return this.sortByFieldIndexComparator(this.assertMetricIndex(metric), fn);
    }

    /**
     * Returns a copy of the given AnalyticsResult, sorted by the given Metric and the specified Direction
     *
     * @param metric The Metric to sort by
     * @param dir The Direction to sort by, Direction.ASC or Direction.DESC
     * @returns {DataSet}
     */
    sortByMetricDirection(metric: Metric, dir: Direction): DataSet {
        return this.sortByFieldIndexComparator(
            this.assertMetricIndex(metric),
            this.getComparator(dir)
        );
    }

    /**
     * Returns a copy of the given AnalyticsResult, sorted by the given Dimension
     *
     * @param dimension The Dimension to sort by
     * @param fn The sort function
     * @returns {DataSet}
     */
    sortByDimension(dimension: Dimension, fn: SortFunc<any>): DataSet {
        return this.sortByFieldIndexComparator(this.assertDimensionIndex(dimension), fn);
    }

    /**
     * Returns a copy of the given AnalyticsResult, sorted by the given Dimension and the specified Direction
     *
     * @param dimension The Dimension to sort by
     * @param dir The Direction to sort by
     * @returns {DataSet}
     */
    sortByDimensionDirection(dimension: Dimension, dir: Direction): DataSet {
        return this.sortByFieldIndexComparator(
            this.assertDimensionIndex(dimension),
            this.getComparator(dir)
        );
    }

    /**
     * Returns a copy of the given AnalyticsResult, sorted by the field at the given index and the given value sort function
     *
     * @param index The field index to sort by
     * @param fn The value sort function
     * @returns {DataSet}
     */
    sortByFieldIndexComparator(index: number, fn: SortFunc<any>): DataSet {
        return this.derivedDataset(rows => {
            return rows.slice().sort((a: readonly any[], b: readonly any[]): number => {
                // If the dimension has a sortValue, use that when sorting.
                if (a[index] !== undefined && a[index] !== null && a[index].sortValue) {
                    const aVal = a[index].sortValue;
                    const bVal =
                        b[index] !== undefined && b[index] !== null ? b[index].sortValue : null;
                    return fn(aVal, bVal);
                }

                return fn(a[index], b[index]);
            });
        }, false);
    }

    /**
     * Returns a copy of the given AnalyticsResult, sorted by the given row sort function
     *
     * @param fn The row sort funciton
     * @returns {DataSet}
     */
    sort(fn: SortFunc<readonly any[]>): DataSet {
        return this.derivedDataset(rows => {
            return rows.slice().sort(fn);
        }, false);
    }

    /**
     * Returns a copy of the given AnalyticsResult, filtered by the given row filter function
     *
     * @param fn The row filter function
     * @returns {DataSet}
     */
    filter(fn: (a: readonly any[]) => boolean): DataSet {
        return this.derivedDataset(rows => {
            return rows.filter(fn);
        }, false);
    }

    /**
     * Returns a unique key representing the dimension values in the row
     * @param row
     * @param dimensions
     * @returns {string}
     */
    rowKey(row: any[] | ReadonlyArray<any>, dimensions?: Dimension[]): string {
        if (!dimensions) {
            return row
                .slice(0, this.dimensions.length)
                .map(value => value.id || value.toString())
                .join('|||');
        }

        return dimensions
            .map((dimension: Dimension) => {
                const dimIndex = this.getDimensionIndex(dimension);
                return row[dimIndex];
            })
            .join('|||');
    }

    /**
     * Given a previous DataSet, returns an [][] of percent changes between 0.0 and 100
     *
     * @param previous
     * @returns {number}
     */
    percentChange(previous: DataSet): number[][] {
        if (!previous) {
            return null;
        }

        const result = [];

        // Finds a matching previous row using dimensions
        // This is because the current and previous datasets may not be in the same order
        const prevRow = row => {
            const dims = row.slice(0, previous.dimensions.length);
            return previous.rows.find(r => {
                for (let i = 0; i < dims.length; i++) {
                    if (dims[i].toString() !== r[i].toString()) {
                        return false;
                    }
                }
                return true;
            });
        };

        this.rows.forEach((row, offset) => {
            const rowPercentChange: number[] = [];

            const rowPrevious = prevRow(row);
            let percentChange = 0;

            for (let x = this.dimensions.length; x < row.length; x++) {
                const value = row[x];

                if (rowPrevious && offset < previous.rows.length) {
                    const valuePrevious = rowPrevious[x];

                    if (valuePrevious === 0 && value > 0) {
                        percentChange = 100;
                    } else if (valuePrevious === 0 && value === 0) {
                        percentChange = 0;
                    } else {
                        percentChange = (value / valuePrevious - 1) * 100;
                    }
                } else if (value > 0) {
                    percentChange = 100;
                }

                rowPercentChange.push(percentChange);
            }

            result.push(rowPercentChange);
        });

        return result;
    }

    static percentChanged(current: number, previous: number): number {
        if (previous === 0.0 || previous === undefined) {
            if (current === 0.0) {
                return 0.0;
            } else {
                return 100.0;
            }
        } else {
            const percentChange = (current / previous - 1) * 100.0;
            return +percentChange.toFixed(3);
        }
    }

    /**
     * Returns a copy of AnalyticsResult with the given Metric removed
     *
     * @param metric
     * @returns {DataSet}
     */
    removeMetric(metric: Metric): DataSet {
        const index = this.assertMetricIndex(metric);

        const rows = this.rows.map(row => {
            // splice doesn't work the same on tuples. Copy it to an array first
            const modded = row.slice(0);
            modded.splice(index, 1);
            return modded;
        });

        const metrics = this.metrics.slice();
        metrics.splice(index - this.dimensions.length, 1);

        return new DataSet(
            this.dimensions,
            metrics,
            rows,
            this.totals,
            this.viralRows,
            this.viralTrends,
            false
        );
    }

    removeDimension(dimension: Dimension): DataSet {
        const index = this.assertDimensionIndex(dimension);

        const rows = this.rows.map(row => {
            // splice doesn't work the same on tuples. Copy it to an array first
            const modded = row.slice();
            modded.splice(index, 1);
            return modded;
        });

        const dimensions = this.dimensions.slice();
        dimensions.splice(index, 1);

        return new DataSet(
            dimensions,
            this.metrics,
            rows,
            this.totals,
            this.viralRows,
            this.viralTrends,
            false
        );
    }

    filterRowsData(): DataSet {
        if (this.dimensions?.[0]?.name) {
            const dimensionName = this.dimensions[0].name;
            if (dimensionName === 'COUNTRY' || dimensionName === 'Country') {
                const rows = this.rows.filter(
                    row => row[0] !== 'Unknown' && row[0]?.name !== 'Unknown'
                );
                return new DataSet(
                    this.dimensions,
                    this.metrics,
                    rows,
                    this.totals,
                    undefined,
                    this.viralTrends,
                    false
                );
            } else if (dimensionName === 'US_STATE' || dimensionName === 'US State') {
                const rows = this.rows.filter(row => usStatesNametoTwo[row[0]] !== undefined);
                return new DataSet(
                    this.dimensions,
                    this.metrics,
                    rows,
                    this.totals,
                    undefined,
                    this.viralTrends,
                    false
                );
            } else {
                return this;
            }
        } else {
            return this;
        }
    }

    reorderDimensions(dimensions: Dimension[]): DataSet {
        const dimIndexes = dimensions.map((dimension: Dimension) => {
            return this.assertDimensionIndex(dimension);
        });

        const metricIndexes = this.metrics.map((metric: Metric) => {
            return this.assertMetricIndex(metric);
        });

        const rows = this.rows.map(row => {
            const newRow: any[] = [];

            dimIndexes.forEach((index: number) => {
                newRow.push(row[index]);
            });

            metricIndexes.forEach((index: number) => {
                newRow.push(row[index]);
            });

            return newRow;
        });

        return new DataSet(
            dimensions,
            this.metrics,
            rows,
            this.totals,
            this.viralRows,
            this.viralTrends,
            false
        );
    }

    reorderMetrics(metrics: Metric[]): DataSet {
        const dimIndexes = this.dimensions.map((dimension: Dimension) => {
            return this.assertDimensionIndex(dimension);
        });

        const metricIndexes = metrics.map((metric: Metric) => {
            return this.assertMetricIndex(metric);
        });

        const rows = this.rows.map(row => {
            const newRow: any[] = [];

            dimIndexes.forEach((index: number) => {
                newRow.push(row[index]);
            });

            metricIndexes.forEach((index: number) => {
                newRow.push(row[index]);
            });

            return newRow;
        });

        return new DataSet(
            this.dimensions,
            metrics,
            rows,
            this.totals,
            this.viralRows,
            this.viralTrends,
            false
        );
    }

    /**
     * Get all the rows for a given Dimension value.
     *
     * @param dimension
     * @param value
     * @returns {DataSet}
     */
    pluck(dimension: Dimension, value?: any): DataSet {
        const index = this.assertDimensionIndex(dimension);
        let rows: ReadonlyArray<DataSetRow> | ReadonlyArray<ReadonlyDataSetRow>;

        if (typeof value !== 'undefined') {
            const valueString = value + '';
            rows = this.rows.filter(row => {
                if (value.id && row[index].id) {
                    return row[index].id === value.id;
                } else {
                    return row[index] + '' === valueString;
                }
            }) as any[][];
        } else {
            rows = this.rows;
        }

        rows = rows.map(row => {
            // splice doesn't work the same on tuples. Copy it to an array first
            const modded: DataSetRow = row.slice();
            modded.splice(index, 1);
            modded.isViral = row.isViral;

            return modded;
        });

        const dimensions = this.dimensions.slice();
        dimensions.splice(index, 1);

        return new DataSet(
            dimensions,
            this.metrics,
            rows,
            this.totals,
            undefined,
            this.viralTrends,
            false
        );
    }

    /**
     * Group all the rows by the given Dimension's values
     *
     * @param dimension
     * @returns {DimensionDataGroup[]}
     */
    groupBy(dimension: Dimension): Array<GroupedData> {
        dimension = dimension || this.getFirstDimension();
        const metrics = this.metrics;

        try {
            const index = this.assertDimensionIndex(dimension);
            const dimensions = this.dimensions.slice();
            dimensions.splice(index, 1);

            // Space returns percentage values that add to 100% (more or less)
            // The mobile API we use adds up to much less (like 12%).
            // However, if we massage the values to be "% of total", then the
            // values match Space.
            if (dimensions[0] && metrics.some(metric => metric.dataType === 'PERCENTAGE')) {
                const rowOffsets = this.groupByRowOffsets(dimensions[0]);

                metrics.forEach((metric, offset) => {
                    if (metric.aggregateFunction === 'PERCENTAGE') {
                        const index = this.dimensions.length + offset;
                        rowOffsets.forEach(offsets => {
                            let total = 0;
                            offsets.forEach(offset => (total += this.rows[offset][index]));
                            offsets.forEach(offset => {
                                if (total === 0) {
                                    (this.rows[offset][index] as any) = 0;
                                } else {
                                    (this.rows[offset][index] as any) =
                                        (this.rows[offset][index] / total) * 100.0;
                                }
                            });
                        });
                    }
                });
            }

            const indexOrder = [];
            const indexedGroups: {
                [value: string]: { value: any; rows: any[][]; totals: number[] };
            } = {};

            this.rows.forEach(row => {
                const value = row[index];
                let dupIndex = '';
                if (value instanceof BulkItem) {
                    if (value instanceof PostItem) {
                        dupIndex = value.id || (value as any).message;
                    } else {
                        dupIndex = value.id;
                    }
                } else {
                    dupIndex = value === null || value === undefined ? '' : value + '';
                }

                let group: { value: any; rows: any[][]; totals: number[] };
                if (dupIndex in indexedGroups) {
                    group = indexedGroups[dupIndex];
                } else {
                    group = indexedGroups[dupIndex] = {
                        value,
                        rows: [],
                        totals: metrics.map(() => 0),
                    };
                    indexOrder.push(dupIndex);
                }

                // splice doesn't work the same on tuples. Copy it to an array first
                const modded = row.slice();
                modded.splice(index, 1);

                metrics.forEach((metric, index) => {
                    group.totals[index] += modded[dimensions.length + index];
                });

                group.rows.push(modded);
            });

            return indexOrder.map(value => {
                const group = indexedGroups[value];
                return {
                    dimension,
                    value: group.value,
                    data: new DataSet(
                        dimensions,
                        this.metrics,
                        group.rows,
                        group.totals,
                        undefined,
                        undefined,
                        false
                    ),
                };
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * Format data as formatted in groupBy, but without grouping duplicate values
     *
     * @param dimension
     * @returns {DimensionDataGroup[]}
     */
    formatData(dimension: Dimension): Array<GroupedData> {
        dimension = dimension || this.getFirstDimension();
        const metrics = this.metrics;

        try {
            const index = this.assertDimensionIndex(dimension);
            const dimensions = this.dimensions.slice();
            dimensions.splice(index, 1);

            // Space returns percentage values that add to 100% (more or less)
            // The mobile API we use adds up to much less (like 12%).
            // However, if we massage the values to be "% of total", then the
            // values match Space.
            if (metrics.some(metric => metric.dataType === 'PERCENTAGE')) {
                const rowOffsets = this.groupByRowOffsets(dimensions[0]);

                metrics.forEach((metric, offset) => {
                    if (metric.aggregateFunction === 'PERCENTAGE') {
                        const index = this.dimensions.length + offset;
                        rowOffsets.forEach(offsets => {
                            let total = 0;
                            offsets.forEach(offset => (total += this.rows[offset][index]));
                            offsets.forEach(offset => {
                                if (total === 0) {
                                    (this.rows[offset][index] as any) = 0;
                                } else {
                                    (this.rows[offset][index] as any) =
                                        (this.rows[offset][index] / total) * 100.0;
                                }
                            });
                        });
                    }
                });
            }

            return this.rows.map(row => {
                const group = {
                    value: row[0],
                    rows: [],
                    totals: metrics.map(() => 0),
                };

                // splice doesn't work the same on tuples. Copy it to an array first
                const modded = row.slice();
                modded.splice(index, 1);
                group.rows.push(modded);

                return {
                    dimension,
                    value: group.value,
                    data: new DataSet(
                        dimensions,
                        this.metrics,
                        group.rows,
                        group.totals,
                        undefined,
                        undefined,
                        false
                    ),
                };
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * Group all the rows by the given Dimension's values
     *
     * @param dimension
     * @returns {DimensionDataGroup[]}
     */
    groupByAggregate(dimension: Dimension): DataSet {
        dimension = dimension || this.getFirstDimension();
        const metrics = this.metrics;

        const index = this.assertDimensionIndex(dimension);
        const dimensions = this.dimensions;

        const indexedGroups: {
            [value: string]: {
                value: any;
                totals: number[];
                counts: number[];
                maxs: number[];
                mins: number[];
            };
        } = {};

        this.rows.forEach(row => {
            const value = row[index];
            const dupIndex = value === null || value === undefined ? '' : value + '';

            let group: {
                value: any;
                totals: number[];
                counts: number[];
                maxs: number[];
                mins: number[];
            };
            if (dupIndex in indexedGroups) {
                group = indexedGroups[dupIndex];
            } else {
                const stub = metrics.map(() => 0);
                group = indexedGroups[dupIndex] = {
                    value,
                    totals: stub.slice(),
                    counts: stub.slice(),
                    maxs: stub.slice(),
                    mins: stub.slice(),
                };
            }

            metrics.forEach((metric, index) => {
                const val = row[dimensions.length + index];
                group.totals[index] += val || 0;
                group.counts[index] += 1;
                group.maxs[index] = Math.max(group.maxs[index], val);
                group.mins[index] = Math.min(group.maxs[index], val);
            });
        });

        const rows = Object.keys(indexedGroups).map(value => {
            const group = indexedGroups[value];
            return [group.value, ...group.totals];
        });

        return new DataSet(
            [dimension],
            this.metrics,
            rows,
            this.totals,
            undefined,
            this.viralTrends,
            false
        );
    }

    /**
     * Group all the original row offsets by the given Dimension's values
     *
     * @param dimension
     * @returns { number[][] }
     */
    groupByRowOffsets(dimension: Dimension): number[][] {
        try {
            const index = this.assertDimensionIndex(dimension);

            const duplicates = {};
            const result: number[][] = [];

            this.rows.forEach(row => {
                const value = row[index];
                const strVal = value ? value + '' : '';

                if (!(strVal in duplicates)) {
                    const offsets: number[] = [];

                    this.rows.forEach((rowInner, offset) => {
                        if (rowInner[index] + '' === value + '') {
                            offsets.push(offset);
                        }
                    });

                    result.push(offsets);
                    duplicates[strVal] = true;
                }
            });

            return result;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getStringFormatter(dimension: Dimension, formatter?: any) {
        if (typeof formatter === 'string') {
            const formatString = formatter;
            return function(value: any): string {
                if (value === 0 || value) {
                    return moment(+value).format(formatString);
                }
                return '';
            };
        }

        if (typeof formatter === 'function') {
            return formatter;
        }

        return function(value: any) {
            if (value === 0 || value) {
                if (value instanceof BulkItem) {
                    return value;
                }
                if (dimension) {
                    return dimension.stringify(value);
                }
                if (value instanceof Date) {
                    return value.toISOString();
                }
                return value + '';
            }
            return '';
        };
    }

    allStrings(): string[] {
        if (!this.dimensions) {
            return [];
        }

        const map = {};

        this.dimensions.forEach(dimension => {
            const categories = this.categories(dimension);
            categories.forEach(category => {
                map[category] = true;
            });
        });

        return Object.keys(map);
    }

    /**
     * Return an array of all the unique values for the given dimension as Strings
     *
     * @param dimension
     * @param formatter Function to convert values to a formatted string
     * @returns {any[]}
     */
    categories(dimension?: Dimension, formatter?: any): string[] {
        dimension = dimension || this.dimensions[0];

        // If this dimension is one of temporal types, then use those values directly
        const builtIn = DataSet.timeSeriesKeys(dimension);
        if (builtIn) {
            return builtIn;
        }

        const index = this.assertDimensionIndex(dimension);

        const duplicates = {};
        let values: any[] = [];

        formatter = this.getStringFormatter(dimension, formatter);

        this.rows.forEach(row => {
            const val: any = row[index];
            const strVal = formatter(row[index]);
            if (!(strVal in duplicates)) {
                values.push(val);
                duplicates[strVal] = true;
            }
        });

        if (dimension.type !== 'STRING') {
            values.sort(this.getComparator(Direction.ASC));
        }

        values = values.map(formatter);

        return values;
    }

    /**
     * @param rowCopier
     * @param cast
     * @returns {DataSet}
     */
    derivedDataset(
        rowCopier: (rows: ReadonlyArray<ReadonlyDataSetRow>) => any[][] | ReadonlyArray<any>[],
        cast = true
    ): DataSet {
        // copy and transform the this
        const newRows = rowCopier(this.rows);

        // keep the metrics & dimensions
        return new DataSet(
            this.dimensions,
            this.metrics,
            newRows,
            this.totals,
            undefined,
            this.viralTrends,
            cast
        );
    }

    /**
     * Returns the total of the given Metric's values
     *
     * @param metric
     * @returns {number}
     */
    sum(metric?: Metric): number {
        let sum = 0;
        metric = metric || this.metrics[0];
        const metricIndex = this.assertMetricIndex(metric);

        let r,
            rl = this.rows.length;
        for (r = 0; r < rl; ++r) {
            const row = this.rows[r];
            sum += row[metricIndex];
        }

        return sum;
    }

    /**
     * Returns the lowest of the given Metric's values
     *
     * @param metric
     * @returns {number}
     */
    min(metric?: Metric): number {
        let min = null;
        metric = metric || this.metrics[0];
        const metricIndex = this.assertMetricIndex(metric);

        let r,
            rl = this.rows.length;
        for (r = 0; r < rl; ++r) {
            const value = this.rows[r][metricIndex];
            if (min === null || min > value) {
                min = value;
            }
        }

        return min || 0;
    }

    /**
     * Returns the highest of the given Metric's values
     *
     * @param metric
     * @returns {number}
     */
    max(metric?: Metric): number {
        let max = null;
        metric = metric || this.metrics[0];
        const metricIndex = this.assertMetricIndex(metric);

        let r,
            rl = this.rows.length;
        for (r = 0; r < rl; ++r) {
            const value = this.rows[r][metricIndex];
            if (max === null || max < value) {
                max = value;
            }
        }

        return max || 0;
    }

    periodicChange(): DataSet {
        switch (this.dimensions.length) {
            case 0:
                return this;
            case 1:
                if (!this.dimensions[0].isTimeSeries()) {
                    return this;
                }
                const prevVals: number[] = this.metrics.map((metric, index) => 0);

                return this.sortByDimensionDirection(
                    this.dimensions[0],
                    Direction.ASC
                ).derivedDataset(rows => {
                    return rows.map(row => {
                        const newRow = [row[0]];
                        this.metrics.forEach((metric, index) => {
                            const curVal = row[index + 1] || 0;
                            const prevVal = prevVals[index];
                            newRow.push(curVal - prevVal);
                            prevVals[index] = curVal;
                        });
                        return newRow;
                    });
                }, false);
            case 2:
                if (!this.dimensions[0].isTimeSeries() && !this.dimensions[1].isTimeSeries()) {
                    return this;
                }
                const nonTimeIndex = this.dimensions[0].isTimeSeries() ? 1 : 0;
                const nonTimeDim = this.dimensions[nonTimeIndex];

                const newRows: any[] = [];
                this.zeroFillDimensions()
                    .groupBy(nonTimeDim)
                    .forEach(value => {
                        value.data.periodicChange().rows.forEach(row => {
                            const metrics = row.slice();
                            const tsValue = metrics.shift();
                            const nonTimeValue = value.dimension.cast(value.value);
                            newRows.push([
                                nonTimeIndex === 0 ? nonTimeValue : tsValue,
                                nonTimeIndex === 1 ? nonTimeValue : tsValue,
                                ...metrics,
                            ]);
                        });
                    });

                // keep the metrics & dimensions
                return new DataSet(
                    this.dimensions,
                    this.metrics,
                    newRows,
                    this.totals,
                    undefined,
                    this.viralTrends,
                    false
                );
            default:
                throw new Error(`Sorry I didn't want to code cumulative for 3+ dimensions`);
        }
    }

    periodicPercentChange(): DataSet {
        switch (this.dimensions.length) {
            case 0:
                return this;
            case 1:
                if (!this.dimensions[0].isTimeSeries()) {
                    return this;
                }
                const prevVals: number[] = this.metrics.map((metric, index) => 0);

                return this.sortByDimensionDirection(
                    this.dimensions[0],
                    Direction.ASC
                ).derivedDataset(rows => {
                    return rows.map(row => {
                        const newRow = [row[0]];
                        this.metrics.forEach((metric, index) => {
                            const curVal = row[index + 1] || 0;
                            const prevVal = prevVals[index];
                            if (prevVal === 0) {
                                newRow.push(0);
                            } else {
                                newRow.push((curVal - prevVal) / prevVal);
                            }
                            prevVals[index] = curVal;
                        });
                        return newRow;
                    });
                }, false);
            case 2:
                if (!this.dimensions[0].isTimeSeries() && !this.dimensions[1].isTimeSeries()) {
                    return this;
                }
                const nonTimeIndex = this.dimensions[0].isTimeSeries() ? 1 : 0;
                const nonTimeDim = this.dimensions[nonTimeIndex];

                const newRows: any[] = [];
                this.zeroFillDimensions()
                    .groupBy(nonTimeDim)
                    .forEach(value => {
                        value.data.periodicPercentChange().rows.forEach(row => {
                            const metrics = row.slice();
                            const tsValue = metrics.shift();
                            const nonTimeValue = value.dimension.cast(value.value);
                            newRows.push([
                                nonTimeIndex === 0 ? nonTimeValue : tsValue,
                                nonTimeIndex === 1 ? nonTimeValue : tsValue,
                                ...metrics,
                            ]);
                        });
                    });

                // keep the metrics & dimensions
                return new DataSet(
                    this.dimensions,
                    this.metrics,
                    newRows,
                    this.totals,
                    undefined,
                    this.viralTrends,
                    false
                );
            default:
                throw new Error(`Sorry I didn't want to code cumulative for 3+ dimensions`);
        }
    }

    cumulative(): DataSet {
        switch (this.dimensions.length) {
            case 0:
                return this;
            case 1:
                if (!this.dimensions[0].isTimeSeries()) {
                    return this;
                }
                const metricTotals: number[] = this.metrics.map((metric, index) => 0);
                return this.sortByDimensionDirection(
                    this.dimensions[0],
                    Direction.ASC
                ).derivedDataset(rows => {
                    return rows.map(row => {
                        const newRow = [row[0]];
                        this.metrics.forEach((metric, index) => {
                            newRow.push((metricTotals[index] += row[index + 1]));
                        });
                        return newRow;
                    });
                }, false);
            case 2:
                if (!this.dimensions[0].isTimeSeries() && !this.dimensions[1].isTimeSeries()) {
                    return this;
                }
                const nonTimeIndex = this.dimensions[0].isTimeSeries() ? 1 : 0;
                const nonTimeDim = this.dimensions[nonTimeIndex];

                const newRows: any[] = [];
                this.zeroFillDimensions()
                    .groupBy(nonTimeDim)
                    .forEach(value => {
                        value.data.cumulative().rows.forEach(row => {
                            const metrics = row.slice();
                            const tsValue = metrics.shift();
                            const nonTimeValue = value.dimension.cast(value.value);
                            newRows.push([
                                nonTimeIndex === 0 ? nonTimeValue : tsValue,
                                nonTimeIndex === 1 ? nonTimeValue : tsValue,
                                ...metrics,
                            ]);
                        });
                    });

                // keep the metrics & dimensions
                return new DataSet(
                    this.dimensions,
                    this.metrics,
                    newRows,
                    this.totals,
                    undefined,
                    this.viralTrends,
                    false
                );
            default:
                throw new Error(`Sorry I didn't want to code cumulative for 3+ dimensions`);
        }
    }

    limit(limit: number): DataSet {
        // keep the metrics & dimensions
        return new DataSet(
            this.dimensions,
            this.metrics,
            this.rows.slice(0, limit),
            this.totals,
            this.viralRows,
            this.viralTrends,
            false
        );
    }

    collapseDimension(dimension: Dimension, metric?: Metric): DataSet {
        if (this.dimensions.length === 0) {
            throw new Error('Cannot collapse dimension on dataset with no dimensions');
        }

        dimension = dimension || this.dimensions[0];
        const dimensionIndex = this.assertDimensionIndex(dimension);
        const groupDimensions = this.dimensions.slice();
        groupDimensions.splice(dimensionIndex, 1);
        metric = metric || this.getFirstMetric();
        if (!metric) {
            return this;
        }

        const rows: any[][] = [];

        if (groupDimensions.length === 0) {
            const sortedData = this.sortByMetricDirection(metric, Direction.DESC);
            const topRow = sortedData.rows[0];
            const topDimVal = topRow[sortedData.getDimensionIndex(dimension)];
            const row = [topDimVal];

            this.metrics.forEach((metric: Metric) => {
                row.push(this.sum(metric));
            });
            rows.push(row);
        } else {
            const groupDimension = groupDimensions[0];
            const groupDimensionIndex = this.getDimensionIndex(groupDimension);

            const groups = this.groupBy(groupDimension);

            groups.forEach((group: DimensionDataGroup) => {
                let data = group.data;
                if (group.data.dimensions.length > 1) {
                    data = group.data.collapseDimension(dimension);
                    data.rows.forEach(row => {
                        const newRow = row.slice();
                        newRow.splice(groupDimensionIndex, 0, group.value);
                        rows.push(newRow);
                    });
                } else {
                    const sortedData = data.sortByMetricDirection(metric, Direction.DESC);
                    const topRow = sortedData.rows[0];
                    const topDimVal = topRow[sortedData.getDimensionIndex(dimension)];
                    const row = [topDimVal];
                    row.splice(groupDimensionIndex, 0, group.value);

                    this.metrics.forEach((metric: Metric, index) => {
                        row.push(group.data.totals[index]);
                    });
                    rows.push(row);
                }
            });
        }

        return new DataSet(
            this.dimensions,
            this.metrics,
            rows,
            this.totals,
            undefined,
            this.viralTrends,
            false
        );
    }

    indexByDimensions() {
        if (this.metrics.length === 0) {
            throw new Error('At least one metric is required');
        }

        const dimensions = this.dimensions;
        const result: { [a: string]: MappedData } = {};
        this.rows.forEach((row: any) => {
            let obj: MappedData = result;
            // for each dimension, created a nested object, keyed by the dimension value in the row
            dimensions.forEach((dimension: Dimension, dimIndex) => {
                const dimValueKey = dimension.stringify(row[dimIndex]);
                const isLast = dimIndex + 1 === dimensions.length;
                if (isLast) {
                    // add the full row value
                    obj[dimValueKey] = row;
                } else {
                    // create an empty nested object for the dim value
                    obj = obj[dimValueKey] = obj[dimValueKey] || {};
                }
            });
        });

        return result;
    }

    /**
     * Returns a hashmap of key value pairs.
     *
     * @returns {{ [a: string]: MappedData }}
     */
    toMap(): { [a: string]: MappedData } {
        if (this.metrics.length === 0) {
            throw new Error('At least one metric is required');
        }

        const result: { [a: string]: MappedData } = {};
        const metricIndices = this.metrics.map((metric: Metric) => {
            return this.getMetricIndex(metric);
        });

        this.rows.forEach((row: any) => {
            let obj: MappedData = result;

            // for each dimension, created a nested object, keyed by the dimension value in the row
            this.dimensions.forEach((dimension: Dimension, dimIndex) => {
                const dimValueKey = dimension.stringify(row[dimIndex]);
                // create an empty nested object for the dim value
                obj = obj[dimValueKey] = obj[dimValueKey] || {};
            });

            // add metric values to the object, keyed by metric name
            this.metrics.forEach((metric: Metric, index) => {
                obj[metric.name] = row[metricIndices[index]];
            });
        });

        return result;
    }

    /**
     * Returns a hashmap of key value pairs.
     *
     * @returns {{ [a: string]: MappedData }}
     */
    toObjects(): Array<{ [key: string]: any }> {
        if (this.metrics.length === 0) {
            throw new Error('At least one metric is required');
        }

        const metricIndices = this.metrics.map((metric: Metric) => {
            return this.getMetricIndex(metric);
        });

        return this.rows.map((row: any) => {
            const obj: { [key: string]: any } = {};

            // for each dimension, created a nested object, keyed by the dimension value in the row
            this.dimensions.forEach((dimension: Dimension, dimIndex) => {
                obj[dimension.name] = row[dimIndex];
            });

            // add metric values to the object, keyed by metric name
            this.metrics.forEach((metric: Metric, index) => {
                obj[metric.name] = row[metricIndices[index]];
            });

            return obj;
        });
    }

    /**
     * Returns a hashmap of key value pairs.
     *
     * @returns { [a: string]: string | Date | number }[]
     */
    toFlatObjects(): FlatDataSetObject[] {
        if (!this.getFirstDimension()) {
            console.warn('Have no first dimension for dataset');
            return [];
        }
        const firstDimensionName = this.getFirstDimension().name + '';
        const firstMetricName = this.getFirstMetric()?.name + '';

        // if there's only one dimension, toObjects() will work
        if (!this.getSecondDimension()) {
            return this.toObjects();
        }

        // assign the metric to the dimension value
        const flattened = this.toObjects().map(obj => {
            const dimensionValue = obj[this.getSecondDimension().name + ''];
            return {
                [firstDimensionName]: obj[firstDimensionName],
                [dimensionValue]: obj[firstMetricName],
            };
        });

        // combine rows with the same first dimension value
        const flatter = new Map();
        flattened.forEach(item => {
            const mapKey = item[firstDimensionName] + '';
            const firstDim = flatter.get(mapKey);

            if (firstDim) {
                flatter.set(mapKey, {
                    ...firstDim,
                    ...item,
                });
            } else {
                flatter.set(mapKey, item);
            }
        });
        return Array.from(flatter.values());
    }

    /**
     * Return an array of XY pairs, where X is the first Dimension and Y is the first Metric
     *
     * @param data
     * @returns {{x: any, y: number}[]}
     */
    toXY(): Array<{ x: any; y: number }> {
        if (this.dimensions.length === 0) {
            throw new Error('At least one dimension is required');
        }

        if (this.metrics.length === 0) {
            throw new Error('At least one metric is required');
        }
        const firstMetric = this.getFirstMetric();
        if (!firstMetric) {
            return [];
        }

        const metricIndex = this.getMetricIndex(firstMetric);

        return this.rows.map((tuple: any) => {
            return { x: tuple[0], y: tuple[metricIndex] };
        });
    }

    /**
     * Return an array of XY pairs, where X is the first Dimension and Y is the Nth Metric
     *
     * @param index // The index of the metric
     * @returns {{x: any, y: number}[]}
     */
    toXYN(index: number): Array<{ x: any; y: number }> {
        if (this.dimensions.length === 0) {
            throw new Error('At least one dimension is required');
        }

        if (this.metrics.length === 0) {
            throw new Error('At least one metric is required');
        }

        const metricIndex = this.getMetricIndex(this.metrics[index]);

        return this.rows.map((tuple: any) => {
            return { x: tuple[0], y: tuple[metricIndex] };
        });
    }

    /**
     * Return an array of XYZ tuples, where X is the first Dimension, Y is the first Metric, and Z is the second Metric
     *
     * @param data
     * @returns {{x: any, y: number, z: number}[]}
     */
    toXYZ(): Array<{ x: any; y: number; z: number }> {
        if (this.dimensions.length === 0) {
            throw new Error('At least one dimension is required');
        }

        if (this.metrics.length === 0) {
            throw new Error('At least one metric is required');
        }

        const firstMetric = this.getFirstMetric();
        if (!firstMetric) {
            return [];
        }

        const yIndex = this.getMetricIndex(firstMetric);
        const zIndex = yIndex + 1;

        return this.rows.map((tuple: any) => {
            return { x: tuple[0], y: tuple[yIndex], z: tuple[zIndex] };
        });
    }

    /**
     * Return an array of XY pairs with timezone adjusted to request timezone, where X is the first Dimension and Y is the Nth Metric
     *
     * @param index //the index of the metric
     * @param timeZone
     * @returns {{x: number, y: number}[]}
     */
    toLocalXYN(timeZone: string, index: number): Array<{ x: number; y: number }> {
        if (this.dimensions.length === 0) {
            throw new Error('At least one dimension is required');
        }

        if (this.metrics.length === 0) {
            throw new Error('At least one metric is required');
        }

        const metricIndex = this.getMetricIndex(this.metrics[index]);

        return this.rows.map((tuple: any) => {
            const date = tuple[0] instanceof Date ? tuple[0] : +tuple[0].toString();
            const localized = moment(date).tz(timeZone);
            const formattedTime = localized?.format('YYYY-MM-DD HH:mm:ss');
            const browser = moment(formattedTime);

            return { x: browser.valueOf(), y: tuple[metricIndex] };
        });
    }

    /**
     * Return an array of XYZ tuples, where X is the first Metric, Y is the second Metric, and Z is the third Metric
     *
     * @param data
     * @returns {{x: any, y: number, z: number}[]}
     */
    toMetricXYZ(): Array<{ x: any; y: number; z: number }> {
        // if (this.dimensions.length === 0) {
        //     throw new Error('At least one dimension is required');
        // }

        if (this.metrics.length === 0) {
            throw new Error('At least one metric is required');
        }
        const firstMetric = this.getFirstMetric();
        if (!firstMetric) {
            return [];
        }

        const xIndex = this.getMetricIndex(firstMetric);
        const yIndex = xIndex + 1;
        const zIndex = xIndex + 2;

        return this.rows.map((tuple: any) => {
            return { x: tuple[xIndex], y: tuple[yIndex], z: tuple[zIndex] };
        });
    }

    /**
     * Merge the metrics and rows from one DataSet into another.
     * Returns a new, merged DataSet.
     *
     * @param primary The DataSet to merge to
     * @param additional The DataSet to merge in
     * @param additionalOrder The order to merge values in
     * @returns {DataSet}
     */
    static merge(primary: DataSet, additional: DataSet, additionalOrder: number[]): DataSet {
        let dimension2: Dimension;
        let newRow: any[];
        const newRows: any[][] = [];
        additionalOrder = additionalOrder.slice();

        // this checks if the metrics are in ascending order.
        // if not it sorts them and updates additional
        // meant to help achieve correct order of decoration metrics
        if (additionalOrder.length > 1 && earlierIndexValuesAreLarger(additionalOrder)) {
            const copied = new Map();
            additional.metrics.slice().forEach((metric, index) => {
                copied.set(additionalOrder[index], metric);
            });
            additionalOrder = additionalOrder.slice().sort((a, b) => a - b);
            const sortedMetrics = [...copied.entries()]
                .sort((a, b) => a[0] - b[0])
                .map(entry => entry[1]);
            additional = additional.reorderMetrics(sortedMetrics);
        }

        if (primary.dimensions.length !== additional.dimensions.length) {
            throw new Error('Dimension counts are different.  Cannot merge.');
        }

        // Perform sanity check to ensure that "primary" and "additional" have the same metric values
        primary.dimensions.forEach((dimension: Dimension, index: number) => {
            dimension2 = additional.dimensions[index];
            if (dimension.name !== dimension2.name || dimension.type !== dimension2.type) {
                //timestamp dimension types are determined with regex - if one dataset is empty (meaning that dimension will have type string instead), prevent error from being thrown incorrectly
                const isEmptyTimestampDataset =
                    dimension.name === dimension2.name &&
                    (primary.rows.length === 0 || additional.rows.length === 0) &&
                    (dimension.type === 'TIMESTAMP' || dimension2.type === 'TIMESTAMP') &&
                    (dimension.type === 'STRING' || dimension2.type === 'STRING');

                if (!isEmptyTimestampDataset) {
                    throw new Error('Dimension types are different.  Cannot merge.');
                }
            }
        });

        // Create a copy of the metrics
        const newMetrics = primary.metrics.concat([]);

        // Merge our additional metrics in the correct position.
        additional.metrics.forEach((metric: Metric, index: number) => {
            if (additionalOrder[index] === undefined) {
                console.warn(`No index provided for metric ${metric.name}`);
                additionalOrder[index] = newMetrics.length;
                newMetrics.push(metric);
            } else {
                newMetrics.splice(additionalOrder[index], 0, metric);
            }
        });

        const indexRows = (dataSet: DataSet) => {
            return dataSet.rows.reduce((indexed, row, index) => {
                const rowKey = dataSet.rowKey(row);
                indexed[rowKey] = row;
                return indexed;
            }, {} as Record<string, ReadonlyDataSetRow>);
        };

        // Create our new unified rows array
        const unifyRows = (firstDataSet, secondDataSet) => {
            const additionalIndexed = indexRows(secondDataSet);
            const startOfValues = firstDataSet.dimensions.length;
            firstDataSet.rows.forEach((row: any, index: number) => {
                const rowKey = firstDataSet.rowKey(row);

                // Create copy of row to generate new results with
                newRow = row.slice();
                newRows.push(newRow);

                // Look for the rowKey in the "additional" values.  If found, then merge
                // them in the correct position.
                if (rowKey in additionalIndexed) {
                    const row2 = additionalIndexed[rowKey];
                    row2.slice(startOfValues).forEach((value: any, index2: number) => {
                        newRow.splice(startOfValues + additionalOrder[index2], 0, value);
                    });
                } else {
                    // If we didn't find the value within the "additional" values, then
                    // we need to put a default one in there.
                    secondDataSet.metrics.forEach((metric: Metric, index2: number) => {
                        let value: any;

                        switch (metric.type) {
                            case 'STRING':
                                value = '';
                                break;

                            case 'INTEGER':
                            case 'DECIMAL':
                            case 'NUMBER':
                            case 'TIMESTAMP':
                            case 'TIME_INTERVAL':
                            case 'DATE':
                                value = 0;
                                break;
                        }

                        newRow.splice(startOfValues + additionalOrder[index2], 0, value);
                    });
                }
            });
        };

        let dimensions = primary.dimensions;
        //if the primary dataset is empty but additional is not, merge the primary into the additional to prevent an incorrect "No Data Found" error and use the additional's dimensions for the merged dataset
        if (primary.rows.length === 0 && additional.rows.length > 0) {
            dimensions = additional.dimensions;
            unifyRows(additional, primary);
        } else {
            unifyRows(primary, additional);
        }

        const totals = (primary.totals ?? []).slice();
        if (totals.length > 0 && additional.totals && additional.totals.length > 0) {
            additional.totals.forEach((value: any, metricIndex: number) => {
                totals.splice(additionalOrder[metricIndex], 0, value);
            });
        }

        return new DataSet(
            dimensions,
            newMetrics,
            newRows,
            totals,
            primary.viralRows,
            primary.viralTrends
        );
    }

    correctTimezoneMismatch(timeZone: string): DataSet {
        const timestampIndexes = [];

        this.fields.forEach((field, index) => {
            if (field.type === 'TIMESTAMP') {
                timestampIndexes.push(index);
            }
        });

        if (timestampIndexes.length === 0) {
            return this;
        }

        const newRows = [];

        // Adjust dates to local timezone
        this.rows.forEach(row => {
            const newRow = [...row];
            timestampIndexes.forEach(fieldIndex => {
                const isDateType = row[fieldIndex] instanceof Date;

                const date = isDateType ? row[fieldIndex] : row[fieldIndex].toString();
                const localized = moment(date).tz(timeZone);

                const formattedTime = localized?.format('YYYY-MM-DD HH:mm:ss');
                const browser = moment(formattedTime);

                newRow[fieldIndex] = isDateType ? new Date(browser.valueOf()) : browser;
            });
            newRows.push(newRow);
        });

        const newViralTrends = [];
        this.viralTrends?.forEach(viralTrend => {
            const row = viralTrend.rowValues;
            const newRow = [...row];

            timestampIndexes.forEach(fieldIndex => {
                const localized = moment(row[fieldIndex]).tz(timeZone);

                const formattedTime = localized?.format('YYYY-MM-DD HH:mm:ss');
                const browser = moment(formattedTime);

                newRow[fieldIndex] = new Date(browser.valueOf());
            });

            const newViralTrend = { ...viralTrend, rowValues: newRow };
            newViralTrends.push(newViralTrend);
        });

        return new DataSet(
            this.dimensions,
            this.metrics,
            newRows,
            this.totals,
            this.viralRows,
            newViralTrends,
            false
        );
    }

    // Returns a dataset with zeros converted to nulls.
    zeroKill() {
        const metrics = this.metrics;
        const dimCount = this.dimensions.length;

        const newRows = this.rows
            .filter(row => {
                // remove rows with no metric values
                let hasValues = false;
                metrics.forEach((metric, index) => {
                    if (
                        row[index + dimCount] !== 0 &&
                        row[index + dimCount] !== undefined &&
                        row[index + dimCount] !== null
                    ) {
                        hasValues = true;
                    }
                });
                return hasValues;
            })
            .map(row => {
                // remove columns with no metric values
                const newRow: DataSetRow = row.map((value, index) => {
                    return value === 0 ? null : value;
                });

                newRow.isViral = row.isViral;
                return newRow;
            });

        return new DataSet(
            this.dimensions,
            this.metrics,
            newRows,
            this.totals,
            undefined,
            this.viralTrends,
            false
        );
    }

    /**
     * @param values
     * @param request
     * @return {DataSet}
     */
    zeroFillLabels(values: Array<{ id: string; name: string }>, request: AnalyticsRequest) {
        if (!request.groupBys || request.groupBys.length === 0) {
            return this;
        }

        const indexedValues = {};
        const allValues = [];

        values.forEach(value => {
            indexedValues[value.id] = value.name;
            allValues.push(value.name);
        });

        const excludedValues = [];
        const selectedValues = [];
        const dimensionName: string = request.groupBys[0].dimensionName;

        request.filters &&
            request.filters.forEach((filter: AnalyticsFilter) => {
                if (filter.dimensionName === dimensionName) {
                    if (filter.filterType === 'IN' || filter.filterType === 'EQUALS') {
                        filter.values.forEach(value => {
                            const label = indexedValues[value];
                            if (label && !selectedValues.includes(label)) {
                                selectedValues.push(label);
                            }
                        });
                    }
                    if (filter.filterType === 'NIN') {
                        filter.values.forEach(value => {
                            const label = indexedValues[value];
                            if (label && !excludedValues.includes(label)) {
                                excludedValues.push(label);
                            }
                        });
                    }
                }
            });

        const targetValues = (selectedValues.length > 0 ? selectedValues : allValues).filter(
            value => {
                return !excludedValues.includes(value);
            }
        );

        const indexValues = (valueObj: any, categories: string[][], metricNames: string[]) => {
            categories[0].forEach((value: string) => {
                const valueMap = valueObj[value] || {};
                if (categories.length === 1) {
                    metricNames.forEach((name: string) => {
                        if (!(name in valueMap)) {
                            valueMap[name] = 0;
                        }
                    });
                    valueObj[value] = valueMap;
                    return;
                }

                valueObj[value] = valueMap;
                indexValues(valueMap, categories.slice(1), metricNames);
            });
        };

        const valueMap = this.toMap();
        const categories = this.dimensions.slice(1).map((dimension: Dimension) => {
            return this.categories(dimension);
        });
        categories.unshift(targetValues);
        const metricNames = this.metrics.map((metric: Metric) => {
            return metric.name;
        });
        indexValues(valueMap, categories, metricNames);

        const toArray = (object: any, rows?: any[][], currentRow?: any[]) => {
            rows = rows || [];
            currentRow = currentRow || [];
            let isFinal = false;

            let valueRow;
            Object.keys(object).forEach((key: string) => {
                const value = object[key];

                if (typeof value === 'object') {
                    valueRow = currentRow.slice(0);
                    valueRow.push(key);
                    toArray(value, rows, valueRow);
                } else {
                    currentRow.push(value);
                    isFinal = true;
                }
            });

            if (isFinal) {
                rows.push(currentRow);
            }
            return rows;
        };

        const rows = toArray(valueMap);

        return new DataSet(
            this.dimensions,
            this.metrics,
            rows,
            this.totals,
            this.viralRows,
            this.viralTrends
        );
    }

    zeroFillDimensions() {
        const indexed = this.indexByDimensions();
        const newRows: any[][] = [];
        const categories = this.dimensions.map(dimension => this.categories(dimension));
        const dimensions = this.dimensions;
        const metrics = this.metrics;

        const visitDimensions = (obj, dimIndex: number, rowFragment: any[]) => {
            const last = dimensions.length === dimIndex + 1;
            const dimension = dimensions[dimIndex];
            categories[dimIndex].forEach(value => {
                const row = rowFragment.slice();
                const castValue = dimension.cast(value);
                row.push(castValue);

                if (last) {
                    if (obj[value]) {
                        newRows.push(obj[value]);
                    } else {
                        metrics.forEach(metric => {
                            row.push(0);
                        });
                        newRows.push(row);
                    }
                } else {
                    visitDimensions(obj[value] || {}, dimIndex + 1, row);
                }
            });
        };

        visitDimensions(indexed, 0, []);

        return new DataSet(
            this.dimensions,
            this.metrics,
            newRows,
            this.totals,
            this.viralRows,
            this.viralTrends,
            true
        );
    }

    // Returns a zerofilled copy of the dataset
    zeroFill(
        timePeriod: TimePeriod,
        timeZone: string,
        limit: number,
        filters: AnalyticsFilter[],
        interval?: string
    ): DataSet {
        if (!timeZone) {
            // Default TZ
            timeZone = moment.tz.guess();
        }

        const zeroFillKeys = this.zeroFillKeys(timePeriod, timeZone, filters, interval);

        // No need to zerofill if there aren't any gaps in the DataSet's keys.
        const allCombinations = zeroFillKeys.reduce((total: number, keys: string[]) => {
            return total * keys.length;
        }, 1);

        // Check row size before doing a bunch of recursion
        if (this.rows.length === allCombinations || this.rows.length === limit) {
            return this;
        }

        const newRows = this.zeroFillRecursive(
            this.zeroFillIndex(timeZone),
            zeroFillKeys,
            0,
            timeZone,
            limit
        );

        return new DataSet(
            this.dimensions,
            this.metrics,
            newRows,
            this.totals,
            this.viralRows,
            this.viralTrends
        );
    }

    getPercentChangeIndex(metricName: string) {
        return this.fields.findIndex(field => field.name === metricName + '_PERCENTAGE_CHANGE');
    }

    getMetricValueIndex(metricName: string) {
        return this.fields.findIndex(field => field.name === metricName);
    }

    // Flatten dimensions into a unique key
    private zeroFillIndex(timeZone: string): any {
        const indexed = {};
        this.rows.forEach(row => {
            indexed[
                this.zeroFillIndexString(row as any[], this.dimensions as Dimension[], timeZone)
            ] = row;
        });

        return indexed;
    }

    private zeroFillIndexString(row: any[], dimensions: Dimension[], timeZone: string): string {
        const index = [];
        dimensions.forEach((dimension: Dimension, i: number) => {
            if (dimension.isTimeSeries()) {
                index.push(
                    moment(row[i])
                        .tz(timeZone)
                        .format()
                );
            } else {
                index.push(row[i]);
            }
        });
        return index.join('_');
    }

    private zeroFillRecursive(
        index: any,
        keys: any[],
        depth: number,
        timeZone: string,
        limit: number
    ): any[][] {
        if (!keys[depth]) {
            return [this.metrics.map(m => 0)]; // [[0,0,0]], or similar
        }

        const vals = this.zeroFillRecursive(index, keys, depth + 1, timeZone, limit);

        // Track values
        let firstIndex = -1;
        let lastIndex = -1;

        const newRows = [];
        for (let i = 0, il = keys[depth].length; i < il; i++) {
            for (let j = 0, jl = vals.length; j < jl; j++) {
                const newRow = [keys[depth][i]].concat(vals[j]);

                if (depth === 0) {
                    const key = this.zeroFillIndexString(
                        newRow as any[],
                        this.dimensions as Dimension[],
                        timeZone
                    );
                    if (index[key]) {
                        if (firstIndex < 0) {
                            firstIndex = newRows.length;
                        }
                        lastIndex = newRows.length;
                        for (let k = this.dimensions.length; k < newRow.length; k++) {
                            newRow[k] = index[key][k];
                        }
                    }
                }

                newRows.push(newRow);
            }
        }

        // Apply request limit, but only with a single dimension
        // groupBy limit filters should handle 2d+ scenarios
        if (this.dimensions.length === 1 && newRows.length > limit && firstIndex >= 0) {
            const trimmedRows = [];

            // Add rows from first value to the last value.
            // This may exceed the desired number of rows, as it may will include zerofilled values.
            let i = firstIndex;
            for (i; i <= lastIndex; i++) {
                trimmedRows.push(newRows[i]);
            }

            // Then, keep adding (zeroed) rows to the end, until we hit the limit or the end of the array.
            if (trimmedRows.length < limit) {
                for (i; i < newRows.length && trimmedRows.length < limit; i++) {
                    trimmedRows.push(newRows[i]);
                }
            }

            // Last, keep adding (zeroed) rows to beginning, until we hit the limit or the beginning of the array
            if (trimmedRows.length < limit) {
                i = firstIndex - 1;
                for (i; i >= 0 && trimmedRows.length < limit; i--) {
                    trimmedRows.unshift(newRows[i]);
                }
            }

            return trimmedRows;
        }

        return newRows;
    }

    // Determines how fine-grained the date sequence will be
    // This string will be used by moment.js to fill in the gaps.
    private static timeSeriesPeriod(
        interval: string
    ): moment.unitOfTime.DurationConstructor | undefined {
        if (interval === 'minute' || interval.indexOf('_1m') !== -1) {
            return 'm';
        }

        if (interval === 'hour' || interval.indexOf('_1h') !== -1) {
            return 'h';
        }

        if (interval === 'day' || interval.indexOf('_1d') !== -1) {
            return 'd';
        }

        if (interval === 'week' || interval.indexOf('_1w') !== -1) {
            return 'w';
        }

        if (interval === 'month' || interval.indexOf('_1M') !== -1) {
            return 'M';
        }

        if (interval.indexOf('_1q') !== -1) {
            return 'Q';
        }

        return null;
    }

    private zeroFillKeys(
        timePeriod: TimePeriod,
        timeZone: string,
        filters: AnalyticsFilter[],
        interval?: string
    ): any[] {
        const keys = [];
        const uniqueKeys = {};

        for (let i = 0, il = this.dimensions.length; i < il; i++) {
            const dim = this.dimensions[i];
            const filter = filters.find(
                f => dim.name === f.dimensionName || dim.name === f.details?.displayName
            );

            const fixedKeys = DataSet.timeSeriesKeys(this.dimensions[i]);
            if (fixedKeys) {
                keys[i] = fixedKeys.filter(key => {
                    // Do/Do not include filter values in the fixed keys.
                    // This is important for groupBy limits
                    if (filter && filter.filterType === 'IN') {
                        return filter.values.indexOf(key) > -1;
                    }
                    if (filter && filter.filterType === 'NIN') {
                        return filter.values.indexOf(key) === -1;
                    }
                    return true;
                });
                continue;
            }

            keys[i] = this.categories(this.dimensions[i]);

            if (this.dimensions[i].isTimeSeries()) {
                let min = null;
                let max = null;
                let qty: number = null; // numerical part of interval, e.g. "15"
                let period: string = null; // string part of interval, e.g. "m"

                if (i === 0 && timePeriod) {
                    const startDate = timePeriod.startDate;

                    //apply custom start-of-week day if specified so that zerofill range is correct - AMR
                    let week: unitOfTime.StartOf = 'isoWeek';
                    if (timePeriod.weekStart) {
                        moment.updateLocale('en', {
                            week: {
                                dow: TimePeriodService.weekStartToDay(timePeriod.weekStart),
                            },
                        });
                        week = 'week';
                    }

                    if (interval) {
                        // If interval specified for groupby, Spinrklr API adjusts
                        // the dates to beginning of interval.  So we need to adjust
                        // start date so no data is filtered out.
                        switch (interval.slice(-1)) {
                            case 'w':
                                startDate.startOf(week);
                                break;

                            case 'M':
                                startDate.startOf('month');
                                break;

                            case 'q':
                                startDate.startOf('quarter');
                                break;

                            case 'y':
                                startDate.startOf('year');
                                break;
                        }
                    }

                    min = startDate.format();
                    max = timePeriod.endDate.format();
                } else {
                    keys[i].forEach(key => {
                        const val = moment(key).tz(timeZone);
                        if (val.isValid()) {
                            if (!min || val.isBefore(min)) {
                                min = val;
                            }
                            if (!max || val.isAfter(max)) {
                                max = val;
                            }
                        }
                    });
                }

                if (i !== 0 || !interval) {
                    qty = 1;
                    period = DataSet.timeSeriesPeriod(this.dimensions[i].name);
                } else {
                    qty = parseInt(interval);
                    period = interval.slice(-1);
                    // Quarter is uppercase for moment, API wants lowercase
                    period = period === 'q' ? 'Q' : period;
                }

                if (min && period) {
                    // Pick an initial date that is within the time period.
                    const initialKeys = keys[i].filter(key => {
                        const keyTime = moment(key.match(/^\d+$/) ? parseInt(key) : key);
                        const between = moment(keyTime).isBetween(min, max);
                        return between;
                    });

                    const initial = initialKeys[0]
                        ? moment(
                              initialKeys[0].match(/^\d+$/)
                                  ? parseInt(initialKeys[0])
                                  : initialKeys[0]
                          ).tz(timeZone)
                        : moment(min).tz(timeZone);

                    keys[i] = [];
                    uniqueKeys[i] = [];

                    // Start from a known point
                    let x = initial.clone();

                    // Travel to beginning of time series
                    while (x.isSameOrAfter(min)) {
                        keys[i].unshift(x.format());
                        // Note: there are two subtract() methods -- this one is the non-depricated version.
                        x = x
                            .clone()
                            .subtract(
                                qty as number,
                                period as moment.unitOfTime.DurationConstructor
                            );
                    }

                    // Reset to initial point, plus one
                    x = initial
                        .clone()
                        .add(qty as number, period as moment.unitOfTime.DurationConstructor);

                    // Travel to end of time series
                    while (x.isSameOrBefore(max)) {
                        keys[i].push(x.format());
                        // Note: there are two subtract() methods -- this one is the non-depricated version.
                        x = x
                            .clone()
                            .add(qty as number, period as moment.unitOfTime.DurationConstructor);
                    }
                }
            }
        }

        return keys;
    }

    private static timeSeriesKeys(dimension: Dimension): any[] {
        switch (normalizeCasingAndSpacing(dimension?.name)) {
            case 'DAY_OF_WEEK':
                return [
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                ];
            case 'MONTH_OF_YEAR':
                return [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ];
            case 'TIME_OF_DAY':
                return [
                    '00:00',
                    '01:00',
                    '02:00',
                    '03:00',
                    '04:00',
                    '05:00',
                    '06:00',
                    '07:00',
                    '08:00',
                    '09:00',
                    '10:00',
                    '11:00',
                    '12:00',
                    '13:00',
                    '14:00',
                    '15:00',
                    '16:00',
                    '17:00',
                    '18:00',
                    '19:00',
                    '20:00',
                    '21:00',
                    '22:00',
                    '23:00',
                ];
            default:
                return null;
        }
    }

    private getComparator(direction: Direction) {
        if (direction === Direction.DESC) {
            return compare({ order: 'desc' });
        }
        return compare();
    }

    private assertMetricIndex(metric: Metric) {
        const metricIndex = this.getMetricIndex(metric);
        if (metricIndex === -1) {
            throw new Error(
                'The given metric "' + JSON.stringify(metric) + '" is not in the given data set.'
            );
        }
        return metricIndex;
    }

    private assertDimensionIndex(dimension: Dimension) {
        const dimensionIndex = this.getDimensionIndex(dimension);
        if (dimensionIndex === -1) {
            throw new Error(
                'The given dimension "' +
                    JSON.stringify(dimension) +
                    '" is not in the given data set.'
            );
        }
        return dimensionIndex;
    }
}
