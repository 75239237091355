import { observable } from 'mobx';
import { AnimationValues, TimeStampOptions } from '@sprinklr/stories/widget/types';
import { PostsWindowWidgetOptions, PostsWindowWidgetOptionsImpl } from '../options';

export class PostsWidgetCinematicOptionsImpl extends PostsWindowWidgetOptionsImpl
    implements PostsWindowWidgetOptions {
    // Cinematic specific
    @observable postTextOnlySize = 22;
    @observable postImageTextSize = 22;
    @observable engagementMetricsSize = 30;
    @observable postLineClampValue = 4;
    @observable imagePostLineClampValue = 4;
    @observable userGroupIconSize = 19;
    @observable userGroupAvatarSize = 40;
    @observable userGroupNameSize = 25;
    @observable userGroupScreenNameSize = 20;
    @observable timeStampSize = 20;
    @observable timeStampFormat: TimeStampOptions = 'timeAgo';
    @observable endianTime = 'D MMM YYYY, h:mm a';
    @observable userGroupLayoutType = 'b';
    @observable imageBlendMode = 'normal';
    @observable showSecondaryOverlayColorInUser = false;

    @observable imageSecondaryBlendMode = 'normal';
    @observable animationType: AnimationValues = 'ken_burns';
    @observable useLegacyAnimations = false;
    @observable showSecondaryImageOverlay = true;
    @observable secondaryOverlayColor = 'rgba(0,0,0,.5)';
    @observable imageBlendColor = 'rgba(0,0,0,.3)';
    @observable textOnlyBackgroundColor = '';
    @observable showPostTextOverlay = false;
    @observable maxCharacters?: number = 400;
}
