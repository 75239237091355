import { TreeMapNode } from 'components/_charts/TreeMap/TreeMap';
import { ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';

export const treeMapOptionStyles = options => {
    const { spacing, padding, sentimentSize } = options;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.node',
            styles: {
                padding: styler(spacing, 0.1, 'em', ''),
            },
        },
        {
            selector: '.node_inner',
            styles: {
                padding: styler(padding, 0.1, 'em', ''),
            },
        },
        {
            selector: '.icon__container',
            styles: {
                fontSize: styler(sentimentSize, 0.015, 'em', ''),
            },
        },
    ];

    return computedStyles;
};

export const transformData = (currentDataSet, previousDataSet, blacklist) => {
    const firstMetric = currentDataSet.getFirstMetric();
    const firstMetricIndex = currentDataSet.getMetricIndex(firstMetric);

    const firstDimension = currentDataSet.getFirstDimension();
    const firstDimensionIndex = currentDataSet.getDimensionIndex(firstDimension);

    return currentDataSet.rows
        .filter(row => blacklist.indexOf(row[0]) === -1)
        .map(
            (row): TreeMapNode => {
                const percentChangeIndex = currentDataSet.getPercentChangeIndex(firstMetric.name);
                const percentChange = row[percentChangeIndex];

                const total: number = parseFloat(row[firstMetricIndex]) || 0;
                const name = row[firstDimensionIndex] || '';
                const metricType = currentDataSet.metrics[0].type;

                if (row.length > 3) {
                    const negative: number = row[3] || 0;
                    const sentiment: number = !total ? 0 : 0.5 - negative / total; // DAN'S BEAUTIFUL MATH

                    return {
                        name,
                        value: total,
                        type: metricType,
                        metricValue: total,
                        sentiment:
                            sentiment < 0 ? 'negative' : sentiment > 0 ? 'positive' : 'neutral',
                        color: sentiment < 0 ? '#B33A3A' : null, // why this still in here at all?
                        percentChange,
                    };
                }

                if (row.length >= 1 && row.length <= 3) {
                    return {
                        name,
                        value: total,
                        type: metricType,
                        metricValue: total,
                        percentChange,
                    };
                }
            }
        );
};
