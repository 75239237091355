import { observable } from 'mobx';

import { NativeColorMode } from 'src/widgets/PostsWidget/types';
import { SmartTrendsOptions } from 'components/SmartTrend/types';

export class SmartTrendsWidgetOptionsImpl implements SmartTrendsOptions {
    @observable fontScale = 19;
    @observable colorMode: NativeColorMode = 'dark';
    @observable timeStamp: SmartTrendsOptions['timeStamp'] = {
        enabled: true,
        timeFormat: 'MMMM Do, YYYY h:mma',
    };
    @observable media = {
        enabled: false,
    };
    @observable criticality = {
        enabled: true,
    };
    @observable categories = {
        enabled: true,
    };
    @observable metric: SmartTrendsOptions['metric'] = {
        enabled: true,
        measurement: 'TREND_MENTIONS_COUNT',
        numberFormat: {
            decimals: 1,
            usePretty: true,
        },
    };
    @observable bullets = {
        enabled: true,
        org: {
            enabled: true,
        },
        country: {
            enabled: true,
        },
        person: {
            enabled: true,
        },
        rank: {
            enabled: true,
        },
    };
}
