import { observable } from 'mobx';
import {
    AnimationOrientation,
    DecimalFormat,
    ExtraPostDataMode,
    ExtraPostDataOptions,
    PostTextFormat,
} from '@sprinklr/stories/widget/types';
import {
    GridLayoutImpl,
    NativeStylingOptionsDefaultImpl,
    PostsWidgetOptions,
    PostsWidgetOptionsImpl,
} from '../options';

export class ExtraPostDataOptionsImpl implements ExtraPostDataOptions {
    @observable enabled = true;
    @observable color = '';
    @observable size = 20;
    @observable mode: ExtraPostDataMode = 'table';
    @observable value: {
        format: DecimalFormat;
    } = {
        format: '1.23k',
    };
    @observable border = {
        enabled: true,
        color: '',
    };
    @observable header = {
        enabled: true,
        size: 30,
        text: 'Additional Details',
        color: '',
    };
    @observable dateFormat = 'dddd, MMMM Do YYYY';
}

export class PostCardWidgetOptionsImpl extends PostsWidgetOptionsImpl
    implements PostsWidgetOptions {
    @observable extraPostDataOptions = new ExtraPostDataOptionsImpl();
    @observable showEngagementMetrics = false;
    @observable showAdsMetrics = false;
    @observable textOnlyTextFormat: PostTextFormat = 'clamp';
    @observable postImageTextFormat: PostTextFormat = 'clamp';
    @observable classes = 'rel';
    @observable postLineClampValue = 7;
    @observable postTextOnlySize = 18;
    @observable postImageTextSize = 13;
    @observable postComposition = 'a';
    @observable nativeStyling = new NativeStylingOptionsDefaultImpl();
    @observable gridLayout = new GridLayoutImpl();
    @observable animationOrientation: AnimationOrientation = 'horizontal';
}
