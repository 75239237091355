import { ComputedStyle, styler, computedStyleToString } from 'utils/GenerateStyles/GenerateStyles';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from '@sprinklr/stories/src/theme/Theme';
import { DynamicTitleWidgetOptions } from './options';
import DataSet from '@sprinklr/stories/analytics/DataSet';

export const dynamicTitleOptionStyles = ({ fontSize, date, color }) => {
    const computedStyles: ComputedStyle[] = [
        {
            selector: '.dynamic_title',
            styles: {
                fontSize: styler(fontSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.title_widget_sub',
            styles: {
                fontSize: styler(date.size, 0.1, 'em', ''),
            },
        },
    ];
    return computedStyles;
};

export const dynamicTitleCss: GetWidgetTypeStyles = (
    mergedTheme: Theme,
    dataSet: DataSet,
    widget: Widget,
    prefix: string
): string => {
    let css = '';

    if (!mergedTheme || !widget) {
        return css;
    }

    const { date, color } = widget.options as DynamicTitleWidgetOptions;
    const prefixConfig = { prefix };

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.widget_inner',
            styles: {
                justifyContent: 'flex-start',
            },
        },
        {
            selector: '.dynamic_title',
            styles: {
                color: color || 'inherit',
                paddingBottom: '70px',
                fontWeight: 'bold',
            },
        },
        {
            selector: '.title_widget_sub',
            styles: {
                color: date.color || 'inherit',
            },
        },
    ];

    computedStyles &&
        computedStyles.forEach((style: ComputedStyle) => {
            css += computedStyleToString(style, prefixConfig);
        });
    return css;
};
