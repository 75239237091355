import BulkItem from './BulkItem';

export default class TopicTheme extends BulkItem {
    constructor(
        id: string,
        name: string,
        private displayName: string = null,
        private imageUrl: string = null,
        private color: string = null
    ) {
        super(id, name);
    }

    toString() {
        return this.displayName ? this.displayName : this.name;
    }

    get sortValue() {
        return this.displayName;
    }

    toColor(): string {
        return this.color;
    }

    toImageUrl(): string {
        return this.imageUrl;
    }

    public static fromObject(object: any): TopicTheme {
        return new TopicTheme(
            object.id,
            object.name,
            object.displayName,
            object.imageUrl,
            object.color
        );
    }
}
