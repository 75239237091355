import BulkItem from './BulkItem';

export default class SourceItem extends BulkItem {
    constructor(id: string, name: string, public sourceType: string = null) {
        super(id, name);
    }

    public static fromObject(object: any): SourceItem {
        return new SourceItem(object.id, object.name, object.sourceType ?? '');
    }

    toString() {
        return this.name;
    }

    get sortValue() {
        return this.name;
    }
}
