import { scaleLinear } from '@vx/scale';
import { HAlign } from '@sprinklr/stories/widget/types';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { MultiMetricWidgetOptionsImpl } from './options';
import { ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';
import { rgba } from 'polished';

export const getTextAlignment = (alignment: HAlign): string => {
    switch (alignment) {
        case 'left':
            return 'ta-l';
        case 'right':
            return 'ta-r';
        case 'center':
            return 'ta-c';
        default:
            return 'ta-l';
    }
};
export const getMultiMetricCss: GetWidgetTypeStyles = (
    mergedTheme: Theme,
    dataSet,
    widget: Widget,
    cssPrefix: string
) => {
    const cellCount = dataSet?.metrics?.length;
    const { columns } = (widget.options as MultiMetricWidgetOptionsImpl).gridLayout;
    const { smartSize } = widget.options;
    const remainder = cellCount % columns;

    const getFontScale = scaleLinear({
        domain: [1, 10],
        range: [1, 0.25],
    });
    const getFontSingleColScale = scaleLinear({
        domain: [1, 10],
        range: [1, 0.5],
    });

    const adjustedColumns = Math.min(columns, cellCount);

    const calculateFontScale = () => {
        if (adjustedColumns === 1) {
            return `${getFontSingleColScale(cellCount)}em`;
        } else {
            return `${getFontScale(adjustedColumns)}em`;
        }
    };

    let output = '';
    output += `${cssPrefix} .multi_metric_secondary { grid-column-end: span ${adjustedColumns}; grid-template-columns: repeat(${remainder}, 1fr);}`;
    output += `${cssPrefix} .multi_metric_primary { grid-template-columns: repeat(${adjustedColumns}, 1fr);}`;

    if (smartSize.enabled) {
        output += `${cssPrefix} .metric_item { font-size: ${calculateFontScale()}}`;
    }

    return output;
};

export const multiMetricWidgetOptionStyles = (
    options: MultiMetricWidgetOptionsImpl,
    theme: Theme,
    inheritedTheme: Theme,
    measurements?
): ComputedStyle[] => {
    const { percentChangeColor, gridLayout, cell, smartSize } = options;
    const { columns } = gridLayout;

    const { padding, border, background } = cell;
    const computedStyles: ComputedStyle[] = [];

    const themeColor = theme?.typography?.color;
    const inheritedThemeColor = inheritedTheme?.typography?.color;

    const textColor = themeColor || inheritedThemeColor;

    computedStyles.push({
        selector: '.multi_metric',
        styles: {
            display: 'grid',
            gridGap: styler(gridLayout.gap, 0.1, 'em', ''),
            height: '100%', // fix for safari
            width: '100%',
        },
    });

    computedStyles.push({
        selector: '.metric_item',
        styles: {
            border: border.enabled
                ? `${border.size * 0.05}em solid ${border.color || rgba(textColor, 0.2)}`
                : 'initial',
            backgroundColor: background ? background : 'transparent',
            padding: `0 ${styler(padding, 0.1, 'em', '')}`,
        },
    });

    if (percentChangeColor.enabled) {
        computedStyles.push(
            {
                selector: '.metric_ratio.change_positive',
                styles: {
                    color: percentChangeColor.up,
                    opacity: 1,
                },
            },
            {
                selector: '.metric_ratio.change_negative',
                styles: {
                    color: percentChangeColor.down,
                },
            },
            {
                selector: '.metric_ratio:not(.change_negative):not(.change_positive)',
                styles: {
                    color: !!percentChangeColor.zero ? percentChangeColor.zero : textColor,
                },
            }
        );
    }

    return computedStyles;
};
