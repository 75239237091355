import * as React from 'react';
import { WIDGET_TYPES } from 'models/Widget/constants';
import { WidgetTypeId } from 'models/Widget/types';
import { WidgetContainer } from './types';

const ComboChartWidget = React.lazy(() =>
    import(
        /* webpackChunkName: "ComboChartWidget" */ '../../widgets/ComboChartWidget/ComboChartWidget'
    )
);

const PostsWindowWidget = React.lazy(() =>
    import(
        /* webpackChunkName: "PostsWindowWidget" */ '../../widgets/PostsWindowWidget/PostsWindowWidget'
    )
);

const PostsWidget = React.lazy(() =>
    import(/* webpackChunkName: "PostsWidget" */ '../../widgets/PostsWidget/PostsWidget')
);

const SmallMultiplesPieChart = React.lazy(() =>
    import(
        /* webpackChunkName: "SmallMultiplesPieChartWidget" */ '../../widgets/SmallMultiplesPieChartWidget/SmallMultiplesPieChartWidget'
    )
);

const CustomContentWidget = React.lazy(() =>
    import(
        /* webpackChunkName: "CustomContentWidget" */ '../../widgets/CustomContentWidget/CustomContentWidget'
    )
);

const TickerWidget = React.lazy(() =>
    import(/* webpackChunkName: "TickerWidget" */ '../../widgets/TickerWidget/TickerWidget')
);

const LineChartWidget = React.lazy(() =>
    import(
        /* webpackChunkName: "LineChartWidget" */ '../../widgets/LineChartWidget/LineChartWidget'
    )
);

const WIDGET_TYPE_TO_WIDGET_FACTORY: { [key in WidgetTypeId]: WidgetContainer } = {
    [WIDGET_TYPES.autoAlerts]: React.lazy(() =>
        import(
            /* webpackChunkName: "AutoAlertsWidget" */ '../../widgets/AutoAlertsWidget/AutoAlertsWidget'
        )
    ),
    [WIDGET_TYPES.axisBubbleChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "AxisBubbleChartWidget" */ '../../widgets/AxisBubbleChartWidget/AxisBubbleChartWidget'
        )
    ),
    [WIDGET_TYPES.customTable]: React.lazy(() =>
        import(
            /* webpackChunkName: "CustomTableChartWidget" */ 'components/_charts/CustomTableChartWidget/CustomTableChartWidget'
        )
    ),
    [WIDGET_TYPES.comboChart]: ComboChartWidget,
    [WIDGET_TYPES.dualAxis]: ComboChartWidget,
    [WIDGET_TYPES.shape]: React.lazy(() =>
        import(/* webpackChunkName: "ShapeWidget" */ '../../widgets/ShapeWidget/ShapeWidget')
    ),
    [WIDGET_TYPES.metricRollup]: React.lazy(() =>
        import(
            /* webpackChunkName: "MetricRollupWidget" */ '../../widgets/MetricRollupWidget/MetricRollupWidget'
        )
    ),
    [WIDGET_TYPES.autoAlerts]: React.lazy(() =>
        import(
            /* webpackChunkName: "AutoAlertsWidget" */ '../../widgets/AutoAlertsWidget/AutoAlertsWidget'
        )
    ),
    [WIDGET_TYPES.funnelChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "FunnelChartWidget" */ '../../widgets/FunnelChartWidget/FunnelChartWidget'
        )
    ),
    [WIDGET_TYPES.dynamicImage]: React.lazy(() =>
        import(
            /* webpackChunkName: "DynamicImageWidget" */ '../../widgets/DynamicImageWidget/DynamicImageWidget'
        )
    ),
    [WIDGET_TYPES.sunburst]: React.lazy(() =>
        import(
            /* webpackChunkName: "SunburstWidget" */ '../../widgets/SunburstWidget/SunburstWidget'
        )
    ),
    [WIDGET_TYPES.postsWaterfall]: React.lazy(() =>
        import(
            /* webpackChunkName: "WaterfallWidget" */ '../../widgets/WaterfallWidget/WaterfallWidget'
        )
    ),
    [WIDGET_TYPES.postsTicker]: TickerWidget,
    [WIDGET_TYPES.customContentImage]: CustomContentWidget,
    [WIDGET_TYPES.customContentRichText]: CustomContentWidget,
    [WIDGET_TYPES.customContentIframe]: CustomContentWidget,
    [WIDGET_TYPES.customContentVideo]: CustomContentWidget,
    [WIDGET_TYPES.customContentSocialPost]: CustomContentWidget,
    [WIDGET_TYPES.customTableRichText]: CustomContentWidget,
    [WIDGET_TYPES.heatMapTableChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HeatMapTableChartWidget" */ '../../widgets/HeatMapTableChartWidget/HeatMapTableChartWidget'
        )
    ),
    [WIDGET_TYPES.worldHeatMap]: React.lazy(() =>
        import(
            /* webpackChunkName: "WorldHeatMapWidget" */ '../../widgets/WorldHeatMapWidget/WorldHeatMapWidget'
        )
    ),
    [WIDGET_TYPES.geoStream]: React.lazy(() =>
        import(
            /* webpackChunkName: "GeoStreamWidget" */ '../../widgets/GeoStreamWidget/GeoStreamWidget'
        )
    ),
    [WIDGET_TYPES.treeMap]: React.lazy(() =>
        import(/* webpackChunkName: "TreeMapWidget" */ '../../widgets/TreeMapWidget/TreeMapWidget')
    ),
    [WIDGET_TYPES.profile]: React.lazy(() =>
        import(/* webpackChunkName: "ProfileWidget" */ '../../widgets/ProfileWidget/ProfileWidget')
    ),
    [WIDGET_TYPES.dataTablePivot]: React.lazy(() =>
        import(
            /* webpackChunkName: "DataTableWidget" */ '../../widgets/DataTableWidget/DataTableWidget'
        )
    ),
    [WIDGET_TYPES.dataTableCrossTab]: React.lazy(() =>
        import(
            /* webpackChunkName: "DataTableWidget" */ '../../widgets/DataTableWidget/DataTableWidget'
        )
    ),
    [WIDGET_TYPES.dataTableSummary]: React.lazy(() =>
        import(
            /* webpackChunkName: "DataTableWidget" */ '../../widgets/DataTableWidget/DataTableWidget'
        )
    ),
    [WIDGET_TYPES.dataTableGroupedSummary]: React.lazy(() =>
        import(
            /* webpackChunkName: "DataTableWidget" */ '../../widgets/DataTableWidget/DataTableWidget'
        )
    ),
    [WIDGET_TYPES.rankedTable]: React.lazy(() =>
        import(
            /* webpackChunkName: "RankedTableWidget" */ '../../widgets/RankedTableWidget/RankedTableWidget'
        )
    ),
    [WIDGET_TYPES.rankedList]: React.lazy(() =>
        import(
            /* webpackChunkName: "RankedListWidget" */ '../../widgets/RankedListWidget/RankedListWidget'
        )
    ),
    [WIDGET_TYPES.multiMetric]: React.lazy(() =>
        import(
            /* webpackChunkName: "MultiMetricWidget" */ '../../widgets/MultiMetricWidget/MultiMetricWidget'
        )
    ),
    [WIDGET_TYPES.metric]: React.lazy(() =>
        import(/* webpackChunkName: "MetricWidget" */ '../../widgets/MetricWidget/MetricWidget')
    ),
    [WIDGET_TYPES.bubbleChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "BubbleChartWidget" */ '../../widgets/BubbleChartWidget/BubbleChartWidget'
        )
    ),
    [WIDGET_TYPES.wordCloud]: React.lazy(() =>
        import(
            /* webpackChunkName: "WordCloudWidget" */ '../../widgets/WordCloudWidget/WordCloudWidget'
        )
    ),
    [WIDGET_TYPES.lineChart]: LineChartWidget,
    [WIDGET_TYPES.barChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "BarChartWidget" */ '../../widgets/BarChartWidget/BarChartWidget'
        )
    ),
    [WIDGET_TYPES.postsFilmStrip]: PostsWindowWidget,
    [WIDGET_TYPES.postsCinematic]: PostsWindowWidget,
    [WIDGET_TYPES.postsTypePoster]: PostsWindowWidget,
    [WIDGET_TYPES.postsSinglePost]: PostsWindowWidget,
    [WIDGET_TYPES.posts]: PostsWidget,
    [WIDGET_TYPES.postsCollage]: PostsWidget,
    [WIDGET_TYPES.postsGrid]: PostsWidget,
    [WIDGET_TYPES.postsGridV2]: PostsWidget,
    [WIDGET_TYPES.postCard]: PostsWidget,
    [WIDGET_TYPES.storyCard]: PostsWidget,
    [WIDGET_TYPES.runOfShow]: PostsWidget,
    [WIDGET_TYPES.runOfShow]: PostsWidget,
    [WIDGET_TYPES.stackedBarChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "StackedBarChartWidget" */ '../../widgets/StackedBarChartWidget/StackedBarChartWidget'
        )
    ),
    [WIDGET_TYPES.pieChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "PieChartWidget" */ '../../widgets/PieChartWidget/PieChartWidget'
        )
    ),
    [WIDGET_TYPES.smallMultiplesPieChart]: SmallMultiplesPieChart,
    [WIDGET_TYPES.smartCluster]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceSmartCluster" */ '../../widgets/hyperSpace/hyperspaceSmartClusterWidget/HyperspaceSmartClusterWidget'
        )
    ),
    [WIDGET_TYPES.bubblePlotChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "BubblePlotChartWidget" */ '../../widgets/BubblePlotChartWidget/BubblePlotChartWidget'
        )
    ),
    [WIDGET_TYPES.metricValue]: React.lazy(() =>
        import(
            /* webpackChunkName: "MetricValueWidget" */ '../../widgets/MetricValueWidget/MetricValueWidget'
        )
    ),
    [WIDGET_TYPES.metricValueLockup]: React.lazy(() =>
        import(
            /* webpackChunkName: "MetricValueLockupWidget" */ '../../widgets/MetricValueLockupWidget/MetricValueLockupWidget'
        )
    ),
    [WIDGET_TYPES.twitterTrends]: React.lazy(() =>
        import(
            /* webpackChunkName: "TwitterTrendsWidget" */ '../../widgets/TwitterTrendsWidget/TwitterTrendsWidget'
        )
    ),
    [WIDGET_TYPES.googleTrends]: React.lazy(() =>
        import(
            /* webpackChunkName: "GoogleTrendsWidget" */ '../../widgets/GoogleTrendsWidget/GoogleTrendsWidget'
        )
    ),
    [WIDGET_TYPES.countUp]: React.lazy(() =>
        import(/* webpackChunkName: "CountUpWidget" */ '../../widgets/CountUpWidget/CountUpWidget')
    ),
    [WIDGET_TYPES.legend]: React.lazy(() =>
        import(/* webpackChunkName: "LegendWidget" */ '../../widgets/LegendWidget/LegendWidget')
    ),
    [WIDGET_TYPES.hyperlinkButton]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperlinkButtonWidget" */ '../../widgets/HyperlinkButtonWidget/HyperlinkButtonWidget'
        )
    ),
    [WIDGET_TYPES.speechBubble]: React.lazy(() =>
        import(
            /* webpackChunkName: "SpeechBubbleWidget" */ '../../widgets/SpeechBubbleWidget/SpeechBubbleWidget'
        )
    ),
    [WIDGET_TYPES.dynamicTitle]: React.lazy(() =>
        import(
            /* webpackChunkName: "DynamicTitleWidget" */ '../../widgets/DynamicTitleWidget/DynamicTitleWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceColumnChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceColumnChartWidget" */ '../../widgets/hyperSpace/HyperSpaceColumnChartWidget/HyperSpaceColumnChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceBarChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceBarChartWidget" */ '../../widgets/hyperSpace/HyperSpaceBarChartWidget/HyperSpaceBarChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceStackedBarChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceStackedBarChartWidget" */ '../../widgets/hyperSpace/HyperSpaceStackedBarChartWidget/HyperSpaceStackedBarChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceStackedColumnChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceStackedColumnChartWidget" */ '../../widgets/hyperSpace/HyperSpaceStackedColumnChartWidget/HyperSpaceStackedColumnChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceLineChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceLineChartWidget" */ '../../widgets/hyperSpace/HyperSpaceLineChartWidget/HyperSpaceLineChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceAreaChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceAreaChartWidget" */ '../../widgets/hyperSpace/HyperSpaceAreaChartWidget/HyperSpaceAreaChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceDualAxisChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceDualAxisChartWidget" */ '../../widgets/hyperSpace/HyperSpaceDualAxisChartWidget/HyperSpaceDualAxisChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceComboChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceComboChartWidget" */ '../../widgets/hyperSpace/HyperSpaceComboChartWidget/HyperSpaceComboChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceBubblePlotChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceBubblePlotChartWidget" */ '../../widgets/hyperSpace/HyperSpaceBubblePlotChartWidget/HyperSpaceBubblePlotChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceAxisBubbleChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceAxisBubbleChartWidget" */ '../../widgets/hyperSpace/HyperSpaceAxisBubbleChartWidget/HyperSpaceAxisBubbleChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpacePieChart]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpacePieChartWidget" */ '../../widgets/hyperSpace/HyperSpacePieChartWidget/HyperSpacePieChartWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceTreeMap]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceTreeMapWidget" */ '../../widgets/hyperSpace/HyperSpaceTreeMapWidget/HyperSpaceTreeMapWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceAlerts]: React.lazy(() =>
        import(
            /* webpackChunkName: "HyperSpaceAlertsWidget" */ '../../widgets/hyperSpace/HyperSpaceAlertsWidget/HyperSpaceAlertsWidget'
        )
    ),
    [WIDGET_TYPES.hyperSpaceQuadrantMatrix]: React.lazy(() =>
        import(
            /* webpackChunkName: "hyperSpaceQuadrantMatrixWidget" */ '../../widgets/hyperSpace/HyperSpaceQuadrantMatrixWidget/HyperSpaceQuadrantMatrixWidget'
        )
    ),
    [WIDGET_TYPES.sparkline]: LineChartWidget,
    [WIDGET_TYPES.geoStreamTicker]: TickerWidget,
    [WIDGET_TYPES.smartTrends]: TickerWidget,
    // [WIDGET_TYPES.hyperSpaceWordCloud]: React.lazy(() => import(
    //     /* webpackChunkName: "hyperSpaceWordCloud" */ '../../widgets/hyperSpace/HyperSpaceWordCloud/HyperSpaceWordCloud'
    // ) ),
};

export function getWidget(widgetType?: WidgetTypeId): WidgetContainer {
    // console.log('widgetType', widgetType);

    return widgetType ? WIDGET_TYPE_TO_WIDGET_FACTORY[widgetType] : null;
}
