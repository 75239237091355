import { observable } from 'mobx';
import merge from 'lodash/merge';
import { LegendOptions, MetaInfoOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import {
    DataLabelsOptionsImpl,
    LegendOptionsImpl,
    MetaInfoOptionsImpl,
    XAxisImpl,
    YAxisImpl,
} from '../options';

import { HyperSpaceDualAxisChartWidgetOptions } from './types';

export class HyperSpaceDualAxisChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceDualAxisChartWidgetOptions {
    @observable baseXYChartOptions: HyperSpaceDualAxisChartWidgetOptions['baseXYChartOptions'] = {
        grid: {
            enabled: false,
            color: '',
            size: 1,
        },
        yAxes: [
            {
                type: 'line',
            },
            {
                type: 'column',
            },
        ],
        xAxis: new XAxisImpl(),
        yAxis: new YAxisImpl(),
        dataLabels: new DataLabelsOptionsImpl(),
    };
    @observable zeroFill = true;
    @observable legend: LegendOptions = merge(new LegendOptionsImpl(), {
        glyph: { shape: undefined }, // disable glyph shape
    });
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
}
