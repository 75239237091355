import { FontCase, FontStyle, FontWeight, Theme } from '../theme/Theme';

// TODO: these @sprinklr/display-builder imports need to go away
import { TrendRequest } from '@sprinklr/display-builder/services/TrendService/types';
import { DataSourceName } from '@sprinklr/display-builder/models/DataSource/DataSource';

import { PanelOrientation } from './types';
import { AnalyticsRequest } from '../analytics/AnalyticsRequest';
import { AnalyticsResultRaw } from '../analytics/AnalyticsResultRaw';
import { ProfileRequest } from '../profile/ProfileRequest';
import { PostsRequest } from '../post/PostsRequest';
import { PostsFormatRequest } from '../post/PostsFormatRequest';
import { PostProfile } from '../post/Post';

export type WidgetDataRequest =
    | AnalyticsRequest
    | ProfileRequest
    | PostsRequest
    | PostsFormatRequest
    | TrendRequest;

export type TextShadowOptions = {
    enabled: boolean;
    color: string;
    x: number;
    y: number;
    blur: number;
};

export type MergedLegacyTheme = Theme & ThemeInterface;

export type ThemeInterface = {
    colorPrimary?: string;
    colorSecondary?: string;
    colorBackground?: string;
    colorNegative?: string;
    colorPostive?: string;
    colorFont?: string;
    fontFamilyPrimary?: string;
    fontFamilySecondary?: string;
    fontSizePrimary?: string;
    fontSizeSecondary?: string;
    fontPrimary?: WidgetFont;
    fontSecondary?: WidgetFont;
    backgroundImage?: BackgroundImage;
    ext?: Ext;
};

type Margin = {
    enabled: boolean;
    top: number;
    right: number;
    bottom: number;
    left: number;
};

export type Ext = {
    padding?: number;
    colorGrid?: string;
    showGuides?: boolean;
    showGridLayout?: boolean;
    snapToGrid?: boolean;
    gutter?: number;
    margin?: Margin;
    grid?: {
        columns: number;
        rows: number;
        gap: number;
    };
};

export type WidgetPositionOrientation = {
    left?: number | string;
    top?: number | string;
    width?: number | string;
    height?: number | string;
};

export type WidgetFont = {
    samId?: string; // id from Sprinklr Core SAM
    name?: string;
    description?: string;
    family?: string;
    subFamily?: string;
    weight?: FontWeight;
    style?: FontStyle;
    url?: string;
    useFamily?: boolean;
    textTransform?: FontCase;
};

export type BackgroundImage = {
    samId?: string; // id from Sprinklr Core SAM
    name?: string;
    description?: string;
    previewUrl?: string;
    url: string;
    backgroundSize?: string;
    backgroundRepeat?: boolean;
};
export type WidgetPositionGrid = {
    enabled: boolean;
    columns: {
        start: number;
        end: number;
    };
    rows: {
        start: number;
        end: number;
    };
};

export type WidgetPosition = {
    left?: number | string;
    right?: number | string;
    top?: number | string;
    bottom?: number | string;
    width?: number | string;
    height?: number | string;
    portrait?: Omit<WidgetPosition, 'portrait' | 'landscape'>;
    landscape?: Omit<WidgetPosition, 'portrait' | 'landscape'>;
    grid?: WidgetPositionGrid;
    // TODO: These are for backward compatibility.  Need to be chucked.
    backgroundColor?: string;
    display?: string;
    maxWidth?: string;
    maxHeight?: string;
};

export const positionDefaults: WidgetPosition = {
    top: null,
    left: null,
    height: null,
    width: null,
};

export type WidgetLabelInterface = {
    authService?: any;
    enabled?: boolean;
    size?: number;
    titleText?: string;
    subTitleText?: string;
    classes?: string;
    overlap?: boolean;
    isEditable?: boolean;
    dateRange?: string[];
    dateOptions?: {
        labelTimeRangeFormat?: string;
        labelTimeRangeEnabled?: boolean;
        labelTimeZone?: string;
        date?: {
            enabled: boolean;
        };
    };
    setNewLabelText?: () => void;
    textShadow?: TextShadowOptions;
};

export type Widget<OptionsType = any, TypeId = any> = {
    id?: any;
    name?: string;
    type?: TypeId;
    classes?: string;
    thumbSrc?: string;
    theme?: MergedLegacyTheme;

    // Note: This is used by the widget renderer to layout widgets.  Also includes width and height.
    position?: WidgetPosition;
    label?: WidgetLabelInterface;

    css?: string;
    js?: string;

    panelOrientation?: PanelOrientation;
    widgetOrientation?: PanelOrientation;

    postRequests?: PostsFormatRequest[];
    postRequest?: PostsRequest;
    analyticsRequests?: AnalyticsRequest[];
    analyticsResults?: AnalyticsResultRaw[];
    profileRequests?: ProfileRequest[];
    profileResults?: PostProfile[][];
    trendRequests?: TrendRequest[];

    setNumAvailablePosts?: any;

    // TODO: implement these?
    // postsRequests?: PostsRequest[];
    // sourceFormat?: SourceFormat;

    useGlobalTimePeriod?: boolean;
    useGlobalPreviousTimePeriod?: boolean;
    useGlobalDataSources?: boolean;
    useGlobalTranslate?: boolean;
    translationLanguage?: string;
    globalDataSourceName?: DataSourceName;

    children?: Widget[];

    options?: OptionsType;

    clientId?: number;

    userAssetId?: string;
    userAssetContentUrl?: string;
};
