import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';

export interface MetricValueWidgetOptions extends WidgetOptions {
    data?: number;
    prettyNumber?: boolean;
    duration?: number;
    useEasing?: boolean;
    decimals?: number;
    countUp?: boolean;
    ratio?: boolean;
    flagNegatative?: boolean;
    showIndicator?: boolean;
    metricName?: string;
    verticalStack?: boolean;
    classes?: string;
    metricClasses?: string;
}

export class MetricValueWidgetOptionsImpl extends WidgetOptionsImpl
    implements MetricValueWidgetOptions {
    data?: number;
    prettyNumber?: boolean;
    duration?: number;
    useEasing?: boolean;
    decimals?: number;
    countUp?: boolean;
    ratio?: boolean;
    flagNegatative?: boolean;
    showIndicator?: boolean;
    metricName?: string;
    verticalStack?: boolean;
    classes?: string;
    metricClasses?: string;
}
