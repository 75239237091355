import DataSet from '@sprinklr/stories/analytics/DataSet';
import { FieldType } from '@sprinklr/stories/reporting/types';
import { normalizeLabel } from 'utils/NormalizeLabel/NormalizeLabel';
import d3 from 'd3';
import classNames from 'classnames';
import {
    ComputedStyle,
    computedStylesArrayToString,
    styler,
} from 'utils/GenerateStyles/GenerateStyles';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Measurements } from 'components/Widget/WidgetComponent/types';
import { GenerateTransitionStyles } from 'utils/GenerateTransitionStyles/GenerateTransitionStyles';

export const transformData = (
    currentDataSet: DataSet,
    options?,
    measurements?,
    previousDataSet?: DataSet
) => {
    const width = (measurements && measurements.width) || 1;
    const height = (measurements && measurements.height) || 1;
    const firstMetric = currentDataSet.getFirstMetric();
    const firstMetricIndex = currentDataSet.getMetricIndex(firstMetric);
    const firstDimension = currentDataSet.getFirstDimension();
    const firstDimensionIndex = currentDataSet.getDimensionIndex(firstDimension);

    const type = (): FieldType => {
        return currentDataSet.metrics[currentDataSet.metrics?.length < 2 ? 0 : firstMetricIndex]
            .type;
    };

    const blacklist = options?.blacklist || [];

    const widgetData = () => {
        return currentDataSet
            .groupBy(firstDimension)
            .filter(row => blacklist.indexOf(row.value) === -1)
            .map(item => {
                const total =
                    currentDataSet.dimensions.length > 1
                        ? item.data.rows.reduce((rowsTotal, row) => row[1] + rowsTotal, 0)
                        : item.data.rows[0][firstDimensionIndex];
                const label = item.value || '';

                const percentChangeIndex = item.data.getPercentChangeIndex(firstMetric.name);
                const percentChange = item.data.totals?.[percentChangeIndex];

                return {
                    name: normalizeLabel(label),
                    value: total,
                    type: type(),
                    metricLabel: firstMetric.name,
                    percentChange,
                    children:
                        item.data.rows.length > 1
                            ? item.data.rows
                                  .filter((row: any) => blacklist.indexOf(row.value) === -1)
                                  .map((row: any, index) => {
                                      return {
                                          name: row[0],
                                          value: row[1],
                                          type,
                                          metricLabel: normalizeLabel(item.data.metrics[0].name),
                                          percentChange: 0,
                                      };
                                  })
                            : null,
                };
            });
    };

    // using the min of the width and height values allows us to vertically or hoizontally center the chart as needed
    const diameter = Math.min(height, width);
    const radius = diameter / 2;

    const tempData = widgetData();

    // Limit the length of the data array
    const data = tempData.slice(0, options?.limit);

    // Sort array by value
    // data = data.sort((a, b) => b.value - a.value);

    // Create the d3 pack layout
    const bubblePackLayout = d3.layout
        .pack()
        .sort(null)
        .size([diameter, diameter])
        .padding(options?.padding);

    // Format the nodes data in a way that the d3 pack layout expects
    const nodes = bubblePackLayout.nodes({ children: data }).filter((d: any) => true);

    // Build parents
    const parentCheck = (d: any) => d.depth < 2;
    const parentNodes = nodes.filter(parentCheck);

    // Class Hooks
    const bubbleChartClasses = classNames('bubble_chart component flex middle center', {});
    const bubbleChartInnerClasses = classNames(
        `bubble_chart_inner rel total_bubbles_${data.length}`,
        {
            total_bubbles_over_10: data.length > 10,
        }
    );

    const items = currentDataSet.groupBy(currentDataSet.dimensions[firstDimensionIndex]);

    // Child Length
    const getChildLength = item => {
        const val =
            item && item.data && item.data.rows && item.data.rows.length
                ? item.data.rows.length
                : 0;
        return val;
    };
    const compareChildrenLength = (a, b) => (getChildLength(a) > getChildLength(b) ? a : b);

    const longestChild = items.reduce(compareChildrenLength, 0);
    const childItems: number = getChildLength(longestChild);

    return {
        parentNodes,
        bubbleChartClasses,
        bubbleChartInnerClasses,
        diameter,
        radius,
        childItems,
    };
};

export const generateChildBlend = (prefix: string, childItems: number, options) => {
    let css = '';

    const { childContrast: contrast, childVibrance: vibrance } = options;

    // Get Greyscale Color
    const vibranceCoEfficient = 2.55;
    const vibranceValue = Math.round(vibranceCoEfficient * vibrance); // rgba needs integers
    const vibranceString = `${vibranceValue}, ${vibranceValue}, ${vibranceValue}`;

    // Get Contrast/Saturation value
    const childLengthAdjusted = childItems - 1;
    const contrastLowerBound = 50 - contrast / 2;
    const contrastCoEfficient = contrast / childLengthAdjusted;
    const getContrastValue = childIndex =>
        (contrastLowerBound + contrastCoEfficient * (childLengthAdjusted - childIndex)) / 100;

    for (let i = 0; i < childItems; i++) {
        let alpha = getContrastValue(i);
        if (isNaN(alpha)) {
            alpha = 1;
        }
        css += `${prefix} .bubble_index_${i +
            1}.child .primary_background.bubble_inner { background-color: rgba(${vibranceString}, ${alpha}) }`;
    }

    return css;
};

export const bubbleChartOptionStyles = options => {
    const {
        offsetX,
        offsetY,
        zoom,
        childLabelSize,
        childMetricValueSize,
        childMetricNameSize,
        childOpacity,
        childVibrance,
        childContrast,
    } = options;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.bubble_chart_inner',
            styles: {
                transform: `translate(${offsetX}%, ${offsetY}%) scale(${zoom * 0.02})`,
            },
        },
        {
            selector: '.child .label_size',
            styles: {
                fontSize: styler(childLabelSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.child .metric_value_inner',
            styles: {
                fontSize: styler(childMetricValueSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.child .metric_name_inner',
            styles: {
                fontSize: styler(childMetricNameSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.child',
            styles: {
                opacity: styler(childOpacity, 0.01, '', ''),
            },
        },
    ];

    return computedStyles;
};

export const BubbleChartCss: GetWidgetTypeStyles = (
    mergedTheme: Theme,
    dataSet: DataSet,
    widget: Widget,
    cssPrefix: null,
    measurements?: Measurements
): string => {
    let output = '';
    if (!mergedTheme || !dataSet || !widget || dataSet.dimensions.length === 0) {
        return output;
    }
    const {
        animationDelay,
        animationLength,
        metricValueSize,
        highlightPercentChange,
        metricPercentChangeSize,
    } = widget.options as any;
    const { parentNodes, childItems } = transformData(
        dataSet.filterRowsData(),
        widget.options,
        measurements
    );

    const prefix = { prefix: cssPrefix };

    output += generateChildBlend(prefix.prefix, childItems, widget.options);
    output += GenerateTransitionStyles(
        '.bubble',
        '.bubble',
        parentNodes.length,
        animationLength,
        animationDelay,
        cssPrefix
    );

    const styles: ComputedStyle[] = [];

    // Sentiment
    parentNodes.map((node: any, index) => {
        // calc the ratio of the bubble to the total using diameter)
        const ratio: number = Math.max(node.r / 1000, 0.01);
        const multiplier: number = 0.08 * ratio;

        styles.push({
            selector: `.bubble_index_${index}`,
            styles: {
                fontSize: styler(
                    highlightPercentChange ? metricPercentChangeSize : metricValueSize,
                    multiplier,
                    'em',
                    ''
                ),
            },
        });
    });

    // needs to not break current sizes
    // maybe another JSON option?
    output += computedStylesArrayToString(styles, prefix);

    return output;
};
