import { observable } from 'mobx';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { BaseXYAxisChartOptionsImpl, LegendOptionsImpl, MetaInfoOptionsImpl } from '../options';

// types
import { BaseXYAxisChartOptions, LegendOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import { HyperSpaceStackedColumnChartWidgetOptions } from './types';
import { StackType } from '../types';

export class HyperSpaceStackedColumnChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceStackedColumnChartWidgetOptions {
    @observable barHeight = 90;
    @observable zeroFill = true;
    @observable
    baseXYChartOptions: BaseXYAxisChartOptions = new BaseXYAxisChartOptionsImpl();
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
    @observable legend: LegendOptions = new LegendOptionsImpl();
    @observable columnWidth = 90;
    @observable borderRadius = 4;
    @observable stackType: StackType = 'stacked';
}
