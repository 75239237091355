import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { observable } from 'mobx';
import { FontCase } from 'models/Theme/Theme';

export interface MetricValueLockupWidgetOptions extends WidgetOptions {
    label: string; // kill these
    subLabel: string; // kill these

    // props
    verticalStack?: boolean;
    ratioValue?: number;
    metricValue?: number;
    metricName?: string;
    icon?: string;
    iconWrap?: boolean;
    secondaryMetricValue?: number;
    secondaryMetricName?: string;
    secondaryIcon?: string;
    ratioIndicator?: boolean;
    classes?: string;
    primaryClasses?: string;
    highlightPercentChange?: boolean;
}

export class MetricValueLockupWidgetOptionsImpl extends WidgetOptionsImpl
    implements MetricValueLockupWidgetOptions {
    label: string; // kill these
    subLabel: string; // kill these

    // props
    verticalStack?: boolean;
    ratioValue?: number;
    metricValue?: number;
    metricName?: string;
    icon?: string;
    iconWrap?: boolean;
    secondaryMetricValue?: number;
    secondaryMetricName?: string;
    secondaryIcon?: string;
    ratioIndicator?: boolean;
    classes?: string;
    primaryClasses?: string;
    highlightPercentChange?: boolean;
    @observable metricNameCase: FontCase = 'lowercase';
}
