import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { HAlign, VAlign } from '@sprinklr/stories/widget/types';
import { observable } from 'mobx';

export type CustomContentWidgetOptions = WidgetOptions;

export class CustomContentWidgetOptionsImpl extends WidgetOptionsImpl
    implements CustomContentWidgetOptions {
    @observable contentText = 'click to edit';
    @observable contentPadding = 20;
    @observable zoom = 50;
    @observable offsetX = 0;
    @observable offsetY = 0;
    @observable zoomClipping = false;
    @observable contentBackgroundColor = '';
    @observable contentSocialPostUrl = '';
    @observable contentSocialPostUrls: string[] = [];
    @observable contentImageUrl = '';
    @observable contentImageFit: 'cover' | 'contain' = 'contain';
    @observable contentImageOptimized = true;
    @observable contentVideoUrl = '';
    @observable contentIframeUrl = '';
    @observable contentTextAlign: HAlign = 'left';
    @observable verticalAlign: VAlign = 'middle';
    @observable horizontalAlign: HAlign = 'center';
    @observable imageFilterBlur = false;
    @observable imageFilterBrightness = false;
    @observable imageFilterContrast = false;
    @observable imageFilterGrayscale = false;
    @observable imageFilterSaturate = false;
    @observable imageFilterHueRotate = false;
    @observable imageFilterOpacity = false;
    @observable imageFilterInvert = false;
    @observable imageFilterSepia = false;
    @observable imageFilterBlurValue = 0;
    @observable imageFilterBrightnessValue = 100;
    @observable imageFilterContrastValue = 100;
    @observable imageFilterGrayscaleValue = 0;
    @observable imageFilterSaturateValue = 100;
    @observable imageFilterHueRotateValue = 0;
    @observable imageFilterOpacityValue = 100;
    @observable imageFilterInvertValue = 0;
    @observable imageFilterSepiaValue = 0;
    @observable imageBlendMode = '';
    @observable imageSecondaryBlendMode = '';
    @observable imageBlendColor: string = null;
    @observable imageSecondaryBlendColor = '';
    @observable presetImageFilters = '';
}

export class CustomContentRichTextOptionsImpl extends CustomContentWidgetOptionsImpl
    implements CustomContentWidgetOptions {
    @observable contentType = 'richText';
    @observable border = {
        enabled: false,
        color: '',
        size: 0,
    };
    @observable styleMap = {
        CUSTOM_FONT_SIZE_20: {
            fontSize: '2em',
        },
        CUSTOM_FONT_SIZE_18: {
            fontSize: '1.8em',
        },
        CUSTOM_FONT_SIZE_46: {
            fontSize: '4.6000000000000005em',
        },
        CUSTOM_FONT_SIZE_24: {
            fontSize: '2.4000000000000004em',
        },
        CUSTOM_FONT_SIZE_40: {
            fontSize: '4em',
        },
        CUSTOM_FONT_SIZE_60: {
            fontSize: '6em',
        },
    };
    @observable bulletColor = '';
    // setting inheritFontCase to true for backward compatibility
    @observable inheritFontCase = true;
}
export class CustomContentImageOptionsImpl extends CustomContentWidgetOptionsImpl
    implements CustomContentWidgetOptions {
    @observable contentType = 'image';
    @observable contentImageUrl = '/public/assets/flower.jpg';
    @observable contentPadding = 0;
}
export class CustomContentVideoOptionsImpl extends CustomContentWidgetOptionsImpl
    implements CustomContentWidgetOptions {
    @observable contentType = 'video';
    @observable contentImageFit: 'cover' | 'contain' = 'cover';
    @observable contentPadding = 0;
    @observable contentVideoLoop = true;
}
export class CustomContentIframeOptionsImpl extends CustomContentWidgetOptionsImpl
    implements CustomContentWidgetOptions {
    @observable contentType = 'iframe';
    @observable contentPadding = 0;
}
export class CustomContentSocialPostOptionsImpl extends CustomContentWidgetOptionsImpl
    implements CustomContentWidgetOptions {
    @observable contentType = 'socialPost';
    @observable contentPadding = 20;
}
