import { ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';

import { Theme } from 'models/Theme/Theme';
import { StoryOptions } from './types';

type GetStoryCardComputedStylesProps = {
    storyOptions: StoryOptions;
    mergedTheme: Theme;
};

const GetStoryCardComputedStyles = ({
    storyOptions,
    mergedTheme,
}: GetStoryCardComputedStylesProps): ComputedStyle[] => {
    const { summary, metrics, publications, divider, title, meta, space } = storyOptions || {};
    const fallBackColor = mergedTheme?.typography?.color || '#fff';
    const computedStyles: ComputedStyle[] = [];

    if (summary?.enabled) {
        computedStyles.push({
            selector: '.story_summary_item',
            styles: {
                fontSize: styler(summary.size, 0.025, 'em', ''),
                color: summary.color || fallBackColor,
            },
        });
    }

    if (metrics?.enabled) {
        computedStyles.push({
            selector: '.story_metric',
            styles: {
                fontSize: styler(metrics.size, 0.025, 'em', ''),
                color: metrics.color || fallBackColor,
            },
        });
    }
    if (publications?.enabled) {
        computedStyles.push({
            selector: '.story_publications',
            styles: {
                fontSize: styler(publications.size, 0.025, 'em', ''),
                color: publications.color || fallBackColor,
            },
        });
    }
    if (meta?.enabled) {
        computedStyles.push({
            selector: '.story_meta',
            styles: {
                fontSize: styler(meta.size, 0.025, 'em', ''),
                color: meta.color || '#fff',
            },
        });
    }
    if (title?.enabled) {
        computedStyles.push({
            selector: '.story_title_group .post_text',
            styles: {
                fontSize: styler(title.size, 0.025, 'em', ''),
                color: title.color || '#fff',
            },
        });
    }

    if (divider?.enabled) {
        computedStyles.push({
            selector: '.story_metrics',
            styles: {
                borderTop: `${divider.size * 0.1}px solid ${divider.color || fallBackColor}`,
            },
        });
        computedStyles.push({
            selector: '.story_metrics',
            styles: {
                paddingTop: styler(space || 0, 0.025, 'em', ''),
            },
        });
    }

    if (space) {
        computedStyles.push({
            selector: '.story_title_group',
            styles: {
                padding: styler(space, 0.025, 'em', ''),
            },
        });
        computedStyles.push({
            selector: '.summary_group',
            styles: {
                padding: styler(space, 0.025, 'em', ''),
            },
        });
        computedStyles.push({
            selector: '.story_metrics',
            styles: {
                marginTop: styler(space, 0.025, 'em', ''),
            },
        });
    }

    return computedStyles;
};

export { GetStoryCardComputedStyles };
