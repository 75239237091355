import {
    DonutWidth,
    InnerRadius,
    LegendOptions,
    MetricGroupOptions,
} from '@sprinklr/stories/hyperspaceWidgets/types';
import _merge from 'lodash/merge';
import { observable } from 'mobx';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { DataLabelsOptionsImpl, LegendOptionsImpl, MetaInfoOptionsImpl } from '../options';

import { HyperSpacePieChartWidgetOptions } from './types';

export class HyperSpacePieChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpacePieChartWidgetOptions {
    @observable legend: LegendOptions = _merge(new LegendOptionsImpl(), {
        position: {
            selectedPosition: 'right',
        },
        maxWidth: 400,
    });

    @observable innerRadius: InnerRadius = {
        enabled: false,
        size: null,
    };
    @observable donutWidth: DonutWidth = {
        enabled: false,
        size: null,
    };
    @observable dataLabels = _merge(new DataLabelsOptionsImpl(), {
        formats: {
            numberFormat: {
                percentage: true,
            },
        },
    });
    @observable metricGroupOptions: MetricGroupOptions = {
        enabled: true,
        metric: {
            size: 50,
            enabled: true,
            format: {
                decimals: 2,
                useShortened: true,
            },
        },
        metricName: {
            size: 25,
            enabled: true,
        },
        percentChange: {
            size: 20,
            enabled: false,
        },
    };
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
    @observable maxItems = 6;
    @observable showOtherItems = true;
}
