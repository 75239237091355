import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { RichImageOptions, RichImageProps } from './types';
import { observable } from 'mobx';

export interface DynamicImageWidgetOptions extends WidgetOptions {
    richImage?: RichImageOptions;
    rank: number;
}

export class DynamicImageWidgetOptionsImpl extends WidgetOptionsImpl implements RichImageProps {
    @observable richImage: RichImageOptions = {
        image: {
            enabled: true,
            opacity: 100,
            size: 'cover',
            repeat: 'no-repeat',
            position: 'center center',
            padding: 10,
        },
        fill: {
            enabled: true,
            opacity: 100,
        },
        border: {
            enabled: false,
            size: 20,
        },
        radius: 0,
    };
    @observable rank = 1;
}
