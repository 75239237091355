import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { observable } from 'mobx';
import { AnimationValues, SmartSize } from '@sprinklr/stories/widget/types';
import { ColorPaletteDirection, SunburstOptions } from './types';

export interface SunburstWidgetOptions extends WidgetOptions {
    data?: any; // TODO type this
    sunburst?: SunburstOptions;
}

export type colorPaletteDirectionOption = {
    label: string;
    value: ColorPaletteDirection;
};

export const colorPaletteDirectionOptions: colorPaletteDirectionOption[] = [
    {
        label: 'Ascending',
        value: 'ascending',
    },
    {
        label: 'Descending',
        value: 'descending',
    },
    {
        label: 'Neutral',
        value: 'neutral',
    },
];

export class SunburstWidgetOptionsImpl extends WidgetOptionsImpl implements SunburstWidgetOptions {
    @observable sunburst: SunburstOptions = {
        stroke: {
            enabled: false,
            color: 'rgba(0, 0, 0, 0.4)',
            width: 2,
        },
        spacing: {
            enabled: true,
            width: 7,
        },
        radius: 0,
        maxItems: 4,
        labelThreshold: 1,
        childItems: {
            max: 8,
            colorPalette: {
                type: 'solid',
                direction: 'descending',
                monochrome: {
                    startColor: '',
                    endOffset: 30,
                },
                hue: {
                    startColor: '',
                    rotation: 15,
                },
                solid: '',
            },
        },
    };
    @observable labelSize = 8;
    @observable offsetX = 0;
    @observable offsetY = 0;
    @observable zoom = 50;
    @observable labelEnable = true;
    @observable animationType: AnimationValues = 'sequential';
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            labelSize: 8,
            sunburst: {
                spacing: {
                    width: 7,
                },
                childItems: {
                    colorPalette: {
                        monochrome: {
                            endOffset: 30,
                        },
                    },
                },
            },
        },
    };
}
