import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { SmartSize, TimeFormat } from '@sprinklr/stories/widget/types';
import { IAxis, ITicks } from 'components/_charts/Primitives/XAxis';
import { observable } from 'mobx';
import { ScaleBy } from '@sprinklr/stories/widget/WidgetOptions';

export interface HeatMapTableChartWidgetOptions extends WidgetOptions {
    classes?: string;
    showLegend?: boolean;
    legendSize?: number;
    orbSize?: number;
    timeFormat?: TimeFormat;
    scaleBy?: ScaleBy;
}

export class AxisImpl implements IAxis {
    @observable enabled = true;
    @observable label = {
        enabled: true,
        size: 20,
        padding: 50,
    };
    @observable ticks: ITicks = {
        enabled: true,
        label: {
            enabled: true,
            size: 30,
            numberFormat: '1.2k',
            timeFormat: 'ddd ha',
        },
        line: {
            enabled: true,
        },
    };
    @observable padding = {
        start: 0,
        end: 0,
    };
}

export class HeatMapTableChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HeatMapTableChartWidgetOptions {
    @observable showLegend = true;
    @observable legendSize = 20;
    @observable orbSize = 75;
    @observable timeFormat: TimeFormat = 'b';
    @observable showTimeRemainder = true;
    @observable metricValueSize: number = null;
    @observable scaleBy: ScaleBy = 'row';
    @observable xAxis: IAxis = new AxisImpl();
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            legendSize: 20,
            orbSize: 70,
            xAxis: {
                label: {
                    size: 20,
                },
            },
        },
        optionDefaults: {
            showLegend: true,
            xAxis: {
                enabled: true,
            },
        },
        breakpoints: {
            xs: {
                options: {
                    showLegend: false,
                    xAxis: {
                        enabled: false,
                    },
                },
            },
            s: {
                options: {
                    showLegend: false,
                    xAxis: {
                        enabled: false,
                    },
                },
            },
        },
    };
}
