import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import {
    LabelTextFormat,
    Orientation,
    PanelOrientation,
    PercentChangeColor,
    SmartSize,
} from '@sprinklr/stories/widget/types';
import { Threshold } from 'components/Threshold/Thresholds';
import { IAxis, ITicks } from 'components/_charts/Primitives/XAxis';
import { observable } from 'mobx';
import { FontCase } from 'models/Theme/Theme';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { colors } from 'components/spaceKit';
import { CenterAxis } from './types';

export interface BarChartWidgetOptions extends WidgetOptions {
    // component props
    data?: any;
    limit?: number;
    colors?: string[];
    classes?: string;
    orientation?: Orientation;
    panelOrientation?: PanelOrientation;
    thresholds?: Threshold[];
    maxThresholds?: number;
    blacklist?: string[];
    yAxisLabelSize?: number;
    xAxis?: IAxis;
    yAxis?: IAxis;
}

class AxisImpl implements IAxis {
    @observable enabled = false;
    @observable label = {
        enabled: true,
        size: 50,
        padding: 0,
    };
    @observable ticks: ITicks = {
        enabled: true,
        label: {
            enabled: false,
            size: 50,
            numberFormat: '1.2k',
            numberCase: 'lowercase',
            timeFormat: 'ddd ha',
            angle: 0,
        },
        line: {
            enabled: false,
        },
        numTicks: 10,
    };
}

export class BarChartWidgetOptionsImpl extends WidgetOptionsImpl implements BarChartWidgetOptions {
    @observable labelSize = 50;

    @observable deprecated = {
        labelEnable: false,
    };
    @observable showYAxis = false;
    @observable yAxisLabelSize = 24;

    @observable xAxis: IAxis = new AxisImpl();
    @observable yAxis: IAxis = new AxisImpl();

    @observable showMetricName = true;
    @observable metricNameSize = 15;
    @observable metricNameCase: FontCase = 'lowercase';

    @observable showMetricValue = true;
    @observable metricValueSize = 50;
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';

    @observable showMetricChange = false;
    @observable metricPercentChangeSize = 17;
    @observable highlightPercentChange: boolean;

    @observable stacked100Percent = false;
    @observable stackedInline = false;

    @observable showTimeRemainder = true;

    @observable spacing = 6;
    @observable padding = 21;

    @observable borderRadiusMode: 'all' | 'ends' = 'all';
    @observable borderRadius = 0;
    @observable borderRadiusStart = 0;
    @observable borderRadiusEnd = 0;

    @observable thresholds: Threshold[] = [];
    @observable thresholdsEnabled = false;
    @observable thresholdsLegend = false;
    @observable thresholdsStacked?: boolean = false;
    @observable thresholdLineWidth?: number = 0.5;
    @observable thresholdsPercent?: boolean = false;
    @observable thresholdsAxis?: boolean = true;
    @observable thresholdsDashed?: boolean = false;
    @observable maxThresholds?: number = 5;

    @observable themeImagePosition = 'left';

    @observable labelTextFormat: LabelTextFormat = 'clamp';
    @observable labelLineClamp = true;
    @observable labelLineClampValue = 1;
    @observable labelWrapText = false;

    @observable endianTime = 'MMM DD YYYY';

    @observable zeroFill = true;
    @observable blacklist: string[] = [];
    @observable centerAxis: CenterAxis = {
        enabled: true,
        color: '',
        size: 10,
        spacing: 15,
    };
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            spacing: 6,
            padding: 21,
            labelSize: 50,
            metricValueSize: 50,
            metricPercentChangeSize: 17,
            metricNameSize: 15,
        },
        optionDefaults: {
            labelEnable: false,
            showMetricName: true,
            showMetricValue: true,
            showMetricChange: false,
            showTimeRemainder: true,
        },
        breakpoints: {
            xs: {
                options: {
                    labelEnable: false,
                    showMetricName: false,
                    showMetricValue: false,
                    showMetricChange: false,
                    showTimeRemainder: false,
                },
            },
            s: {
                options: {
                    showMetricName: false,
                    showMetricChange: false,
                    showTimeRemainder: false,
                },
            },
            m: {
                options: {
                    showMetricChange: false,
                    showTimeRemainder: false,
                },
            },
        },
    };
    @observable percentChangeColor: PercentChangeColor = {
        enabled: false,
        up: colors.sentiments.fern,
        zero: '',
        down: colors.sentiments.blush,
    };
}
