import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';

import { HyperSpaceBarChartWidgetOptions } from './types';
import { observable } from 'mobx';
import {
    BaseXYAxisChartOptions,
    LegendOptions,
    MetaInfoOptions,
} from '@sprinklr/stories/hyperspaceWidgets/types';
import { BaseXYAxisChartOptionsImpl, MetaInfoOptionsImpl, LegendOptionsImpl } from '../options';

export class HyperSpaceBarChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceBarChartWidgetOptions {
    @observable barHeight = 90;
    @observable zeroFill = true;
    @observable
    baseXYChartOptions: BaseXYAxisChartOptions = new BaseXYAxisChartOptionsImpl().setValues({
        yAxis: {
            barLabels: {
                enabled: true,
                position: 'side',
            },
            // \/\/\/\/ duplicate - can we just merge the differences... or use a common impl for at least ticks?
            ticks: {
                enabled: true,
                formats: {
                    numberFormat: {
                        decimals: 0,
                        useShortened: true,
                    },
                    dateFormat: {
                        autoFormat: true,
                        formatString: 'dd MMM',
                    },
                },
                numTicks: 5,
                size: 20,
            },
            enabled: true,
            label: {
                enabled: true,
                size: 25,
                padding: 25,
            },
            line: {
                enabled: false,
                stroke: '',
                strokeWidth: 1,
            },
        },
    });
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
    @observable borderRadius = 4;
    @observable compareMode = false;
    @observable legend: LegendOptions = new LegendOptionsImpl();
}
