import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { WordCloudWord } from 'components/_charts/WordCloud/WordCloud';
import { Theme } from 'models/Theme/Theme';
import { observable } from 'mobx';
import { Emoji, SmartSize } from '@sprinklr/stories/widget/types';

export interface WordCloudWidgetOptions extends WidgetOptions {
    data?: WordCloudWord[];
    limit?: number;
    padding?: number;
    minFontSize?: number;
    maxFontSize?: number;
    spacing?: number;
    colors?: string[];
    showSentiment?: boolean;
    negativeSentimentColor?: string;
    blacklist?: string[];
    widgetIndex?: string;
    maxWords?: number;
    mergedTheme?: Theme;
}

export class WordCloudWidgetOptionsImpl extends WidgetOptionsImpl
    implements WordCloudWidgetOptions {
    @observable minFontSize = 70;
    @observable maxFontSize = 150;
    @observable showSentiment = true;

    @observable spacing = 20;
    @observable blacklist: string[] = [];
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            minFontSize: 70,
            maxFontSize: 150,
            spacing: 20,
        },
    };
    @observable emoji: Emoji = {
        platform: 'default',
    };
    @observable maxWords = 60;
}
