import { AnalyticsEngine } from '@sprinklr/stories/analytics/AnalyticsRequest';
import TemporalDimension from '@sprinklr/stories/analytics/TemporalDimension';
import AccountItem from '@sprinklr/stories/analytics/AccountItem';
import SourceItem from '@sprinklr/stories/analytics/SourceItem';
import TopicTheme from '@sprinklr/stories/analytics/TopicTheme';
import BenchmarkBrand from '@sprinklr/stories/analytics/BenchmarkBrand';
import SLAFrequency from '@sprinklr/stories/analytics/SLAFrequency';
import CommunityUser from '@sprinklr/stories/analytics/CommunityUser';
import WorkQueue from '@sprinklr/stories/analytics/WorkQueue';
import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import LookupItem from './LookupItem';

export default class BulkItemFactory {
    static fromResponse(
        raw: Record<string, any>,
        dimensionName: string,
        engine?: AnalyticsEngine
    ): Record<string, BulkItem | any> {
        const result = {};
        for (const id in raw) {
            if (raw[id] === null || raw[id] === undefined) {
                continue;
            }
            if (typeof raw[id] !== 'object') {
                result[id] = raw[id];
                continue;
            }
            result[id] = BulkItemFactory.factory(raw[id], dimensionName, id, engine);
        }
        return result;
    }

    static factory(
        item: Record<string, any> | string,
        dimensionName: string,
        id?: string,
        engine?: AnalyticsEngine
    ): BulkItem | any {
        if (typeof item === 'string') {
            switch (dimensionName) {
                case 'TIME_OF_DAY':
                case 'DAY_OF_WEEK':
                case 'MONTH_OF_YEAR':
                    return new TemporalDimension(id ?? item, item, dimensionName);
                default:
                    return id;
            }
        }

        if (typeof item !== 'object') {
            return item;
        }

        if (id === null || id === undefined) {
            id = item.id;
        }

        if (dimensionName.startsWith('BENCHMARKING_KEYWORD_LIST_TAG_')) {
            dimensionName = 'BENCHMARKING_KEYWORD_LIST';
        } else if (
            dimensionName === 'TOPIC_IDS' ||
            dimensionName === 'LST_THEME_DISPLAY_NAME' ||
            dimensionName.startsWith('SPECIFIC_THEME_TAG_') ||
            dimensionName.startsWith('SPECIFIC_TOPIC_TAG_') ||
            dimensionName.startsWith('SPECIFIC_TOPIC_GROUP_')
        ) {
            return TopicTheme.fromObject(item);
        }

        switch (dimensionName) {
            case 'COUNTRY':
            case 'CITY':
                return LookupItem.fromObject(item);

            // For SLA Frequency, we normalize to objects.
            case 'SLA_FREQUENCY':
                return SLAFrequency.fromObject(item);

            case 'accountIds':
            case 'ACCOUNT_ID':
                return AccountItem.fromObject(item);

            case 'LISTENING_MEDIA_TYPE':
            case 'REVIEW_SOURCE':
                return SourceItem.fromObject(item);

            case 'COMMUNITY_USER_ID':
                return CommunityUser.fromObject(item);

            case 'BRAND_ID':
            case 'BENCHMARKING_KEYWORD_LIST':
                if (engine === 'BENCHMARKING') {
                    return BenchmarkBrand.fromObject(item);
                }
                break;

            case 'TIME_OF_DAY':
            case 'DAY_OF_WEEK':
            case 'MONTH_OF_YEAR':
                return TemporalDimension.fromObject(item);

            case 'WORK_QUEUE_IDS':
                return WorkQueue.fromObject(item);
        }

        BulkItem.normalize(item, dimensionName);

        // when the PII feature is in use and the user doesn't have permissions,
        // the post will have many fields with a result string of 'Restricted result'
        // more notes at docs/posts.md
        if (item.snMsgId === 'Restricted result') {
            item.snMsgId = id;
        }

        return item;
    }
}
