import TopicTheme from './TopicTheme';
import SLAFrequency from './SLAFrequency';
import CommunityUser from './CommunityUser';
import LookupItem from './LookupItem';

export type AnalyticChartLabel =
    | number
    | string
    | BulkItem
    | TopicTheme
    | SLAFrequency
    | CommunityUser
    | LookupItem;

export default class BulkItem {
    protected constructor(public id: string = null, protected name: string = null) {}

    static normalize(item: Record<string, any> | string | null, dimensionName?: string): void {
        if (item === null || typeof item !== 'object') {
            return;
        }

        // VOICE engine Work Queues
        if (dimensionName === 'WORK_QUEUE_ID') {
            item.id = item.workQueueId;
            item.name = item.workQueueName;
        }

        // BENCHMARKING_ACCOUNT_ID
        if (dimensionName === 'BENCHMARKING_ACCOUNT_ID') {
            // Try to make BENCHMARKING_ACCOUNT_IDs look like regular account objects
            if (item.type && !item.snType) {
                item.snType = item.type;
            }
            if (item.displayName) {
                item.name = item.displayName;
            }
            return;
        }

        // USER_ID and AFFECTED_USER_ID
        if (item.userId !== undefined) {
            item.id = item.userId.toString();
            // SPRINKLR_CREATIVE_ACCOUNT_DICTIONARY
        } else if (
            dimensionName === 'SPRINKLR_CREATIVE_ACCOUNT_DICTIONARY' &&
            item.accountUserId !== undefined
        ) {
            item.id = item.accountUserId.toString();
            // ACCOUNT_ID
        } else if (item.accountId !== undefined) {
            item.id = item.accountId.toString();
        }

        // empty displayName is not wanted
        if (item.displayName === '') {
            delete item.displayName;
        }

        if (item.name === undefined || item.name === null) {
            if (item.displayName !== undefined) {
                item.name = item.displayName;
                // USER_ID and AFFECTED_USER_ID
            } else if (item.visibleId !== undefined) {
                item.name = item.visibleId;
                // ACCOUNT_ID
            } else if (item.screenName !== undefined) {
                item.name = item.screenName;
                // ACCOUNT_GROUP_ID
            } else if (item.groupName !== undefined) {
                item.name = item.groupName;
                // Otherwise this record doesn't have a readable name, so use id instead
                //   https://sprinklr.atlassian.net/browse/DISPLAY-1287
            } else {
                item.name = item.id;
            }
        }

        // Cleanup extra crap we don't need.  Reduces the in-memory load on these
        // large data pulls.  DISPLAY-1052
        if (dimensionName) {
            switch (dimensionName) {
                case 'MEDIA_ASSET_ID':
                    delete item.assetSource;
                    delete item.assetStatus;
                    delete item.assetType;
                    delete item.clientActionStats;
                    delete item.clientDetails;
                    delete item.customFields;
                    delete item.reportingStats;
                    delete item.shareConfigs;
                    delete item.socialAsset;
                    break;
            }
        }
    }

    toString(): string {
        return this.id === undefined || this.id === null ? '' : this.id;
    }

    toLowerCase(): string {
        return this.toString().toLowerCase();
    }

    toName(): string {
        return this.name;
    }

    toColor(): string {
        return undefined;
    }

    toImageUrl(): string {
        return undefined;
    }

    toEmail(): string {
        return undefined;
    }

    get sortValue(): any {
        return this.name;
    }
}
