import Field from './Field';
import { FieldType, FieldDataType } from '../reporting/types';

// TODO: these @sprinklr/display-builder imports need to go away
import {
    normalizeLabel,
    stringCase,
} from '@sprinklr/display-builder/utils/NormalizeLabel/NormalizeLabel';

export default class Metric extends Field<string> {
    aggregateFunction: string;
    alternateHeading: boolean;

    constructor(
        name: string,
        type?: FieldType,
        dataType?: FieldDataType,
        aggregateFunction?: string,
        alternateHeading?: boolean
    ) {
        super(name, typeof type !== 'undefined' && type !== null ? type : 'NUMBER', dataType);

        // Mainly needed from invocation from unit test where aggregateFunction isn't defined
        if (aggregateFunction === undefined) {
            aggregateFunction = 'SUM';
        }

        this.aggregateFunction = aggregateFunction;
        this.alternateHeading = !!alternateHeading;
    }

    isDecorator(): boolean {
        return (
            this.aggregateFunction === 'CHANGE' ||
            this.aggregateFunction === 'PERCENTAGE_CHANGE' ||
            this.aggregateFunction === 'PERCENTAGE'
        );
    }
    isChange(): boolean {
        return this.aggregateFunction.includes('CHANGE');
    }
    isPercentage(): boolean {
        return (
            this.aggregateFunction.includes('PERCENTAGE') ||
            this.name?.toLowerCase().includes('_rate') || // coerce metrics with a name that includes '_rate' to return true
            this.name?.toLowerCase().includes('_percentage') // coerce metrics with a name that includes '_percentage' to return true
        );
    }

    getName(): string {
        switch (this.aggregateFunction) {
            case 'CHANGE':
                return 'change in ' + this.name.replace('CHANGE', '');

            case 'PERCENTAGE':
                return this.name.replace('PERCENTAGE', '') + ' %';

            case 'PERCENTAGE_CHANGE':
                return (
                    'change in ' + this.name.replace('PERCENTAGE', '').replace('CHANGE', '') + ' %'
                );

            default:
                return this.name;
        }
    }

    getNamePretty(convertCase?: any): string {
        let result = this.getName();

        if (!this.alternateHeading) {
            result = normalizeLabel(result + '');

            if (convertCase) {
                result = stringCase(result, convertCase);
            }
        }

        return result;
    }
}
