import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { PostTemplate } from 'components/Post/PostGroup';
import {
    AnimationOrientation,
    Emoji,
    ExtraPostDataOptions,
    PanelOrientation,
    PostCompositionTypeValues,
    PostTextFormat,
    ReviewOptions,
    SmartSize,
    TimeStampOptions,
    TitleOptions,
} from '@sprinklr/stories/widget/types';
import { StoryOptions } from './StoryCard/types';
import {
    GridLayout,
    NativeColorMode,
    NativeStylingOptions,
    ProfanityFilter,
    RetweetIndicatorOptions,
} from './types';
import { observable } from 'mobx';
import { PostOptionLocation } from '../PostsWindowWidget/types';

export interface PostsWidgetOptions extends WidgetOptions {
    classNames: string;
    postConfig: any;
    // new

    postTemplate: PostTemplate;
    postTransition?: 'scale' | 'fade';
    postDirection: 'horizontal' | 'vertical' | null;
    userGroupEffect?: 'bottom' | 'top' | 'none';
    // theme: 'primary' | 'secondary' | null;
    showText?: boolean;
    showSentiment: boolean;
    showKlout?: boolean;
    showCenterAvatar?: boolean;
    showCenterChannel?: boolean;
    animationLength?: number;
    animationDelay?: number;
    animationDuration?: number;
    animationOrientation?: AnimationOrientation;
    imageStyle?: string;
    showEngagementMetrics?: boolean;
    engagementMetricsPosition?: string;
    showAdsMetrics?: boolean;
    showImageStyles?: boolean;
    showUserGroup?: boolean;
    postLineClamp?: boolean;
    postLineClampValue?: number;
    imagePostLineClamp?: boolean;
    imagePostLineClampValue?: number;
    userGroupPosition?: string;
    panelOrientation?: PanelOrientation;
    timeStampFormat?: TimeStampOptions;
    retweetIndicator?: RetweetIndicatorOptions;
    storyOptions?: StoryOptions;
    setNumAvailablePosts?: any;
}

export class GridLayoutImpl implements GridLayout {
    @observable gap = 10;
    @observable columns = 3;
}

export class NativeStylingOptionsImpl implements NativeStylingOptions {
    @observable enabled = true;
    @observable colorMode: NativeColorMode = 'light';
    @observable removeUrls = true;
    @observable showExpandedEntityUrls = false;
    @observable clickableUrls = false;
    @observable lineClamp = {
        enabled: true,
        lines: 8,
        hasMedia: {
            enabled: true,
            lines: 8,
        },
    };
    @observable maxCharacters = 400;
    @observable border = {
        enabled: false,
        color: 'rgba(0,0,0, .3)',
        size: 5,
    };
    @observable shadow = {
        enabled: false,
        color: 'rgba(0,0,0, .5)',
        size: 25,
    };
    @observable fontScale = 30;
    @observable showTitle = false;
    @observable hideZero = true;
    @observable profanityFilter: ProfanityFilter = {
        enabled: false,
        profanityList: [],
        obscureSymbol: '*',
        replace: false,
        replaceList: {},
    };
    @observable title = {
        lineClamp: {
            enabled: true,
            lines: 3,
            hasMedia: {
                enabled: true,
                lines: 2,
            },
        },
    };
    // @observable retweetIndicator = true;
}

export class NativeStylingOptionsDefaultImpl extends NativeStylingOptionsImpl
    implements NativeStylingOptions {
    @observable enabled = false;
}

export interface PostCardWidgetOptions extends PostsWidgetOptions {
    extraPostDataOptions: ExtraPostDataOptions;
    gridLayout: GridLayout;
    nativeStyling?: NativeStylingOptions;
}

export class PostsWidgetOptionsImpl extends WidgetOptionsImpl implements PostsWidgetOptions {
    classNames: string;
    postConfig: any;
    numbPosts: number;
    postTemplate: PostTemplate;
    postTransition?: 'scale' | 'fade';
    postDirection: 'horizontal' | 'vertical' | null;
    userGroupEffect?: 'bottom' | 'top' | 'none';
    // theme: 'primary' | 'secondary' | null;
    showSentiment: boolean;
    showKlout?: boolean;
    showCenterAvatar?: boolean;
    showCenterChannel?: boolean;

    @observable showImageStyles = true;
    @observable imageFiltersOpen = true;
    @observable imageFilterBlur = false;
    @observable imageFilterBrightness = false;
    @observable imageFilterContrast = false;
    @observable imageFilterGrayscale = false;
    @observable imageFilterSaturate = false;
    @observable imageFilterHueRotate = false;
    @observable imageFilterOpacity = false;
    @observable imageFilterInvert = false;
    @observable imageFilterSepia = false;
    @observable imageFilterBlurValue = 0;
    @observable imageFilterBrightnessValue = 100;
    @observable imageFilterContrastValue = 100;
    @observable imageFilterGrayscaleValue = 0;
    @observable imageFilterSaturateValue = 100;
    @observable imageFilterHueRotateValue = 0;
    @observable imageFilterOpacityValue = 100;
    @observable imageFilterInvertValue = 0;
    @observable imageFilterSepiaValue = 0;
    @observable imageBlendMode = 'normal';
    @observable imageSecondaryBlendMode = '';
    @observable imageBlendColor = 'rgba(0,0,0,.3)';
    @observable imageSecondaryBlendColor = '';
    @observable presetImageFilters = '';
    @observable imageTextColor = '#FFF';
    @observable postImageTextSize = 10;
    @observable imagePostLineClamp = true;
    @observable imagePostLineClampValue = 3;
    @observable imagePostHideText = false;
    @observable imageFaceDetection = false;
    @observable hashtagTextColor = '';
    @observable hashtagBackgroundColor = '';
    @observable mentionTextColor = '';
    @observable mentionBackgroundColor = '';
    @observable urlTextColor = '';
    @observable urlBackgroundColor = '';
    @observable showTextEntityUrls = false;
    @observable showExpandedEntityUrls = false;
    @observable clickableUrls = false;

    @observable showEngagementMetrics = true;
    @observable engagementMetricsSize = 30;
    @observable engagementMetricsPosition = 'bottom_left';

    @observable showAdsMetrics = true;
    @observable adsMetricsSize = 30;

    @observable showTextStyles = false;
    @observable showText = true;
    @observable textOnlyColor = '#FFF';
    @observable postTextOnlySize = 10;
    @observable postLineClamp = true;
    @observable postLineClampValue = 3;
    @observable textFitMin = 20;
    @observable showPostTextOverlay = false;

    @observable showUserGroup = true;
    @observable showUserAvatar = true;
    @observable showUserName = true;
    @observable showUserScreenName = true;
    @observable userGroupIconSize = 10;
    @observable userGroupAvatarSize = 20;
    @observable userGroupNameSize = 20;
    @observable userGroupScreenNameSize = 16;
    @observable userGroupPosition = 'top';
    @observable userGroupGradientColor = 'rgba(0,0,0,.3)';
    @observable userGroupGradientEnabled = false;
    @observable userGroupGradientDirection = 'bottomToTop';
    @observable showTimeStamp = false;
    @observable timeStampSize = 15;
    @observable timeStampFormat: TimeStampOptions = 'timeAgo';
    @observable endianTime = 'D MMM YYYY, h:mm a';

    @observable textOnlyBackgroundColor = '';
    @observable textOnlyTextFormat: PostTextFormat = 'fit';
    @observable textOnlyTeleprompterSpeed = 50;
    @observable postImageTextFormat: PostTextFormat = 'fit';
    @observable postImageTeleprompterSpeed = 50;
    @observable imageBackgroundSize = 'cover';
    @observable showZeroEngagementValues?: boolean = false;

    @observable showLinkPreview?: boolean = false;
    @observable linkPreviewSize?: number = 12;

    @observable showQuotedTweet?: boolean = false;
    @observable quotedTweetSize?: number = 7;

    @observable maxCharacters?: number = 180;
    @observable teleprompterSpeed = 50;

    @observable userGroupLayoutType = 'a';
    @observable postCompositionType: PostCompositionTypeValues = 'a';
    @observable socialIconBgColor = false;
    @observable removeGenericPII = true;

    @observable customFields: { fieldId: string; size: number } = {
        fieldId: '',
        size: 12,
    };

    @observable location: PostOptionLocation = {
        enabled: false,
        size: 15,
    };

    @observable profanityFilter: ProfanityFilter = {
        enabled: false,
        profanityList: [],
        obscureSymbol: '*',
        replace: false,
        replaceList: {},
    };

    @observable retweetIndicator: RetweetIndicatorOptions = {
        enabled: false,
        opacity: 50,
        size: 13,
    };

    // ratings/reviews
    @observable reviews: ReviewOptions = {
        enabled: false,
        // title/name
        title: {
            enabled: true,
            color: '',
            size: 17,
            format: {
                type: 'clamp',
                clamp: {
                    enabled: true,
                    lines: 2,
                },
                fit: {
                    min: 10,
                    max: 400,
                },
            },
        },
        rating: {
            enabled: true,
            filledColor: '',
            emptyColor: '',
            size: 12,
        },
        product: {
            enabled: true,
            color: '',
            size: 9,
        },
        message: {
            enabled: true,
            size: 13,
            color: '',
            format: {
                type: 'clamp',
                clamp: {
                    enabled: true,
                    lines: 5,
                },
                fit: {
                    min: 20,
                    max: 400,
                },
            },
        },
        domain: {
            enabled: true,
            color: '',
            size: 9,
        },
    };
    @observable postTitleOptions: TitleOptions = {
        enabled: false,
        size: 25,
        color: '',
        format: {
            type: 'clamp',
            clamp: {
                enabled: true,
                lines: 3,
            },
            fit: {
                min: 20,
                max: 400,
            },
        },
    };
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
    };
    @observable postComposition = 'a';
    @observable emoji: Emoji = {
        platform: 'default',
    };
}
