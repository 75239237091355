import {
    AggregationFunction,
    AnalyticsProjection,
    AnalyticsRequest,
    ProjectionDetails,
} from './AnalyticsRequest';
import { ReportingResponse } from '../reporting/types';

// topic cluster values are 1-100 and is often arbitrary
// so we fallback to one hundred minus the index of the row
// if the count is missing
const TOPIC_CLUSTER_FALLBACK_LENGTH = 100;

export const isMetricCustom = (measurementName: string): boolean => {
    const last2 = measurementName.slice(-2);
    const splits = measurementName?.split('_');
    return !Number.isNaN(parseInt(splits[splits.length - 1])) || !Number.isNaN(parseInt(last2));
};

export const buildProjectionLookupKey = (measurementName: string, report: string) => {
    if (!measurementName || !report) {
        return null;
    }

    // custom metrics have a number at the end and follow a different unique key pattern
    // more info at docs/metadata-search -> ## Unique Metric Keys
    if (isMetricCustom(measurementName)) {
        return `M_${measurementName}_${measurementName}`.toUpperCase();
    }

    if (report) {
        return `M_${report}_${measurementName}`.toUpperCase();
    }

    return null;
};

export const buildProjectionHeading = (
    measurementName: string,
    report: string,
    agg: AggregationFunction = 'SUM'
) => {
    if (!measurementName) {
        return null;
    }

    const measurement = measurementName.replace('Copy of ', '').replace('.', '_'); // remove "Copy Of" from measurementName so that request doesn't error out

    if (measurement.startsWith('M_')) {
        // Might have already been through this function, so don't repeat process
        return measurement;
    }

    const aggSuffix = agg === 'SUM' ? '' : `_${agg}`;

    if (!report) {
        return `M_${measurement}${aggSuffix}`.toUpperCase();
    }

    return `M_${report}_${measurement}${aggSuffix}`.toUpperCase();
};

export const dedupeProjections = (projections: AnalyticsProjection[]) => {
    return projections.filter(
        (projection, index) =>
            projections.findIndex(
                existing =>
                    existing.heading === projection.heading ||
                    (existing.measurementName === projection.measurementName &&
                        existing.aggregateFunction === projection.aggregateFunction)
            ) === index
    );
};

// Metric aggregateFunctions that are "CHANGE", "PERCENTAGE_CHANGE" or "PERCENTAGE" need
// to be handled by adding a projectionDecorations array to the ReportingRequest
export const decorators = ['CHANGE', 'PERCENTAGE_CHANGE', 'PERCENTAGE', 'CUMULATIVE_SUM'];

export const buildAnalyticsProjectionHeader = (
    measurementName: string,
    aggregateFunction: AggregationFunction = 'SUM',
    report: string,
    projectionDetails?: ProjectionDetails
) => {
    let measurement = measurementName?.replace('Copy of ', '') ?? ''; // remove "Copy Of" from measurementName so that request doesn't error out

    //custom metrics need the key to be formatted to match Space
    const resolvedReport = projectionDetails?.origReport ?? report;
    if (isMetricCustom(measurementName) && resolvedReport) {
        // underscores will cause requests to fail when used in projection key
        if (!measurementName.includes(`M_${resolvedReport}_`)) {
            measurement = `M_${resolvedReport.replace(/-/g, '_')}_${measurement}`;
        }
    }

    if (measurement.endsWith(`_${aggregateFunction}`) || aggregateFunction === 'SUM') {
        return measurement;
    }

    // If the request includes a 'PERCENTAGE_CHANGE' projection and the projection's aggregation is anything other than 'SUM',
    // add the aggregation to the heading. Otherwise the % change will show the 'SUM' percent change instead of the correct aggregation's percent change
    if (
        projectionDetails?.percentChangeAggregation &&
        projectionDetails?.percentChangeAggregation !== 'SUM' &&
        projectionDetails?.percentChangeAggregation !== 'PERCENTAGE_CHANGE' &&
        aggregateFunction === 'PERCENTAGE_CHANGE'
    ) {
        return `${measurement}_${projectionDetails.percentChangeAggregation}_${aggregateFunction}`;
    }
    return `${measurement}_${aggregateFunction}`;
};

// Used when metrics use CHANGE, PERCENTAGE_CHANGE, or PERCENTAGE.
// This will truncate the data.headings and rows to remove extraneous
// data for each metric
export const removeUnusedDecorators = (
    data: ReportingResponse,
    projections: AnalyticsProjection[],
    dimensionCount: number,
    report: string
): ReportingResponse => {
    const desiredProjectionHeadings: string[] = projections.map(projection => {
        // No matter what order the projectionDecorations are in, the
        // results come back in this pre-defined order:
        //   CHANGE, PERCENTAGE_CHANGE, PERCENTAGE
        return buildAnalyticsProjectionHeader(
            projection.measurementName,
            projection.aggregateFunction,
            report,
            projection.details
        );
    });

    const unwantedIndexes: number[] = [];
    // ex. data.headings = ['TIME', 'MENTIONS]
    // ex. dimensionCount = 1
    data.headings.forEach((heading, index) => {
        if (index < dimensionCount) {
            return;
        }
        if (!desiredProjectionHeadings.includes(heading)) {
            unwantedIndexes.push(index);
        }
    });
    if (unwantedIndexes.length === 0) {
        return { ...data };
    }

    const headings = data.headings.filter((heading, index) => !unwantedIndexes.includes(index));

    const totals = (data.totals ?? []).filter(
        (total, index) => !unwantedIndexes.includes(index + dimensionCount)
    );

    const rows = (data.rows ?? []).map(row =>
        row.filter((value, index) => !unwantedIndexes.includes(index))
    );

    return {
        ...data,
        headings,
        rows,
        totals,
    };
};

export const getTopicClusterHeading = (headings: string[]): string | undefined => {
    return headings.find(
        heading =>
            heading.indexOf('TOPIC_CLUSTER') !== -1 ||
            heading.indexOf('Topic Cluster') !== -1 ||
            heading.indexOf('MESSAGE_TOPIC_CLUSTER') !== -1
    );
};

export const TOPIC_CLUSTER_HEADINGS = ['MESSAGE_TOPIC_CLUSTER', 'TOPIC_CLUSTER', 'Topic Cluster'];

export function pivotTopicClusterSpaceRequest({ groupBy, groupedData }) {
    const headings = [groupBy.key, 'TOPIC_CLUSTER'];

    const newRows = [];

    for (
        let groupedDataIndex = 0;
        groupedDataIndex < groupedData[groupBy.key].length &&
        groupedDataIndex < TOPIC_CLUSTER_FALLBACK_LENGTH;
        groupedDataIndex++
    ) {
        const spaceResponse = groupedData[groupBy.key][groupedDataIndex];
        if (spaceResponse.groupedData?.[spaceResponse.key]?.length > 1) {
            for (
                let index = 1;
                index < spaceResponse.groupedData[spaceResponse.key].length;
                index++
            ) {
                const rowItem = spaceResponse.groupedData[spaceResponse.key][index];
                newRows.push([
                    spaceResponse.key,
                    rowItem.key,
                    rowItem.count ?? TOPIC_CLUSTER_FALLBACK_LENGTH + 1 - index,
                ]);
            }
        } else {
            newRows.push([spaceResponse.key, '', spaceResponse.count]);
        }
    }

    return {
        headings,
        rows: newRows,
    };
}

export const pivotTopicCluster = (
    data: ReportingResponse,
    firstProjection: AnalyticsProjection,
    pageSize = 100
) => {
    let { headings, rows } = data;

    if (!getTopicClusterHeading(data.headings)) {
        return data;
    }

    // Determine if we are in a topic cluster, and transform appropriately
    for (const heading of data.headings) {
        if (['TOPIC_CLUSTER', 'Topic Cluster'].includes(heading)) {
            // dimensions.push(new Dimension(heading));

            const newHeadings = [heading, 'TOPIC_CLUSTER', firstProjection.heading];
            const newRows = [];

            for (let j = 0; j < data.rows.length && j < pageSize; j++) {
                const row = data.rows[j];
                if (row.length > 1) {
                    for (let i = 1; i < row.length; i++) {
                        newRows.push([row[i], row[0], 101 - i]);
                    }
                } else {
                    newRows.push(['', row[0], 100]);
                }
            }

            headings = newHeadings;
            rows = newRows;
            break;
        }
    }

    return {
        ...data,
        headings,
        rows,
    };
};

export function getMeasurementNameByIndex({
    analyticsRequest,
    index,
}: {
    analyticsRequest: AnalyticsRequest;
    index: number;
}) {
    return analyticsRequest?.projections?.[index]?.measurementName;
}

// checks to see if numbers are larger in earlier indexes than later indexes
// returns true if there are numbers larger in earlier indexes
// returns false if there are no numbers larger in earlier indexes
// ex. [1, 4, 0] -> true
// ex. [0, 3, 4] -> false
export function earlierIndexValuesAreLarger(arrayToCheck: number[]): boolean {
    for (let i = 0; i < arrayToCheck.length; i++) {
        const foundIndex = arrayToCheck.findIndex(item => item > arrayToCheck[i]);
        if (foundIndex < i && foundIndex !== -1) {
            return true;
        }
    }
    return false;
}
