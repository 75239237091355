import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { observable } from 'mobx';
import { FontCase } from 'models/Theme/Theme';
import { DecimalFormat } from 'utils/Number/NumberUtils';

export interface MetricRollupWidgetOptions extends WidgetOptions {
    data?: any;
    metricName?: string;
}

export class MetricRollupWidgetOptionsImpl extends WidgetOptionsImpl
    implements MetricRollupWidgetOptions {
    data?: any;

    @observable labelSize = 15;
    @observable labelName = 'Row Name';

    @observable showMetricName = true;
    @observable metricNameSize = 10;
    @observable metricNameCase: FontCase = 'lowercase';
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';

    @observable showMetricValue = true;
    @observable metricValueSize = 30;

    @observable showMetricChange = false;
    @observable metricPercentChangeSize = 10;
    @observable highlightPercentChange: boolean;

    @observable showTimeRemainder = true;
}
