import { observable } from 'mobx';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { HyperSpaceStackedBarChartWidgetOptions } from './types';
import { BaseXYAxisChartOptions, LegendOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import { StackType } from '../types';
import { BaseXYAxisChartOptionsImpl, LegendOptionsImpl, MetaInfoOptionsImpl } from '../options';

export class HyperSpaceStackedBarChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceStackedBarChartWidgetOptions {
    @observable barHeight = 90;
    @observable zeroFill = true;
    @observable baseXYChartOptions: BaseXYAxisChartOptions = new BaseXYAxisChartOptionsImpl();
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
    @observable legend: LegendOptions = new LegendOptionsImpl();

    // consider creating a bar object
    @observable borderRadius = 4;
    @observable stackType: StackType = 'stacked';
}
