import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { observable } from 'mobx';
import { ShapeBackgroundOptions, ShapeOptions } from './types';

export type ShapeWidgetOptions = WidgetOptions;

const background: ShapeBackgroundOptions = {
    color: '',
    image: null,
    imageFit: 'cover',
    verticalAlign: 'center',
    horizontalAlign: 'center',
};

const border = {
    enabled: false,
    size: 3,
    color: '',
};

export class ShapeWidgetOptionsImpl extends WidgetOptionsImpl implements ShapeWidgetOptions {
    @observable shape: ShapeOptions = {
        type: 'rectangle',
        background,
        border,
        points: 5,
        line: {
            size: 10,
            rotation: 90,
            marker: {
                start: false,
                end: false,
                size: 15,
            },
        },
    };
}
