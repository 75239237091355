import { DonutWidth, LegendOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import { observable } from 'mobx';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { HyperspaceSmartClusterWidgetOptions } from './types';
import _merge from 'lodash/merge';
import { LegendOptionsImpl, MetaInfoOptionsImpl } from '../options';

export class HyperspaceSmartClusterWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperspaceSmartClusterWidgetOptions {
    @observable legend: LegendOptions = _merge(new LegendOptionsImpl(), {
        position: {
            selectedPosition: 'right',
        },
        maxWidth: 230,
    });

    @observable donutWidth: DonutWidth = {
        enabled: false,
        size: null,
    };

    @observable metricGroupOptions: HyperspaceSmartClusterWidgetOptions['metricGroupOptions'] = {
        enabled: true,
        metric: {
            size: 40,
            enabled: true,
            format: {
                decimals: 1,
                useShortened: true,
            },
        },
        metricName: {
            size: 20,
            enabled: true,
        },
        autoSize: {
            enabled: true,
        },
        disablePercentChangeOption: true,
    };

    @observable metaInfoOptions = new MetaInfoOptionsImpl();
    @observable blacklist: string[] = [];
}
