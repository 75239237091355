import { AnalyticsResult } from '@sprinklr/stories/analytics/AnalyticsResult';
import { FieldType } from '@sprinklr/stories/reporting/types';
import { PieChartProps, PieChartWedgeProps } from 'components/_charts/PieChart/PieChart';
import { normalizeLabel } from 'utils/NormalizeLabel/NormalizeLabel';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { PieChartWidgetOptionsImpl } from './options';
import { getEndianTime } from 'utils/Number/NumberUtils';

export const transformPieData = (
    analyticsResults: AnalyticsResult[],
    analyticsRequests,
    options
) => {
    const currentDataSet = analyticsResults[0].dataSets[0].filterRowsData();

    let previousDataSet = null;

    if (analyticsResults[0].dataSets.length > 1) {
        previousDataSet = analyticsResults[0].dataSets[1].filterRowsData();
    }

    const firstMetric = currentDataSet.getFirstMetric();
    const firstMetricIndex = currentDataSet.getMetricIndex(firstMetric);
    let total: number;
    if (analyticsRequests[0] && analyticsRequests[0].includeTotal) {
        total = analyticsResults[0].dataSets[0].getFirstTotal();
    }

    const firstDimension = currentDataSet.getFirstDimension();
    const firstDimensionIndex = currentDataSet.getDimensionIndex(firstDimension);
    const type: FieldType =
        currentDataSet.metrics[
            currentDataSet.metrics && currentDataSet.metrics.length < 2 ? 0 : firstMetricIndex
        ].type;

    const requestTimeZone =
        analyticsResults[0].request?.timePeriods?.length &&
        analyticsResults[0].request.timePeriods[0]
            ? analyticsResults[0].request.timePeriods[0].timeZone
            : null;

    const blacklist = options.blacklist || [];

    let data;
    if (firstDimension) {
        data = currentDataSet.rows
            .filter(row => blacklist.indexOf(row[0]) === -1)
            .map(
                (row): PieChartWedgeProps => {
                    let percentChange = previousDataSet ? 0 : null;
                    const total: number = row[firstMetricIndex] || 0;
                    const rowKey = currentDataSet.rowKey(row);
                    let label: any;
                    if (currentDataSet.dimensions[firstDimensionIndex]?.isDate()) {
                        label = getEndianTime(
                            row[firstDimensionIndex],
                            'MMM DD',
                            undefined,
                            undefined
                        );
                    } else {
                        label = row[firstDimensionIndex] ? row[firstDimensionIndex] : '';
                    }

                    if (previousDataSet) {
                        const previousRows = previousDataSet.rows.filter((prevPeriodRow: any[]) => {
                            const prevRowKey = previousDataSet.rowKey(prevPeriodRow);
                            return prevRowKey === rowKey;
                        });

                        if (previousRows.length > 0) {
                            const previousRow = previousRows[0];
                            const previousValue: number = previousRow[firstMetricIndex] || 0;
                            if (previousValue === 0 && total > 0) {
                                percentChange = 100;
                            } else if (previousValue === 0 && total === 0) {
                                percentChange = 0;
                            } else {
                                percentChange = (total / previousValue - 1) * 100;
                            }
                        } else if (total > 0) {
                            percentChange = 100;
                        }
                    }

                    return {
                        label,
                        value: total,
                        type,
                        percentChange,
                        ratio: null,
                        field: currentDataSet.dimensions[0],
                    };
                }
            );
    } else if (currentDataSet.metrics.length > 1) {
        // For widgets imported from space with no dimension and multiple metrics
        data =
            currentDataSet.metrics.length > 1 &&
            currentDataSet.rows[0].map(
                (value, index): PieChartWedgeProps => {
                    const label = normalizeLabel(currentDataSet.metrics[index].name);

                    return {
                        label,
                        value,
                        type,
                        percentChange: null,
                        ratio: null,
                        field: currentDataSet.dimensions[0],
                    };
                }
            );
    }

    /*
     ** Note: absolute value to match space https://sprinklr.atlassian.net/browse/DISPLAY-3303
     ** totalData is still needed for the pie metric
     */
    const absoluteTotal = total ?? data.reduce((a, b) => a + Math.abs(b.value), 0);
    const totalData = total ? total : data.reduce((a, b) => a + b.value, 0);
    for (let i = 0; i < data.length; i++) {
        data[i].ratio = Math.abs((data[i].value / absoluteTotal) * 100);
    }
    return { totalData, data };
};

export const getPieChartCss: GetWidgetTypeStyles = (
    mergedTheme: Theme,
    dataSet: DataSet,
    widget,
    cssPrefix?: string
): string => {
    let css = '';

    if (!mergedTheme || !dataSet || !widget.options) {
        return css;
    }

    const {
        legendOrientation,
        showLegend,
        legendNameSize,
        legendPercentageSize,
        legendValueSize,
        totalValueSize,
        totalValueMetricSize,
        customLabelSize,
        showCustomLabel,
        offsetX,
        offsetY,
        panelOrientation,
        legendWidth,
        legendHeight,
        height,
        width,
        zoom,
        lineWidth,
        maxItems,
        showOtherItems,
        customRatioOpacity,
        customRatioSize,
    } = widget.options as PieChartWidgetOptionsImpl & PieChartProps;

    const currentDataSet = dataSet.filterRowsData();
    const { totalData, data: pieData } = transformPieData(
        [{ dataSets: [currentDataSet] }],
        [],
        widget.options
    );
    const data: PieChartWedgeProps[] = calculatePieData(pieData, maxItems, showOtherItems);

    const portraitClassCheck =
        (legendOrientation === 'bottom' && panelOrientation === 'portrait') ||
        (legendOrientation === 'top' && panelOrientation === 'portrait');

    const innerOffset =
        legendOrientation === 'right' && showLegend ? width * legendWidth * 0.01 * 0.4 : 0;
    const innerOffsetY = portraitClassCheck && showLegend ? height * legendHeight * 0.01 * 0.4 : 0;

    css += `${cssPrefix} .pie_chart_inner { left: ${-innerOffset}px} `;
    css +=
        panelOrientation === 'portrait' && legendOrientation === 'bottom'
            ? `${cssPrefix} .pie_chart_inner { top: ${-innerOffsetY}px} `
            : '';
    css +=
        panelOrientation === 'portrait' && legendOrientation === 'top'
            ? `${cssPrefix} .pie_chart_inner { bottom: ${-innerOffsetY}px} `
            : '';
    css += `${cssPrefix} .pie_chart_inner {
        transform: translate(${offsetX}%,${offsetY}%) scale(${zoom * 0.02}) }`;
    css += `${cssPrefix} .label_text { font-size: ${
        showCustomLabel ? customLabelSize * 0.04 : 0
    }em; } `;
    css += `${cssPrefix} .inner_radius .metric_name { font-size: ${totalValueMetricSize *
        0.05}em; } `;
    css += `${cssPrefix} .inner_radius .metric_value_inner { font-size: ${totalValueSize *
        0.09}em; } `;
    css += `${cssPrefix} .legend_item_name_size { font-size: ${legendNameSize * 0.07}em; } `;
    css += `${cssPrefix} .legend_item_value { font-size: ${legendValueSize * 0.06}em; } `;
    css += `${cssPrefix} .legend_item_ratio { font-size: ${legendPercentageSize * 0.06}em; } `;
    css += `${cssPrefix} .wedge_ratio { opacity: ${customRatioOpacity * 0.01}; }`;

    if (customRatioSize?.enabled) {
        css += `${cssPrefix} .wedge_ratio { font-size: ${customRatioSize?.size || 10}px } `;
    } else {
        data.slice(0, maxItems).map((wedge, wedgeIndex: number) => {
            const fontSize = Math.max(0.8, 0.4 * Math.sqrt(wedge.ratio)) + 'em';
            css += `${cssPrefix} .label_index_${wedgeIndex +
                1} .wedge_ratio { font-size: ${fontSize} } `;
        });
    }

    css += `${cssPrefix} .label_line { stroke-width: ${lineWidth * 0.16}em} `;
    return css;
};

export const calculatePieData = (
    rawData: PieChartWedgeProps[],
    maxItems: number,
    showOtherItems: boolean
): PieChartWedgeProps[] => {
    const max = showOtherItems ? maxItems - 1 : maxItems;
    const data = rawData && rawData.slice(0, max);
    const dataLength = rawData.length;

    if (showOtherItems && dataLength > max) {
        const removed = rawData.slice(max);
        const otherTotalRatio: number = removed.reduce((acc, curr) => acc + curr.ratio, 0);
        const otherTotalValue: number = removed.reduce((acc, curr) => acc + curr.value, 0);

        data.push({
            ratio: otherTotalRatio,
            label: 'Other',
            value: otherTotalValue,
            percentChange: null,
        });
    }
    return data;
};
