import { observable } from 'mobx';
import { BaseXYAxisChartOptions, LegendOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import { HyperSpaceLineChartWidgetOptions, InterpolationOptions } from './types';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { BaseXYAxisChartOptionsImpl, LegendOptionsImpl, MetaInfoOptionsImpl } from '../options';
import { TrendingPostOptionsImpl } from '../withTrendingPost/options';
import { SpikesImpl } from './spikes/options';
export class HyperSpaceLineChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceLineChartWidgetOptions {
    @observable trendingPost = new TrendingPostOptionsImpl();
    @observable spikes = new SpikesImpl();
    @observable line = {
        strokeWidth: 2,
    };
    @observable pointMarkers = {
        enabled: false,
        size: 10,
    };
    @observable baseXYChartOptions: BaseXYAxisChartOptions = new BaseXYAxisChartOptionsImpl();
    @observable zeroFill = true;

    @observable legend: LegendOptions = new LegendOptionsImpl();
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
    @observable interpolation: InterpolationOptions = 'curveLinear';
    @observable compareMode = false;
}
