import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import {
    BaseXYAxisChartOptions,
    LegendOptions,
    MetaInfoOptions,
} from '@sprinklr/stories/hyperspaceWidgets/types';
import { HyperSpaceColumnChartWidgetOptions } from './types';
import { observable } from 'mobx';
import { BaseXYAxisChartOptionsImpl, MetaInfoOptionsImpl, LegendOptionsImpl } from '../options';

export class HyperSpaceColumnChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceColumnChartWidgetOptions {
    @observable
    baseXYChartOptions: BaseXYAxisChartOptions = new BaseXYAxisChartOptionsImpl();
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
    @observable columnWidth = 90;
    @observable borderRadius = 4;
    @observable compareMode = false;
    @observable legend: LegendOptions = new LegendOptionsImpl();
}
