import BulkItem from './BulkItem';

export default class BenchmarkBrand extends BulkItem {
    public isCompetitor = false;
    private color: string;

    constructor(
        id: string = null,
        name: string = null,
        public competitorBrand = false,
        private imageUrl: string = null
    ) {
        super(id, name);

        this.isCompetitor = competitorBrand;
    }

    // notably "star ratings" only have an id e.g. "5.0"
    // always return SOMETHING - avoids item.toString().replace() fails etc.
    // Also in keeping with newer ECMA standards, even null.toString() and undefined.toString()
    // will be error-free soon.
    toString() {
        return this.name ?? this.id ?? '';
    }

    toColor(): string {
        return this.color;
    }

    toImageUrl(): string {
        return this.imageUrl;
    }

    // Construct a BenchmarkBrand from an object
    public static fromObject(object: any) {
        return new BenchmarkBrand(
            object.id,
            object.name,
            object.competitorBrand ?? object.isCompetitor,
            object.imageUrl
        );
    }

    get sortValue() {
        return this.name;
    }
}
