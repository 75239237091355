// types
import { MergedLegacyTheme } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import { SpeechBubbleWidgetOptions } from './types';

// utils
import { ComputedStyle, fadedFontColor, styler } from 'utils/GenerateStyles/GenerateStyles';
import { customContentRichTextOptionStyles } from 'src/widgets/CustomContentWidget/helpers';
import merge from 'deepmerge';

export const speechBubbleOptionStyles = (
    options: SpeechBubbleWidgetOptions,
    theme: MergedLegacyTheme,
    mergedTheme: Theme
) => {
    const computedStyles: ComputedStyle[] = [];

    const { border, arrow, background, shadow, inheritFontCase } = options;
    const arrowSizeString = styler(arrow.size, 0.1, 'em', '');
    const resolvedOffset = arrow.size * 0.1 + (border.enabled ? border.size * 0.1 : 0);

    const { typography: { color = null } = {} } = merge(theme, mergedTheme);
    const borderColor = border.color || fadedFontColor(color);
    const resolvedColor = border.enabled ? borderColor : background || '#555';
    const shadowColor = shadow.color || fadedFontColor(color);

    const arrowMap = {
        top: {
            borderLeft: `${arrowSizeString} solid transparent`,
            borderRight: `${arrowSizeString} solid transparent`,
            borderBottom: `${arrowSizeString} solid ${resolvedColor}`,
            top: `-${resolvedOffset}em`,
            left: `${arrow.offset}%`,
            transform: `translate3d(-50%, 0, 0)`,
        },
        right: {
            borderTop: `${arrowSizeString} solid transparent`,
            borderBottom: `${arrowSizeString} solid transparent`,
            borderLeft: `${arrowSizeString} solid ${resolvedColor}`,
            top: `${arrow.offset}%`,
            right: `-${resolvedOffset}em`,
            transform: `translate3d(0, -50%, 0)`,
        },
        bottom: {
            borderLeft: `${arrowSizeString} solid transparent`,
            borderRight: `${arrowSizeString} solid transparent`,
            borderTop: `${arrowSizeString} solid ${resolvedColor}`,
            bottom: `-${resolvedOffset}em`,
            left: `${arrow.offset}%`,
            transform: `translate3d(-50%, 0, 0)`,
        },
        left: {
            borderTop: `${arrowSizeString} solid transparent`,
            borderBottom: `${arrowSizeString} solid transparent`,
            borderRight: `${arrowSizeString} solid ${resolvedColor}`,
            top: `${arrow.offset}%`,
            left: `-${resolvedOffset}em`,
            transform: 'translate3d(0, -50%, 0)',
        },
    };

    computedStyles.push({
        selector: '',
        styles: {
            borderRadius: styler(border.radius, 0.1, 'em', ''),
            backgroundColor: background || 'transparent',
        },
    });
    if (arrow.enabled) {
        computedStyles.push({
            selector: '.widget_inner:after',
            styles: {
                content: `''`,
                position: 'absolute',
                ...arrowMap?.[arrow.placement],
            },
        });
    }

    if (shadowColor && shadow?.enabled) {
        computedStyles.push({
            selector: '',
            styles: {
                boxShadow: `${shadow?.blur * 0.01}em ${shadow?.blur * 0.01}em ${shadow?.blur *
                    0.1}em ${shadowColor}`,
            },
        });
    }

    if (!inheritFontCase) {
        computedStyles.push({
            selector: '.custom_content_component',
            styles: {
                textTransform: 'inherit',
            },
        });
    }

    const richTextComputedStyles = customContentRichTextOptionStyles(options, theme, mergedTheme);
    return [...computedStyles, ...richTextComputedStyles];
};
