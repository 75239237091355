import {
    ComputedStyle,
    getPostMergedColors,
    imageFilterStyles,
    styler,
} from 'utils/GenerateStyles/GenerateStyles';
import { Theme } from 'models/Theme/Theme';
import merge from 'deepmerge';
import { BrandColors } from '../../stores/PanelTemplatesStore/PanelTemplatesTheme/constants';
import Color from 'color';
import { ReviewOptions } from '@sprinklr/stories/widget/types';
import { GetStoryCardComputedStyles } from './StoryCard/GetStoryCardComputedStyles';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';

export const postOptionStyles = (options, theme, inheritedTheme, measurements?) => {
    const merged = getPostMergedColors(theme, inheritedTheme);
    const mergedTheme: Theme = merge(theme, inheritedTheme);

    const {
        imageTextColor,
        imageFilterBlur,
        imageFilterBrightness,
        imageFilterContrast,
        imageFilterGrayscale,
        imageFilterSaturate,
        imageFilterHueRotate,
        imageFilterOpacity,
        imageFilterInvert,
        imageFilterSepia,
        profileDescriptionSize,
        engagementMetricsSize,
        adsMetricsSize,
        userGroupAvatarSize,
        userGroupNameSize,
        userGroupScreenNameSize,
        userGroupIconSize,
        timeStampSize,
        textOnlyColor,
        postTextOnlySize,
        postImageTextSize,
        postLineClamp,
        imagePostLineClamp,
        imageBlendMode,
        hashtagTextColor,
        hashtagBackgroundColor,
        mentionTextColor,
        mentionBackgroundColor,
        urlTextColor,
        showIconInUserGroup,
        socialIconPosition,
        urlBackgroundColor,
        secondaryOverlayColor,
        showPostTextOverlayAsGradient,
        showSecondaryOverlayColorInUser,
        userGroupGradientEnabled,
        userGroupGradientColor,
        userGroupGradientDirection,
        postVersion,
        commentTextSize,
        reviews,
        postTitleOptions,
        comments,
        customFields,
        spacing,
        padding,
        extraPostDataOptions,
        nativeStyling,
        rank,
        gridLayout,
        showReplyLabelsSize,
        replyBorderColor,
        showReplyBorder,
        location,
        postTemplate,
        retweetIndicator,
        storyOptions,
    } = options;

    let textOnlyBackgroundColor: string;
    if (postVersion === 1 && options.textOnlyBackgroundColor === '') {
        textOnlyBackgroundColor = BrandColors.lightBlue;
    } else if (options.textOnlyBackgroundColor !== '') {
        textOnlyBackgroundColor = options.textOnlyBackgroundColor;
    } else {
        textOnlyBackgroundColor = merged.textOnlyBackgroundColor;
    }
    const imagePostBackgroundColor =
        options.imagePostBackgroundColor && options.imagePostBackgroundColor !== ''
            ? options.imagePostBackgroundColor
            : merged.imagePostBackgroundColor;
    const imageBlendColor =
        options.imageBlendColor !== '' || postVersion === 1
            ? options.imageBlendColor
            : merged.imageBlendColor;
    const computedStyles: ComputedStyle[] = [];

    const triangleBorderWidth = () => {
        let styles = '';
        const borderWidth = `${userGroupIconSize * 0.7}em`;
        if (socialIconPosition.y === 'top' && socialIconPosition.x === 'left') {
            styles += `${borderWidth} ${borderWidth} 0 0`;
        } else if (socialIconPosition.y === 'top' && socialIconPosition.x === 'right') {
            styles += `0 ${borderWidth} ${borderWidth} 0`;
        } else if (socialIconPosition.y === 'bottom' && socialIconPosition.x === 'left') {
            styles += `${borderWidth} 0 0 ${borderWidth}`;
        } else if (socialIconPosition.y === 'bottom' && socialIconPosition.x === 'right') {
            styles += `0 0 ${borderWidth} ${borderWidth}`;
        }
        return styles;
    };

    if (
        imageFilterBlur ||
        imageFilterBrightness ||
        imageFilterContrast ||
        imageFilterGrayscale ||
        imageFilterSaturate ||
        imageFilterHueRotate ||
        imageFilterOpacity ||
        imageFilterInvert ||
        imageFilterSepia
    ) {
        computedStyles.push(
            {
                selector: '.post_image',
                styles: {
                    filter: imageFilterStyles(options),
                },
            },
            {
                selector: '.post_inline_image',
                styles: {
                    filter: imageFilterStyles(options),
                },
            },
            {
                selector: '.post_video_container iframe',
                styles: {
                    filter: imageFilterStyles(options),
                },
            },
            {
                selector: '.post_video_container .post_video',
                styles: {
                    filter: imageFilterStyles(options),
                },
            },
            {
                selector: '.video_placeholder',
                styles: {
                    filter: imageFilterStyles(options),
                },
            }
        );
    }

    if (imageBlendColor && imageBlendMode !== null && imageBlendColor && imageBlendMode !== '') {
        computedStyles.push({
            selector: '.post_image_overlay',
            styles: {
                display: 'block',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                position: 'absolute',
                backgroundColor: imageBlendColor,
                mixBlendMode: imageBlendMode,
            },
        });
    }

    if (secondaryOverlayColor) {
        computedStyles.push(
            {
                selector: '.post_text_overlay',
                styles: {
                    backgroundColor: secondaryOverlayColor,
                },
            },
            {
                selector: '.post_image_secondary_overlay',
                styles: {
                    backgroundColor: secondaryOverlayColor,
                },
            }
        );

        if (showPostTextOverlayAsGradient) {
            const start = Color(secondaryOverlayColor)
                .fade(100)
                .string();

            computedStyles.push({
                selector: '.post_text_overlay_gradient',
                styles: {
                    background: `linear-gradient(to bottom, ${start}, ${secondaryOverlayColor})`,
                },
            });

            if (showSecondaryOverlayColorInUser) {
                computedStyles.push({
                    selector: '.post_user_container',
                    styles: {
                        background: `linear-gradient(to top, ${start}, ${secondaryOverlayColor})`,
                    },
                });
            }
        }
    }

    if (userGroupGradientEnabled) {
        const start = Color(userGroupGradientColor)
            .fade(100)
            .string();

        let direction;
        switch (userGroupGradientDirection) {
            case 'topToBottom':
                direction = 'top';
                break;
            case 'bottomToTop':
                direction = 'bottom';
            default:
                break;
        }

        const thing = `to ${direction}, ${start}, ${userGroupGradientColor}`;
        computedStyles.push(
            {
                selector: '.post_has_image .user_group_gradient',
                styles: {
                    background: `linear-gradient(${thing})`,
                },
            },
            {
                selector: '.post_has_video .user_group_gradient',
                styles: {
                    background: `linear-gradient(${thing})`,
                },
            }
        );
    }

    if (hashtagTextColor || hashtagBackgroundColor) {
        computedStyles.push({
            selector: '.post_text .text_entity_hashtag',
            styles: {
                color: hashtagTextColor,
                backgroundColor: hashtagBackgroundColor,
            },
        });
    }

    if (postTitleOptions?.enabled) {
        computedStyles.push({
            selector: '.post_title',
            styles: {
                fontSize: styler(postTitleOptions.size, 0.1, 'em', ''),
                color: !!postTitleOptions.color ? postTitleOptions.color : merged.textColor,
            },
        });
    }

    if (reviews && reviews.enabled) {
        const { title, rating, product, message, domain } = reviews as ReviewOptions;

        if (message && message.enabled) {
            computedStyles.push({
                selector: '.post_text_review',
                styles: {
                    fontSize: styler(message.size, 0.1, 'em', ''),
                    color: !!message.color ? message.color : merged.textColor,
                },
            });
        }

        if (title && title.enabled) {
            computedStyles.push({
                selector: '.review_title',
                styles: {
                    fontSize: styler(title.size, 0.1, 'em', ''),
                    color: !!title.color ? title.color : merged.textColor,
                },
            });
        }

        if (domain && domain.enabled) {
            computedStyles.push({
                selector: '.review_domain',
                styles: {
                    fontSize: styler(domain.size, 0.1, 'em', ''),
                    color: !!domain.color ? domain.color : merged.textColor,
                },
            });
        }

        if (product && product.enabled) {
            computedStyles.push({
                selector: '.review_product_title',
                styles: {
                    fontSize: styler(product.size, 0.1, 'em', ''),
                    color: !!product.color ? product.color : merged.textColor,
                },
            });
        }

        if (rating && rating.enabled) {
            computedStyles.push(
                {
                    selector:
                        '.post_text_only .review_rating_filled .icon, .review_rating_partial_fill',
                    styles: {
                        fill: !!rating.filledColor ? rating.filledColor : merged.textColor,
                    },
                },
                {
                    selector:
                        '.post_has_image .review_rating_filled .icon, .review_rating_partial_fill',
                    styles: {
                        fill: !!rating.filledColor ? rating.filledColor : imageTextColor,
                    },
                },
                {
                    selector: '.review_rating_empty .icon, .review_rating_partial_empty',
                    styles: {
                        fill: rating.emptyColor ? rating.emptyColor : '#373741',
                        mixBlendMode: rating.emptyColor ? 'inherit' : 'difference',
                    },
                }
            );
        }
    }

    if (comments && comments.enabled) {
        const { background, comment } = comments;

        if (background && background.color) {
            computedStyles.push({
                selector: '.post_has_image.post_has_comments .post_text_container',
                styles: {
                    paddingTop: '1em',
                },
            });
        }

        // comment section
        if (background && background.color) {
            computedStyles.push({
                selector: '.post_comments-container',
                styles: {
                    backgroundColor: background.color,
                },
            });
        }

        // individual comments
        if (comment.background && !comment.background.color) {
            computedStyles.push(
                {
                    selector: comment.background.brandEnabled
                        ? '.post_text_only .post_comment'
                        : '.post_text_only .post_comment:not(.post_comment_brand)',
                    styles: {
                        backgroundColor: merged.textOnlyBackgroundColor,
                    },
                },
                {
                    selector: comment.background.brandEnabled
                        ? '.post_has_image .post_comment'
                        : '.post_has_image .post_comment:not(.post_comment_brand)',
                    styles: {
                        backgroundColor: merged.imagePostBackgroundColor,
                    },
                },

                {
                    selector: comment.background.brandEnabled
                        ? '.post_has_video .post_comment'
                        : '.post_has_video .post_comment:not(.post_comment_brand)',
                    styles: {
                        backgroundColor: merged.imagePostBackgroundColor,
                    },
                }
            );
        }

        if (comment.background && comment.background.color) {
            computedStyles.push({
                selector: comment.background.brandEnabled
                    ? '.post_comment'
                    : '.post_comment:not(.post_comment_brand)',
                styles: {
                    backgroundColor: comment.background.color,
                },
            });
        }

        if (comment.text && comment.text.color) {
            computedStyles.push({
                selector: '.post_comments-container .post_comment .post_text',
                styles: {
                    color: comment.text.color,
                },
            });
        }

        if (comment.text && (comment.text.size === 0 || comment.text.size)) {
            computedStyles.push({
                selector: '.post_comments-container .post_comment .post_text',
                styles: {
                    fontSize: styler(comment.text.size, 0.1, 'em', ''),
                },
            });
        }

        if (comment.text && comment.text.scale.enabled) {
            computedStyles.push(
                {
                    selector: '.post_comments-container .post_comment .post_text',
                    styles: {
                        fontSize: styler(
                            comment.text.size * (comment.text.scale.value * 0.01),
                            0.1,
                            'em',
                            ''
                        ),
                    },
                },
                {
                    selector: '.post_has_comments .post_inner .post_text',
                    styles: {
                        fontSize: styler(
                            postImageTextSize * (comment.text.scale.value * 0.01),
                            0.1,
                            'em',
                            ''
                        ),
                    },
                },
                {
                    selector:
                        '.post_has_comments .engagement_metrics_container .engagement_metric .icon__container',
                    styles: {
                        width: styler(
                            engagementMetricsSize * (comment.text.scale.value * 0.01),
                            0.1,
                            'em',
                            ''
                        ),
                        height: styler(
                            engagementMetricsSize * (comment.text.scale.value * 0.01),
                            0.1,
                            'em',
                            ''
                        ),
                    },
                },
                {
                    selector:
                        '.post_has_comments .engagement_metrics_container .engagement_metric_number',
                    styles: {
                        fontSize: styler(
                            engagementMetricsSize * (comment.text.scale.value * 0.01),
                            0.1,
                            'em',
                            ''
                        ),
                    },
                }
            );
        }

        if (
            comment.userGroup &&
            comment.userGroup.timeStamp &&
            (comment.userGroup.timeStamp.size || comment.userGroup.timeStamp.size === 0)
        ) {
            computedStyles.push({
                selector: '.post_comment .post_time_stamp',
                styles: {
                    fontSize: styler(comment.userGroup.timeStamp.size, 0.1, 'em', ''),
                },
            });
        }
    }

    if (mentionTextColor || mentionBackgroundColor) {
        computedStyles.push({
            selector: '.post_text .text_entity_screenName',
            styles: {
                color: mentionTextColor,
                backgroundColor: mentionBackgroundColor,
            },
        });
    }

    if (urlTextColor || urlBackgroundColor) {
        computedStyles.push({
            selector: '.post_text .text_entity_url',
            styles: {
                color: urlTextColor,
                backgroundColor: urlBackgroundColor,
            },
        });
    }

    if (userGroupAvatarSize === 0 || userGroupAvatarSize) {
        computedStyles.push(
            {
                selector: '.profile_lockup_inner .avatar',
                styles: {
                    fontSize: styler(userGroupAvatarSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.post_user_container .post_avatar',
                styles: {
                    fontSize: styler(userGroupAvatarSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.post_avatar',
                styles: {
                    fontSize: styler(userGroupAvatarSize, 0.1, 'em', ''),
                },
            }
        );
    }

    if (userGroupNameSize === 0 || userGroupNameSize) {
        computedStyles.push(
            {
                selector: '.profile_info .user_full_name',
                styles: {
                    fontSize: styler(userGroupNameSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.post_user_container .user_full_name',
                styles: {
                    fontSize: styler(userGroupNameSize, 0.1, 'em', ''),
                },
            }
        );
    }

    if (userGroupScreenNameSize === 0 || userGroupScreenNameSize) {
        computedStyles.push(
            {
                selector: '.profile_info .user_screen_name',
                styles: {
                    fontSize: styler(userGroupScreenNameSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.post_user_container .user_screen_name',
                styles: {
                    fontSize: styler(userGroupScreenNameSize, 0.1, 'em', ''),
                },
            }
        );
    }

    if (customFields && (customFields.size || customFields.size === 0)) {
        computedStyles.push(
            {
                selector: '.profile_info .user_property',
                styles: {
                    fontSize: styler(customFields.size, 0.1, 'em', ''),
                },
            },
            {
                selector: '.post_user_container .user_property',
                styles: {
                    fontSize: styler(customFields.size, 0.1, 'em', ''),
                },
            }
        );
    }

    if (userGroupIconSize === 0 || userGroupIconSize) {
        computedStyles.push(
            {
                selector: '.profile_lockup_inner .icon__container',
                styles: {
                    fontSize: styler(userGroupIconSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.post_user_container .icon__container',
                styles: {
                    fontSize: styler(userGroupIconSize, 0.1, 'em', ''),
                },
            }
        );
    }

    if (profileDescriptionSize === 0 || profileDescriptionSize) {
        computedStyles.push({
            selector: '.profile_lockup .profile_description',
            styles: {
                fontSize: styler(profileDescriptionSize, 0.1, 'em', ''),
            },
        });
    }

    if (timeStampSize === 0 || timeStampSize) {
        computedStyles.push({
            selector: '.post_time_stamp',
            styles: {
                fontSize: styler(timeStampSize, 0.075, 'em', ''),
            },
        });
    }

    if (location?.size) {
        computedStyles.push({
            selector: '.post_location',
            styles: {
                fontSize: styler(location.size, 0.075, 'em', ''),
            },
        });
    }

    if (engagementMetricsSize === 0 || engagementMetricsSize) {
        computedStyles.push(
            {
                selector: '.engagement_metrics_container .engagement_metric .icon__container',
                styles: {
                    width: styler(engagementMetricsSize, 0.1, 'em', ''),
                    height: styler(engagementMetricsSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.engagement_metrics_container .engagement_metric_number',
                styles: {
                    fontSize: styler(engagementMetricsSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.inline_post .engagement_metric',
                styles: {
                    marginRight: styler(engagementMetricsSize, 0.1, 'em', ''),
                },
            }
        );
    }

    if (adsMetricsSize === 0 || adsMetricsSize) {
        computedStyles.push(
            {
                selector: '.ads_metrics_container .ads_metric .icon__container',
                styles: {
                    width: styler(adsMetricsSize, 0.1, 'em', ''),
                    height: styler(adsMetricsSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.ads_metrics_container .ads_metric_number',
                styles: {
                    fontSize: styler(adsMetricsSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.inline_post .ads_metric',
                styles: {
                    marginRight: styler(adsMetricsSize, 0.1, 'em', ''),
                },
            }
        );
    }

    if (textOnlyColor || postTextOnlySize === 0 || postTextOnlySize) {
        computedStyles.push({
            selector: '.post_text_only .post_text:not(.post_text-comment):not(.post_text_review)',
            styles: {
                color: textOnlyColor,
                fontSize: styler(postTextOnlySize, 0.1, 'em', ''),
            },
        });
    }

    if (textOnlyBackgroundColor) {
        computedStyles.push({
            selector: '.post_text_only',
            styles: {
                backgroundColor: textOnlyBackgroundColor,
            },
        });
    }

    if (imagePostBackgroundColor) {
        computedStyles.push(
            {
                selector: '.post_has_image',
                styles: {
                    backgroundColor: imagePostBackgroundColor,
                },
            },
            {
                selector: '.post_has_video',
                styles: {
                    backgroundColor: imagePostBackgroundColor,
                },
            },
            {
                selector: '.post_template_collage .post_text_only.post_index_2',
                styles: {
                    backgroundColor: imagePostBackgroundColor,
                },
            }
        );
    }

    if (commentTextSize === 0 || commentTextSize) {
        computedStyles.push({
            selector: '.post_text-comment',
            styles: {
                fontSize: styler(commentTextSize, 0.1, 'em', ''),
            },
        });
    }

    if (postImageTextSize === 0 || postImageTextSize) {
        computedStyles.push(
            {
                selector: '.post_has_image .post_text',
                styles: {
                    fontSize: styler(postImageTextSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.post_has_video .post_text',
                styles: {
                    fontSize: styler(postImageTextSize, 0.1, 'em', ''),
                },
            }
        );
    }

    if (showReplyLabelsSize === 0 || showReplyLabelsSize) {
        computedStyles.push({
            selector: '.post_reply_header',
            styles: {
                fontSize: styler(showReplyLabelsSize, 0.1, 'em', ''),
            },
        });
    }

    if (replyBorderColor && showReplyBorder) {
        computedStyles.push({
            selector: '.post_has_reply > .post_inner',
            styles: {
                borderLeft: `4px solid ${replyBorderColor}`,
            },
        });
    }

    if (imageTextColor) {
        computedStyles.push({
            selector: '.post_has_image .post_text',
            styles: {
                color: imageTextColor,
            },
        });
    }

    if (imagePostLineClamp === false) {
        computedStyles.push(
            {
                selector: '.post_has_image .post_text',
                styles: {
                    WebkitLineClamp: 'initial',
                },
            },
            {
                selector: '.post_has_video .post_text',
                styles: {
                    WebkitLineClamp: 'initial',
                },
            }
        );
    }

    if (postLineClamp === false) {
        computedStyles.push({
            selector: '.post_text_only .post_text',
            styles: {
                WebkitLineClamp: 'initial',
            },
        });
    }

    if (userGroupIconSize === 0 || (userGroupIconSize && !showIconInUserGroup)) {
        computedStyles.push(
            {
                selector: '.corner_tear',
                styles: {
                    width: styler(userGroupIconSize, 0.7, 'em', ''),
                    height: styler(userGroupIconSize, 0.7, 'em', ''),
                },
            },
            {
                selector: '.corner_tear .icon__container',
                styles: {
                    fontSize: styler(userGroupIconSize, 0.1, 'em', ''),
                },
            },
            {
                selector: '.social_icon.icon__container',
                styles: {
                    fontSize: styler(userGroupIconSize, 0.1, 'em', ''),
                },
            }
        );
    }

    if (
        userGroupIconSize &&
        !showIconInUserGroup &&
        socialIconPosition &&
        socialIconPosition.x &&
        socialIconPosition.y
    ) {
        computedStyles.push({
            selector: '.corner_tear_triangle',
            styles: {
                borderWidth: triangleBorderWidth(),
            },
        });
    }

    if (
        userGroupIconSize &&
        !showIconInUserGroup &&
        socialIconPosition &&
        socialIconPosition.x &&
        socialIconPosition.y
    ) {
        computedStyles.push({
            selector: '.corner_tear_triangle',
            styles: {
                borderWidth: triangleBorderWidth(),
            },
        });
    }

    if (spacing) {
        computedStyles.push({
            selector: '.inline_post',
            styles: {
                padding: styler(spacing, 0.1, 'em', ''),
            },
        });
    }
    if (padding) {
        computedStyles.push({
            selector: '.inline_post .post_inner',
            styles: {
                padding: styler(padding, 0.1, 'em', ''),
            },
        });
    }

    if (extraPostDataOptions?.enabled) {
        computedStyles.push({
            selector: '.extra_post_data_item',
            styles: {
                fontSize: styler(extraPostDataOptions.size, 0.05, 'em', ''),
                color: !!nativeStyling?.enabled
                    ? 'rgba(0, 0, 0, 100)'
                    : !!extraPostDataOptions.color
                    ? extraPostDataOptions.color
                    : merged.textColor,
            },
        });
        if (extraPostDataOptions?.border?.enabled) {
            computedStyles.push(
                {
                    selector: '.extra_post_data_item',
                    styles: {
                        borderBottom: `1px solid ${
                            !!extraPostDataOptions.border.color
                                ? extraPostDataOptions.border.color
                                : (Color(merged.textColor).fade(0.5) as any)
                        }`,
                        paddingBottom: `.5em`,
                        paddingTop: `.5em`,
                    },
                },
                {
                    selector: '.extra_post_data_item:last-child',
                    styles: {
                        borderBottom: `none`,
                    },
                }
            );
        } else if (extraPostDataOptions?.border && !extraPostDataOptions.border.enabled) {
            computedStyles.push({
                selector: '.extra_post_data_item',
                styles: {
                    paddingBottom: `.25em`,
                    paddingTop: `.25em`,
                },
            });
        }
        if (extraPostDataOptions?.header?.enabled) {
            computedStyles.push({
                selector: '.extra_post_data_header',
                styles: {
                    paddingBottom: `.25em`,
                    fontSize: styler(extraPostDataOptions.header.size, 0.05, 'em', ''),
                    color: !!nativeStyling?.enabled
                        ? 'rgba(0, 0, 0, 100)'
                        : !!extraPostDataOptions.header.color
                        ? extraPostDataOptions.header.color
                        : merged.textColor,
                },
            });
        }
    }

    if (rank?.enabled) {
        computedStyles.push({
            selector: '.profile_rank',
            styles: {
                fontSize: styler(rank.size, 0.1, 'em', ''),
            },
        });
    }

    if (retweetIndicator?.enabled) {
        computedStyles.push({
            selector: '.retweet_indicator',
            styles: {
                fontSize: styler(retweetIndicator.size, 0.1, 'em', ''),
                opacity: styler(retweetIndicator.opacity, 0.01, '', ''),
            },
        });
    }

    if (gridLayout) {
        const { height, width } = measurements;
        const hasRows = !!gridLayout?.rows;
        const gridDirection =
            height > width && !hasRows ? 'gridTemplateRows' : 'gridTemplateColumns';
        computedStyles.push({
            selector: '.post_group',
            styles: {
                display: 'grid',
                gap: styler(gridLayout.gap, 0.1, 'em', ''),
                [gridDirection]: `repeat(${gridLayout.columns}, 1fr)`,
                height: '100%', // fix for safari
                gridTemplateRows: hasRows && `repeat(${gridLayout.rows}, 1fr)`,
            },
        });
    }

    const storyCardComputedStyles = storyOptions
        ? GetStoryCardComputedStyles({
              storyOptions,
              mergedTheme,
          })
        : [];

    return [...computedStyles, ...storyCardComputedStyles];
};

export const getPostCss: GetWidgetTypeStyles = (
    mergedTheme: Theme,
    dataSet,
    widget,
    cssPrefix: string
) => {
    const { typography: { color = null } = {} } = mergedTheme;

    return `${cssPrefix} .engagement_metric .icon, ${cssPrefix} .post_channel_icon .icon, ${cssPrefix} .auto_alert_header .icon {fill: ${color}}`;
};
