import { observable } from 'mobx';
import { SpikesOptions } from './types';

export class SpikesImpl implements SpikesOptions {
    @observable enabled = false;
    @observable maxLabelWidth = 150;
    @observable marker = {
        enabled: true,
        padding: 10,
        size: 50,
        glow: {
            size: 10,
            enabled: true,
            opacity: 40,
        },
        innerSizeRatio: 0.6,
        animation: {
            enabled: true,
            // we can add more animation options here
        },
    };

    @observable value = {
        enabled: true,
        formats: {
            numberFormat: {
                decimals: 0,
                useShortened: true,
            },
            currency: '',
        },
        size: 14,
    };
    @observable date = {
        enabled: false,
        // need to determine how we want to add time ago support here
        dateFormat: {
            autoFormat: true,
            formatString: 'dd MMM',
        },
        size: 14,
    };
    @observable label = {
        enabled: false,
        size: 14,
    };
}
