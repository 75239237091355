import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { observable } from 'mobx';
import { HyperSpaceTreeMapWidgetOptions } from './types';
import { MetricGroupOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import { MetaInfoOptionsImpl } from '../options';

export class HyperSpaceTreeMapWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceTreeMapWidgetOptions {
    @observable metricGroupOptions: MetricGroupOptions = {
        enabled: true,
        disablePercentChangeOption: false,
        metric: {
            size: 35,
            enabled: true,
            format: {
                decimals: 2,
                useShortened: true,
            },
        },
        metricName: {
            size: 20,
            enabled: true,
        },
        percentChange: {
            size: 20,
            enabled: false,
        },
    };
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
    @observable node = {
        label: {
            enabled: true,
            size: 30,
            format: 'default' as HyperSpaceTreeMapWidgetOptions['node']['label']['format'],
            clamp: 2,
        },
        padding: 10,
        margin: 10,
    };
}
