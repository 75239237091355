import { WordCloudWord } from 'components/_charts/WordCloud/WordCloud';
import { WordCloudWidgetOptions, WordCloudWidgetOptionsImpl } from './options';
import { Theme } from 'models/Theme/Theme';
import { generateColorPalette } from 'utils/GenerateColors/GenerateColors';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { ComputedStyle, computedStyleToString } from 'utils/GenerateStyles/GenerateStyles';
import { GenerateTransitionStyles } from 'utils/GenerateTransitionStyles/GenerateTransitionStyles';
import { FontSizeMapper } from './types';

export const generateWordCloudStyles = (
    data: WordCloudWord[],
    options: WordCloudWidgetOptions & WordCloudWidgetOptionsImpl,
    mergedTheme: Theme,
    theme: Theme
) => {
    const { negativeSentimentColor, showSentiment, useThemeColor } = options;
    const output = [];

    if (!mergedTheme || !data.length) {
        return output;
    }

    data.forEach((word, i) => {
        output.push({
            selector: `.word_index_${i + 1}`,
            styles: {
                fill: colorMapper(
                    word,
                    data,
                    generateColorPalette(mergedTheme, data.length, false, theme),
                    showSentiment,
                    useThemeColor
                ),
            },
        });
    });

    if (negativeSentimentColor) {
        const sentimentStyles = {
            selector: `.word_value_negative`,
            styles: {
                fill: negativeSentimentColor,
            },
        };
        output.push(sentimentStyles);
    }
    return output;
};

export const colorMapper = (word, data, colors, showSentiment, useThemeColor) => {
    if (!colors || !data || !word) {
        return;
    }

    if (useThemeColor && word.label && word.label.color) {
        return word.label.color;
    }
    if (showSentiment && word.negativeColor) {
        // if the sentiment is negative it will have a 'negativeColor' value
        return word.negativeColor;
    } else {
        const index = data.indexOf(word) + 1;
        if (index < colors.length) {
            return colors[index];
        } else {
            return colors[colors.length - 1];
        }
    }
};

const excludedEmojis = ['™️', '©️', '®️', '©', '®', '™'];

export const transformWordCloudData = (
    dataSet: DataSet,
    options: WordCloudWidgetOptionsImpl & WordCloudWidgetOptions
): WordCloudWord[] => {
    let output = [];
    const { maxWords, negativeSentimentColor, blacklist } = options;
    if (dataSet) {
        const defaultNegativeColor = '#B33A3A';
        const negativeColor = negativeSentimentColor || defaultNegativeColor;

        dataSet = dataSet.filterRowsData();

        output = dataSet.rows
            .filter(row => {
                return blacklist && blacklist.indexOf(row[0]) === -1;
            })
            .filter(row => {
                return excludedEmojis.indexOf(row[0]) === -1;
            })
            .map(
                (row, i: number): WordCloudWord => {
                    const total: number = row[1] || 0;
                    const label = row[0] || '';

                    if (row.length > 3) {
                        const negative: number = row[3] || 0;
                        const sentiment: number = !total ? 0 : 0.5 - negative / total; // DAN'S HACK
                        return {
                            label,
                            value: total,
                            sentiment:
                                sentiment < 0 ? 'negative' : sentiment > 0 ? 'positive' : 'neutral',
                            negativeColor: sentiment < 0 ? negativeColor : null,
                            index: i + 1,
                        };
                    } else {
                        return {
                            label,
                            value: total,
                            index: i + 1,
                        };
                    }
                }
            )
            .sort((a, b) => b.value - a.value)
            .slice(0, maxWords);
    }

    return output;
};

export const getWordCloudCss: GetWidgetTypeStyles = (
    mergedTheme: Theme,
    dataSet: DataSet,
    widget,
    prefix?: string
): string => {
    let output = '';

    if (!mergedTheme || !dataSet || !widget.options) {
        return output;
    }
    const { animationDelay, animationLength } = widget.options;
    const data = transformWordCloudData(
        dataSet,
        widget.options as WordCloudWidgetOptions & WordCloudWidgetOptionsImpl
    );
    const styles = generateWordCloudStyles(
        data,
        widget.options as WordCloudWidgetOptions & WordCloudWidgetOptionsImpl,
        mergedTheme,
        widget.theme
    );

    const prefixConfig = { prefix };
    styles &&
        styles.map((style: ComputedStyle) => {
            output += computedStyleToString(style, prefixConfig);
        });

    output += GenerateTransitionStyles(
        '.word',
        undefined,
        data.length,
        animationLength,
        animationDelay,
        prefix
    );
    return output;
};

export const fontSizeMapper: FontSizeMapper = (word, data, minFontSize, maxFontSize): number => {
    const valueMap = data.map(w => w.value);
    const minSize = Math.min(...valueMap);
    const maxSize = Math.max(...valueMap);
    const divisor = maxSize - minSize;

    if (divisor === 0) {
        // avoid div-by-zero and subsequent NaN font sizes when all values are the same.
        return minFontSize;
    }
    return minFontSize + ((maxFontSize * 3 - minFontSize * 3) * (word.value - minSize)) / divisor;
};
