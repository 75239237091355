import BulkItem from './BulkItem';

export default class AccountItem extends BulkItem {
    constructor(
        id: string = null,
        public accountId: string = null,
        public displayName: string = null,
        public screenName: string = null,
        public snType: string = null,
        public accountUrl: string = null,
        public profileImgUrl: string = null
    ) {
        super(id);
    }

    public static fromObject(object: any): AccountItem {
        if ('WEB' === object.snType && !object.displayName) {
            object.displayName = object.screenName;
        }

        return new AccountItem(
            object.id,
            object.accountId,
            object.displayName,
            object.screenName,
            object.snType,
            object.accountUrl,
            object.profileImgUrl
        );
    }

    toString() {
        if (this.displayName) {
            return this.displayName;
        }

        if (this.screenName) {
            return this.screenName;
        }

        return this.snType;
    }

    get sortValue() {
        return this.toString();
    }
}
