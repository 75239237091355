import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { LabelTextFormat, SmartSize } from '@sprinklr/stories/widget/types';
import { observable } from 'mobx';
import { FontCase } from 'models/Theme/Theme';
import { DecimalFormat } from 'utils/Number/NumberUtils';

export interface BubbleChartWidgetOptions extends WidgetOptions {
    padding?: number;
    highlightPercentChange?: boolean;

    labelSize?: number;
    showMetricValue?: boolean;
    showMetricName?: boolean;
    showMetricChange?: boolean;
    blacklist?: string[];
}

export class BubbleChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements BubbleChartWidgetOptions {
    @observable labelSize = 25;
    @observable padding = 30;

    @observable showMetricName = true;
    @observable metricNameSize = 15;
    @observable metricNameCase: FontCase = 'lowercase';

    @observable showMetricValue = true;
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';
    @observable metricValueSize = 48;

    @observable showMetricChange = true;
    @observable metricPercentChangeSize = 20;
    @observable highlightPercentChange: boolean;

    @observable showTimeRemainder = true;

    @observable zoom = 65;
    @observable offsetX = 0;
    @observable offsetY = 0;

    // label options
    @observable labelTextFormat: LabelTextFormat = 'clamp';
    @observable labelLineClamp = true;
    @observable labelLineClampValue = 1;
    @observable labelWrapText = false;
    @observable textFitMin = 20;

    // child color stuff
    @observable childOpacity = 20;
    @observable childContrast = 50;
    @observable childVibrance = 0;
    @observable childBlendMode: string | null = null;

    // child duplicates
    @observable childLabelSize = 8;
    @observable showChildMetricValue = true;
    @observable childMetricValueSize = 24;
    @observable childMetricValueFormat: DecimalFormat = '1k';
    @observable showChildMetricName = true;
    @observable childMetricNameSize = 5;
    @observable showChildMetricChange = false;

    @observable childLabelTextFormat: LabelTextFormat = 'fit';
    @observable childLabelLineClamp = true;
    @observable childLabelLineClampValue = 1;
    @observable childLabelWrapText = false;
    @observable childTextFitMin = 5;

    @observable blacklist: string[] = [];
    @observable labelEnable = true;

    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            labelSize: 25,
            padding: 30,
            metricNameSize: 15,
            metricValueSize: 48,
            metricPercentChangeSize: 20,
        },
        optionDefaults: {
            showMetricValue: true,
            showMetricName: true,
            labelEnable: true,
        },
        breakpoints: {
            xs: {
                factor: 1,
                options: {
                    showMetricValue: false,
                    showMetricName: false,
                    labelEnable: false,
                },
            },
            s: {
                factor: 1,
                options: {
                    showMetricValue: false,
                    showMetricName: false,
                },
            },
            m: {
                factor: 1,
            },
            ml: {
                factor: 1,
            },
            l: {
                factor: 1,
            },
            xl: {
                factor: 1,
            },
        },
    };
}
