import { BubbleArrow, SpeechBubbleWidgetOptions } from './types';
import { CustomContentRichTextOptionsImpl } from '../CustomContentWidget/options';
import { observable } from 'mobx';

export class SpeechBubbleWidgetOptionsImpl extends CustomContentRichTextOptionsImpl
    implements SpeechBubbleWidgetOptions {
    @observable background = '';
    @observable border = {
        enabled: true,
        color: '',
        size: 5,
        radius: 15,
    };
    @observable shadow = {
        enabled: false,
        color: '',
        blur: 50,
    };
    @observable arrow: BubbleArrow = {
        enabled: true,
        size: 15,
        placement: 'bottom',
        offset: 50,
    };
    @observable inheritFontCase = true;
}
