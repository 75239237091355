import { borderStyles, ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';
import merge from 'lodash/merge';
import { toJS } from 'mobx';

import { sanitizeString } from 'utils/NormalizeLabel/NormalizeLabel';

const INCREASE_KEY = 'percent_change_increase';
const DECREASE_KEY = 'percent_change_decrease';
const NEUTRAL_KEY = 'percent_change_neutral';

export const rankedTableOptionStyles = (
    options,
    mergedTheme: Theme,
    theme: Theme,
    inheritedTheme: Theme
) => {
    const fullyMergedTheme = merge(toJS(mergedTheme), toJS(theme));

    const keyedOverrides = fullyMergedTheme?.colorPalette?.overrides.reduce(
        (prev, curr) => ({
            ...prev,
            [sanitizeString(curr.label)]: curr.color,
        }),
        {}
    );

    const {
        columnNameSize,
        rowSize,
        showRowSize,
        dimensionSize,
        borders,
        percentChangeColor,
    } = options;

    const fallBackColor = theme.typography.color || mergedTheme.typography.color;
    const { top, bottom, cell } = borders;

    const themeColor = theme?.typography?.color;
    const inheritedThemeColor = inheritedTheme?.typography?.color;

    const textColor = themeColor || inheritedThemeColor;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.column_name',
            styles: {
                fontSize: styler(columnNameSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.dimension_size',
            styles: {
                fontSize: styler(dimensionSize, 0.1, 'em', ''),
            },
        },
    ];

    if (showRowSize) {
        computedStyles.push({
            selector: '.row_name_group',
            styles: {
                lineHeight: styler(rowSize, 0.1, 'em', ''),
                paddingTop: styler(rowSize, 0.025, 'em', ''),
                paddingBottom: styler(rowSize, 0.025, 'em', ''),
            },
        });

        computedStyles.push({
            selector: '.cell',
            styles: {
                paddingTop: styler(rowSize, 0.025, 'em', ''),
                paddingBottom: styler(rowSize, 0.025, 'em', ''),
            },
        });
        if (top.enabled && !cell?.enabled) {
            computedStyles.push({
                selector: '.ranked_table .row_key',
                styles: {
                    borderBottom: `${top.size * 0.1}px solid ${top.color || fallBackColor}`,
                },
            });
        }
        if (bottom.enabled && !cell?.enabled) {
            computedStyles.push({
                selector: '.ranked_table .row.last_row',
                styles: {
                    borderBottom: `${bottom.size * 0.1}px solid ${bottom.color || fallBackColor}`,
                },
            });
        }
    }
    if (top?.enabled && !cell?.enabled) {
        computedStyles.push({
            selector: '.ranked_table .row_key',
            styles: {
                borderBottom: `${top.size * 0.1}px solid ${top.color || fallBackColor}`,
            },
        });
    }
    if (bottom?.enabled && !cell?.enabled) {
        computedStyles.push({
            selector: '.ranked_table .row.last_row',
            styles: {
                borderBottom: `${bottom.size * 0.1}px solid ${bottom.color || fallBackColor}`,
            },
        });
    }
    if (cell?.enabled) {
        computedStyles.push({
            selector: '.ranked_table .row .cell',
            styles: {
                borderRight: `${cell.size * 0.1}px solid ${cell.color || fallBackColor}`,
            },
        });
        computedStyles.push({
            selector: '.ranked_table .row .cell:first-child',
            styles: {
                borderLeft: `${cell.size * 0.1}px solid ${cell.color || fallBackColor}`,
            },
        });
        computedStyles.push({
            selector: '.ranked_table .row',
            styles: {
                borderTop: `${cell.size * 0.1}px solid ${cell.color || fallBackColor}`,
            },
        });
        computedStyles.push({
            selector: '.ranked_table .row.last_row',
            styles: {
                borderBottom: `${cell.size * 0.1}px solid ${cell.color || fallBackColor}`,
            },
        });
    }

    const resolvedIncrease =
        keyedOverrides?.[INCREASE_KEY] ?? (percentChangeColor.enabled && percentChangeColor.up);
    if (resolvedIncrease) {
        computedStyles.push({
            selector: '.metric_ratio.change_positive',
            styles: {
                color: resolvedIncrease,
                opacity: 1,
            },
        });
    }

    const resolvedDecrease =
        keyedOverrides?.[DECREASE_KEY] ?? (percentChangeColor.enabled && percentChangeColor.down);
    if (resolvedIncrease) {
        computedStyles.push({
            selector: '.metric_ratio.change_negative',
            styles: {
                color: resolvedDecrease,
                opacity: 1,
            },
        });
    }
    const resolvedZero = !!percentChangeColor.zero ? percentChangeColor.zero : textColor;
    const resolvedNeutral =
        keyedOverrides?.[NEUTRAL_KEY] ?? (percentChangeColor.enabled && resolvedZero);
    if (resolvedNeutral) {
        computedStyles.push({
            selector: '.metric_ratio:not(.change_negative):not(.change_positive)',
            styles: {
                color: resolvedNeutral,
            },
        });
    }

    return computedStyles;
};

export const getRankedTableCss: GetWidgetTypeStyles = (mergedTheme: Theme, dataSet, widget) => {
    const { typography: { color = null } = {} } = mergedTheme;
    const borderStyle = borderStyles(color);
    const shouldRenderBorders = !widget.options?.borders?.cell?.enabled;
    return shouldRenderBorders
        ? `.ranked_table .row, .ranked_table .row_key {border-bottom: ${borderStyle}}`
        : '';
};
