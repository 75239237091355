import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { observable } from 'mobx';
import { HeaderDateProps } from 'components/storyboard/StoryboardHeaderFooter/Header/HeaderDate/types';

export interface DynamicTitleWidgetOptions extends WidgetOptions {
    fontSize: number;
    color?: string;
    useSourceAsTitle?: boolean;
    dataSourceNames?: any;
    sourceName?: string;
    useStoryboardNameAsTitle?: boolean;
    inputTitleText?: string;
    date?: HeaderDateProps;
}

export class DynamicTitleWidgetOptionsImpl extends WidgetOptionsImpl {
    @observable fontSize = 40;
    @observable inputTitleText: '';
    @observable useSourceAsTitle = true;
    @observable useGlobalDataSources = true;
    @observable useStoryboardNameAsTitle = false;
    @observable date = {
        enabled: true,
        color: '',
        size: 25,
        format: 'Pretty Time',
    };
    @observable color = '';
    @observable sourceName = '';
}
