import { NativeStylingOptionsDefaultImpl } from '../../PostsWidget/options';
import _omit from 'lodash/omit';
import { observable } from 'mobx';
import { TrendingPostOptions } from './types';

export class TrendingPostOptionsImpl implements TrendingPostOptions {
    @observable nativeStyling = _omit(new NativeStylingOptionsDefaultImpl(), 'enabled');
    @observable enabled = false;
    @observable position = {
        auto: false,
        selectedPosition: 'right',
    };
    @observable header = {
        enabled: true,
        size: 50,
        marker: {
            enabled: true,
            size: 50,
            animation: {
                enabled: true,
                // we can add more animation options here
            },
        },
    };
}
