import { observable } from 'mobx';
import { ExtraPostDataOptions, PostTextFormat } from '@sprinklr/stories/widget/types';
import { NativeStylingOptionsImpl, PostsWidgetOptions, PostsWidgetOptionsImpl } from '../options';

export class RunOfShowWidgetOptionsImpl extends PostsWidgetOptionsImpl
    implements PostsWidgetOptions {
    @observable extraPostDataOptions: ExtraPostDataOptions = {
        enabled: true,
        color: '',
        size: 35,
        value: {
            format: '1.23k',
        },
        border: {
            enabled: true,
            color: '',
        },
        header: {
            enabled: true,
            size: 50,
            text: 'Additional Details',
            color: '',
        },
        dateFormat: 'dddd, MMMM Do YYYY',
    };
    @observable showEngagementMetrics = false;
    @observable showAdsMetrics = false;
    @observable gridLayout = {
        gap: 10,
        columns: 1,
    };
    @observable textOnlyTextFormat: PostTextFormat = 'clamp';
    @observable postImageTextFormat: PostTextFormat = 'clamp';
    @observable classes = 'rel';
    @observable postLineClampValue = 7;
    @observable postTextOnlySize = 18;
    @observable postImageTextSize = 13;
    @observable postComposition = 'a';
    @observable nativeStyling = new NativeStylingOptionsImpl();
}
