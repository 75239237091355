import {
    ComputedStyle,
    fadedFontColor,
    getPostMergedColors,
} from 'utils/GenerateStyles/GenerateStyles';
import { GenerateSafeString } from 'utils/StringUtils/StringUtils';

export const geoStreamWidgetOptionStyles = (options, theme, mergedTheme) => {
    return [];
};
export const getComputedStyles = (options, theme, mergedTheme, highlight) => {
    const merged = getPostMergedColors(theme, mergedTheme);

    const { typography: { color = null } = {} } = mergedTheme || {};

    const { highlightLocation } = options;
    const highlightColor =
        highlight || highlightLocation?.color || merged.textOnlyBackgroundColor || '#000';
    const { shadow } = highlightLocation?.post || {};
    const shadowColor = shadow?.color || fadedFontColor(color);
    const { overrides = [] } = theme?.colorPalette || mergedTheme?.colorPalette || {};

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.country_label_active',
            styles: {
                fill: highlightColor,
            },
        },
        {
            selector: '.city_dot_active',
            styles: {
                fill: highlightColor,
            },
        },
        {
            selector: '.city_dot_inactive',
            styles: {
                opacity: 0,
            },
        },
        {
            selector: '.native_post > div',
            styles: {
                borderRadius: 0, // resets the default native border radius
            },
        },
        {
            selector: '.annotation_line',
            styles: {
                strokeWidth: `${highlightLocation?.line.size ?? 2}px`,
                stroke: highlightLocation?.line?.enabled ? highlightColor : 'transparent',
            },
        },
    ];

    if (highlightLocation?.post?.border?.enabled) {
        computedStyles.push({
            selector: '.ticker_post_index_1 .post',
            styles: {
                transition: 'border 300ms',
                border: `${highlightLocation?.post.border.size ?? 3}px solid transparent`,
            },
        });
        computedStyles.push({
            selector: '.has_location .ticker_post_index_1.ticker_post_ready .post',
            styles: {
                border: `${highlightLocation?.post.border.size ?? 3}px solid ${highlightColor}`,
            },
        });
        computedStyles.push({
            selector: '.has_location .ticker_post_index_1.ticker_post_ready .smart_trend',
            styles: {
                border: `${highlightLocation?.post.border.size ?? 3}px solid ${highlightColor}`,
            },
        });
    }
    if (highlightLocation?.post?.radius?.enabled) {
        computedStyles.push({
            selector: '.ticker_post .post',
            styles: {
                overflow: 'hidden',
                borderRadius: `${highlightLocation?.post?.radius.size * 0.025}em`,
            },
        });
    }

    if (shadow?.enabled) {
        computedStyles.push({
            selector: '.ticker_post .post',
            styles: {
                overflow: 'hidden',
                boxShadow: `${shadow?.size * 0.01}em ${shadow?.size * 0.01}em ${shadow?.size *
                    0.1}em ${shadowColor}`,
            },
        });
    }

    const overrideComputedStyles = [
        ...overrides.map(override => {
            return {
                selector: `.annotation_line.tag_${GenerateSafeString(override.label)}`,
                styles: {
                    stroke: highlightLocation?.line?.enabled ? override.color : 'transparent',
                },
            };
        }),
        ...overrides.map(override => {
            return {
                selector: `.country_label_active.tag_${GenerateSafeString(override.label)}`,
                styles: {
                    fill: override.color,
                },
            };
        }),
        ...overrides.map(override => {
            return {
                selector: `.city_dot_active.tag_${GenerateSafeString(override.label)}`,
                styles: {
                    fill: override.color,
                },
            };
        }),
        ...overrides.map(override => {
            return {
                selector: `.has_location .ticker_post_index_1.tag_${GenerateSafeString(
                    override.label
                )}.ticker_post_ready .post`,
                styles: {
                    border: `${highlightLocation?.post.border.size ?? 3}px solid ${override.color}`,
                },
            };
        }),
        ...overrides.map(override => {
            return {
                selector: `.has_location .ticker_post_index_1.criticality_${GenerateSafeString(
                    override.label
                )}.ticker_post_ready .smart_trend`,
                styles: {
                    border: `${highlightLocation?.post.border.size ?? 3}px solid ${override.color}`,
                },
            };
        }),
        ...overrides.map(override => {
            return {
                selector: `.criticality_${GenerateSafeString(override.label)} .criticality_tag`,
                styles: {
                    background: override.color,
                },
            };
        }),
    ];

    return [...computedStyles, ...overrideComputedStyles];
};
