import { AutoAlertsWidgetOptionsImpl } from './options';
import merge from 'lodash/merge';
import { defaultPostOptions } from 'components/_charts/AutoAlert/SourcePost';
import { toJS } from 'mobx';
import { ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';
import { postOptionStyles } from '../PostsWidget/helpers';
import { Theme } from 'models/Theme/Theme';
import ColorFn from 'color';
import { CSSProperties } from 'react';

export const alertOptionStyles = (options: AutoAlertsWidgetOptionsImpl, theme, mergedTheme) => {
    const {
        title: { size: titleSize, enabled: titleEnabled },
        altertTypeIcon: { enabled: iconEnabled },
        description: { size: descriptionSize, enabled: descriptionEnabled },
        metrics: {
            enabled: metricsEnabled,
            metric: { size: metricsSize },
            label: { size: metricsLabelSize },
        },
        pad: { enabled: paddingEnabled, size: paddingSize },
        lineChart: {
            enabled: lineChartEnabled,
            points: { size: pointMarkersSize, enabled: pointsEnabled },
            fill: { enabled: fillEnabled },
            segments: { enabled: segmentsEnabled },
            spike: {
                enabled: spikeEnabled,
                metric: { size: metricSize },
                metricName: { size: metricNameSize },
            },
            expected: {
                enabled: expectedEnabled,
                line: { size: expectedSize, style: expectedStyle },
            },
        },
        detectedTime: { size: detectedTimeSize, enabled: detectedTimeEnabled },
        sourcePost: {
            options: sourcePostOptions,
            dropshadow: { enabled: dropshadowEnabled, color: dropshadowColor },
        },
        emptyData: { enabled: dataEnabled, image, message },
    } = options;

    const { mergedLineColor, fillColorMerged, anomalyColorMerged } = getMergedLineChartColors(
        options?.lineChart,
        mergedTheme
    );

    const mergedPostOptions = merge(
        {},
        defaultPostOptions,
        toJS(sourcePostOptions, { recurseEverything: true })
    );

    const postComputedStyles: ComputedStyle[] = postOptionStyles(
        mergedPostOptions,
        theme,
        mergedTheme
    );

    const computedStyles: ComputedStyle[] = [];

    if (dataEnabled) {
        if (image.enabled) {
            computedStyles.push({
                selector: '.widget_placeholder_image',
                styles: {
                    objectFit: image.fit === '100%' ? 'none' : image.fit,
                },
            });
        }
        if (message && message.enabled) {
            if (message.size) {
                computedStyles.push({
                    selector: '.widget_placeholder_message',
                    styles: {
                        fontSize: styler(message.size, 0.1, 'em', ''),
                    },
                });
            }
            if (message.color) {
                computedStyles.push({
                    selector: '.widget_placeholder_message',
                    styles: {
                        color: message.color,
                    },
                });
            }
        }
    }

    if (paddingEnabled) {
        computedStyles.push({
            selector: '.auto_alert',
            styles: {
                padding: styler(paddingSize, 0.1, 'em', ''),
            },
        });
    }

    if (expectedEnabled) {
        computedStyles.push({
            selector: '.auto_alert .thresher--horizontal',
            styles: {
                borderBottomWidth: `${styler(expectedSize, 0.1, 'px', '')} !important`, // important to override the inline style - could avoid by moving this into the threshold component
                borderBottomStyle: `${expectedStyle} !important` as CSSProperties['borderBottomStyle'], // important to override the inline style
            },
        });
    }

    if (!segmentsEnabled) {
        computedStyles.push({
            selector: '.anomaly_segment',
            styles: {
                display: 'none',
            },
        });
    }
    if (!fillEnabled) {
        computedStyles.push({
            selector: '.anomaly_fill',
            styles: {
                display: 'none',
            },
        });
    }
    if (!pointsEnabled) {
        computedStyles.push({
            selector: '.point_anomaly .point_secondary',
            styles: {
                display: 'none',
            },
        });
    }

    if (spikeEnabled) {
        computedStyles.push(
            {
                selector: '.line_chart .line_label',
                styles: {
                    fontSize: styler(metricNameSize, 0.05, 'em', ''),
                },
            },
            {
                selector: '.line_chart .line_value .metric ',
                styles: {
                    fontSize: styler(metricSize, 0.1, 'em', ''),
                },
            }
        );
    }

    if (titleEnabled) {
        computedStyles.push({
            selector: '.auto_alert_title',
            styles: {
                fontSize: styler(titleSize, 0.1, 'em', ''),
            },
        });
    }
    if (iconEnabled) {
        computedStyles.push({
            selector: '.auto_alert_header .icon__container',
            styles: {
                fontSize: styler(titleSize, 0.15, 'em', ''),
            },
        });
    }
    if (descriptionEnabled) {
        computedStyles.push({
            selector: '.auto_alert_description',
            styles: {
                fontSize: styler(descriptionSize, 0.05, 'em', ''),
            },
        });
    }

    if (metricsEnabled) {
        computedStyles.push(
            {
                selector: '.auto_alert_metrics .metric',
                styles: {
                    fontSize: styler(metricsSize, 0.05, 'em', ''),
                },
            },
            {
                selector: '.auto_alert_metrics .label',
                styles: {
                    fontSize: styler(metricsLabelSize, 0.05, 'em', ''),
                },
            }
        );
    }

    if (detectedTimeEnabled) {
        computedStyles.push({
            selector: '.auto_alert_detected_time',
            styles: {
                fontSize: styler(detectedTimeSize, 0.05, 'em', ''),
            },
        });
    }
    if (mergedLineColor && lineChartEnabled) {
        computedStyles.push(
            {
                selector: '.auto_alert .primary_stroke, .auto_alerts_combochart path',
                styles: {
                    stroke: mergedLineColor,
                },
            },
            {
                selector: '.auto_alert .point_inner.primary_background',
                styles: {
                    backgroundColor: mergedLineColor,
                },
            }
        );
    }
    if (anomalyColorMerged && lineChartEnabled) {
        computedStyles.push(
            {
                selector: '.auto_alert .point_anomaly .point_inner.primary_background',
                styles: {
                    backgroundColor: anomalyColorMerged,
                },
            },
            {
                selector: '.auto_alert .point_anomaly_spike .point_inner.primary_background',
                styles: {
                    backgroundColor: anomalyColorMerged,
                },
            },
            {
                selector: '.auto_alert .point_anomaly_pre_spike .point_inner.primary_background',
                styles: {
                    backgroundColor: anomalyColorMerged,
                },
            },
            {
                selector: '.anomaly_segment',
                styles: {
                    stroke: anomalyColorMerged,
                    strokeWidth: '.5em',
                },
            }
        );
    }

    if (fillColorMerged && lineChartEnabled) {
        computedStyles.push(
            {
                selector: '.anomaly_fill',
                styles: {
                    fill: fillColorMerged,
                },
            },
            {
                selector:
                    '.auto_alert .point_anomaly .point_inner.primary_background.point_secondary',
                styles: {
                    backgroundColor: fillColorMerged,
                    fontSize: styler(pointMarkersSize, 0.1, 'em', ''),
                },
            }
        );
    }

    if (pointMarkersSize && lineChartEnabled) {
        computedStyles.push(
            {
                selector: '.point .point_inner',
                styles: {
                    fontSize: styler(pointMarkersSize, 0.02, 'em', ''),
                },
            },
            {
                selector: '.point.point_anomaly_spike .point_inner',
                styles: {
                    fontSize: styler(pointMarkersSize, 0.07, 'em', ''),
                },
            }
        );
    }
    if (dropshadowColor && dropshadowEnabled) {
        computedStyles.push({
            selector: '.social_embed_inner > *',
            styles: {
                boxShadow: `0.5em 0.5em 5em ${dropshadowColor}`,
            },
        });
    }

    return computedStyles.concat(postComputedStyles);
};

export const getMergedLineChartColors = (lineChartOptions, mergedTheme: Theme) => {
    if (!lineChartOptions) {
        return {
            mergedLineColor: undefined,
            fillColorMerged: undefined,
            anomalyColorMerged: undefined,
        };
    }
    const {
        line: { color: lineColor },
        fill: { color: fillColor },
    } = lineChartOptions;

    const paletteType = mergedTheme.colorPalette && mergedTheme.colorPalette.type;

    type mergedValues = {
        lineColor?: string;
        fillOverlay?: string;
        highlightPoint?: string;
    };

    const merged: mergedValues = {
        lineColor: undefined,
        fillOverlay: undefined,
        highlightPoint: undefined,
    };

    const inheritedPalette =
        paletteType && mergedTheme.colorPalette && mergedTheme.colorPalette[paletteType];

    const fillFade = 0.7;

    if (paletteType === 'colorBlend') {
        merged.lineColor = inheritedPalette.endColor;
        merged.highlightPoint = inheritedPalette.startColor;
        merged.fillOverlay = ColorFn(inheritedPalette.startColor)
            .fade(fillFade)
            .string();
    } else if (paletteType === 'monochrome') {
        const start = inheritedPalette.startColor.trim();
        merged.highlightPoint = start;
        merged.lineColor = ColorFn(start)
            .darken(inheritedPalette.endOffset * 0.01)
            .hex();
        merged.fillOverlay = ColorFn(start)
            .fade(0.5)
            .string();
    } else if (paletteType === 'solid') {
        merged.highlightPoint = ColorFn(inheritedPalette)
            .lighten(0.5)
            .hex();
        merged.lineColor = ColorFn(inheritedPalette)
            .darken(0.5)
            .hex();
        merged.fillOverlay = ColorFn(merged.highlightPoint)
            .fade(0.5)
            .string();
    } else if (paletteType === 'ranked') {
        const start = inheritedPalette.colors && inheritedPalette.colors[0].trim();
        const last =
            inheritedPalette.colors &&
            inheritedPalette.colors[inheritedPalette.colors.length - 1].trim();
        merged.highlightPoint = ColorFn(start)
            .lighten(0.5)
            .hex();
        merged.lineColor = ColorFn(last)
            .darken(0.5)
            .hex();
        merged.fillOverlay = ColorFn(start)
            .fade(0.5)
            .string();
    }

    const mergedLineColor = lineColor !== '' ? lineColor : merged.lineColor;
    const fillColorMerged =
        fillColor !== ''
            ? ColorFn(fillColor)
                  .fade(fillFade)
                  .string()
            : merged.fillOverlay;
    const anomalyColorMerged = fillColor !== '' ? fillColor : merged.highlightPoint;

    return {
        mergedLineColor,
        fillColorMerged,
        anomalyColorMerged,
    };
};
