import { ComputedStyle, computedStylesArrayToString } from 'utils/GenerateStyles/GenerateStyles';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { SmallMultiplesPieChartWidgetOptions } from './options';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';

export const getSmallMultiplesPieChartCss: GetWidgetTypeStyles = (
    mergedTheme: Theme,
    dataSet,
    widget: Widget,
    cssPrefix: string
): string => {
    const { emptyWedgeColor } = widget.options as SmallMultiplesPieChartWidgetOptions;

    const styles: ComputedStyle[] = [
        {
            selector: '.small_multiples_pie_chart .wedge_label_remainder',
            styles: {
                fill: emptyWedgeColor,
            },
        },
    ];

    return computedStylesArrayToString(styles, { prefix: cssPrefix });
};
