import { observable } from 'mobx';
import {
    AnimationValues,
    ExtraPostDataOptions,
    PostTextFormat,
} from '@sprinklr/stories/widget/types';
import { NativeStylingOptionsDefaultImpl } from '../../PostsWidget/options';
import { PostsWindowWidgetOptions, PostsWindowWidgetOptionsImpl } from '../options';

export class PostsWidgetFilmStripOptionsImpl extends PostsWindowWidgetOptionsImpl
    implements PostsWindowWidgetOptions {
    // Film strip specific
    @observable animationType: AnimationValues = 'slide_in_out';
    @observable activePostsInWindow = 3;
    @observable postLayoutType = 'a';
    @observable spacing = 20;
    @observable maxCharacters?: number = 180;
    // Text Only
    @observable postTextOnlySize = 28;
    @observable postLineClamp = true;
    @observable postLineClampValue = 10;
    @observable textOnlyTextFormat: PostTextFormat = 'clamp';
    @observable textOnlyBackgroundColor = '';
    @observable showPostTextOverlay = false;
    // Image Post
    @observable imagePostHideText = false;
    @observable postImageTextSize = 28;
    @observable postImageTextFormat: PostTextFormat = 'clamp';
    @observable imagePostLineClamp = true;
    @observable imagePostLineClampValue = 5;
    @observable imagePostBackgroundColor = '';
    @observable showSecondaryImageOverlay = false;
    @observable secondaryOverlayColor = 'rgba(0,0,0,.4)';
    // User Group
    @observable userGroupLayoutType = 'c';
    @observable userGroupAvatarSize = 30;
    @observable userGroupNameSize = 19;
    @observable userGroupScreenNameSize = 12;
    @observable showIconInUserGroup = false;
    @observable removeGenericPII = true;
    // Engagement
    @observable showEngagementMetrics = false;
    @observable engagementMetricsSize = 20;
    @observable extraPostDataOptions: ExtraPostDataOptions = {
        enabled: null,
        color: '',
        size: 20,
        mode: 'table',
        value: {
            format: '1.23k',
        },
        border: {
            enabled: true,
            color: '',
        },
        header: {
            enabled: false,
            size: 30,
            text: '',
            color: '',
        },
        dateFormat: 'dddd, MMMM Do YYYY',
    };
    @observable nativeStyling = {
        ...new NativeStylingOptionsDefaultImpl(),
        ...{ fontScale: 45 },
    };
}
