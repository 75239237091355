import { observable } from 'mobx';
import { ExtraPostDataOptions } from '@sprinklr/stories/widget/types';
import { PostTemplate } from 'components/Post/PostGroup';
import { GridLayout } from '../types';
import {
    NativeStylingOptionsDefaultImpl,
    PostsWidgetOptions,
    PostsWidgetOptionsImpl,
} from '../options';

export class PostsWidgetGridOptionsImpl extends PostsWidgetOptionsImpl
    implements PostsWidgetOptions {
    @observable extraPostDataOptions: ExtraPostDataOptions = {
        enabled: null,
        color: '',
        size: 15,
        mode: 'table',
        value: {
            format: '1.23k',
        },
        border: {
            enabled: true,
            color: '',
        },
        header: {
            enabled: false,
            size: 30,
            text: '',
            color: '',
        },
        dateFormat: 'dddd, MMMM Do YYYY',
    };
    @observable nativeStyling = new NativeStylingOptionsDefaultImpl();
    @observable quotedTweetSize?: number = 4;
}

export class PostsWidgetGridV2OptionsImpl extends PostsWidgetOptionsImpl
    implements PostsWidgetOptions {
    @observable postTemplate: PostTemplate = 'gridV2';
    @observable extraPostDataOptions: ExtraPostDataOptions = {
        enabled: null,
        color: '',
        size: 15,
        value: {
            format: '1.23k',
        },
        border: {
            enabled: true,
            color: '',
        },
        header: {
            enabled: false,
            size: 30,
            text: '',
            color: '',
        },
        dateFormat: 'dddd, MMMM Do YYYY',
    };
    @observable nativeStyling = new NativeStylingOptionsDefaultImpl();
    @observable quotedTweetSize?: number = 4;
    @observable gridLayout: GridLayout = {
        columns: 3,
        rows: 3,
        gap: 10,
    };
}
