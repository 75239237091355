import { BaseXYAxisChartOptions, LegendOptions } from '@sprinklr/stories/hyperspaceWidgets/types';
import merge from 'lodash/merge';
import { observable } from 'mobx';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import {
    BaseXYAxisChartOptionsImpl,
    BubbleOptionsImpl,
    LegendOptionsImpl,
    MetaInfoOptionsImpl,
} from '../options';

import { HyperSpaceBubblePlotChartWidgetOptions } from './types';

export class HyperSpaceBubblePlotChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperSpaceBubblePlotChartWidgetOptions {
    @observable baseXYChartOptions: BaseXYAxisChartOptions = new BaseXYAxisChartOptionsImpl();
    @observable zeroFill = true;
    @observable bubble = new BubbleOptionsImpl();
    @observable legend: LegendOptions = new LegendOptionsImpl();
    @observable metaInfoOptions = new MetaInfoOptionsImpl();
}
