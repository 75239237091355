import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';
import { Widget } from '@sprinklr/stories/widget/Widget';
import Color from 'color';
import { generateRankedColors, getMonochromeStopColor } from 'utils/GenerateColors/GenerateColors';
import { MetricWidgetOptionsImpl } from './options';
import { ComputedStyle } from 'utils/GenerateStyles/GenerateStyles';

export const getSingleMetricCss: GetWidgetTypeStyles = (
    mergedTheme: Theme,
    dataSet,
    widget: Widget,
    cssPrefix: string
) => {
    let output = '';
    const { colorPalette } = mergedTheme;
    const {
        type,
        colorBlend: { startColor, endColor },
        monochrome: { startColor: mStartColor, endOffset },
        ranked: { colors: inheritedColors, contrast },
    } = colorPalette;

    const backgroundPrefix = type === 'solid' ? 'background-color' : 'background';

    if (type === 'colorBlend') {
        output += `${cssPrefix} .primary_background { ${backgroundPrefix}: linear-gradient(${startColor}, ${endColor}); }`;
    } else if (type === 'monochrome') {
        output += `${cssPrefix} .primary_background { ${backgroundPrefix}: linear-gradient(${mStartColor}, ${getMonochromeStopColor(
            mStartColor,
            endOffset * 0.01
        )}); }`;
    } else if (type === 'ranked') {
        const colors = widget.theme?.colorPalette?.ranked?.colors ?? inheritedColors;
        const relsolvedColors = generateRankedColors(colors, contrast, 1);
        if (relsolvedColors) {
            const stops =
                relsolvedColors?.length > 1
                    ? relsolvedColors.slice(0, 1).join(', ') // just use the first color
                    : relsolvedColors[0];
            output += `${cssPrefix} .primary_background { ${backgroundPrefix}: linear-gradient(${stops}); }`;
        }
    }

    return output;
};

export const metricWidgetOptionStyles = (
    options: MetricWidgetOptionsImpl,
    theme: Theme,
    inheritedTheme: Theme,
    measurements?
): ComputedStyle[] => {
    const { percentChangeColor } = options;
    const computedStyles: ComputedStyle[] = [];

    if (percentChangeColor.enabled) {
        const { typography: { color: themeColor } = null } = theme;
        const { typography: { color: inheritedThemeColor } = null } = inheritedTheme;
        const textColor = themeColor || inheritedThemeColor;
        computedStyles.push(
            {
                selector: '.metric_ratio.change_positive',
                styles: {
                    color: percentChangeColor.up,
                    opacity: 1,
                },
            },
            {
                selector: '.metric_ratio.change_negative',
                styles: {
                    color: percentChangeColor.down,
                },
            },
            {
                selector: '.metric_ratio:not(.change_negative):not(.change_positive)',
                styles: {
                    color: !!percentChangeColor.zero ? percentChangeColor.zero : textColor,
                },
            }
        );
    }

    return computedStyles;
};
