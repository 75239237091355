import { GridLayoutImpl, PostsWidgetOptions, PostsWidgetOptionsImpl } from '../options';
import { observable } from 'mobx';
import { StoryOptions } from './types';
import { PostTextFormat } from '@sprinklr/stories/widget/types';
import { defaultClassOverrides } from 'components/Panel/PanelEditorSidebar/PanelDesign/CompositionsConfig/StoryCardConfig';

export class StoryOptionsImpl implements StoryOptions {
    @observable space = 50;
    @observable title = {
        enabled: true,
        size: 65,
        color: '',
    };
    @observable summary = {
        enabled: true,
        size: 45,
        color: '',
    };
    @observable meta = {
        enabled: true,
        size: 45,
        color: '',
    };
    @observable metrics = {
        enabled: true,
        size: 45,
        color: '',
    };
    @observable publications = {
        enabled: true,
        size: 45,
        color: '',
    };
    @observable divider = {
        enabled: true,
        size: 10,
        color: '',
    };
}
export class StoryCardWidgetOptionsImpl extends PostsWidgetOptionsImpl
    implements PostsWidgetOptions {
    @observable classes = 'rel';
    @observable postComposition = 'a';
    @observable secondaryOverlayColor = 'rgba(0,0,0,.8)';
    @observable showPostTextOverlayAsGradient = true;
    @observable postImageTextFormat: PostTextFormat = 'clamp';
    @observable gridLayout = new GridLayoutImpl();
    @observable storyOptions: StoryOptions = new StoryOptionsImpl();
    @observable classOverrides = defaultClassOverrides;
}
