import moment from 'moment';
import { CurrencyFormat, XAxisDateFormats } from '../widget/types';
import { FieldDataType, FieldType } from '../reporting/types';
import { FontCase } from 'models/Theme/Theme';
import format from 'date-fns/format';

// TODO: these @sprinklr/display-builder imports need to go away
import {
    DecimalFormat,
    formatPrettyTimeInterval,
    getPrettyNumberString,
    getPrettyTimeInterval,
    getPrettyTimestamp,
} from '@sprinklr/display-builder/utils/Number/NumberUtils';
import { applyLocaleToMoment } from '@sprinklr/display-builder/utils/Date/DateUtils';
import { EN_DAY_OF_WEEK_MONTH_OF_YEAR_VS_AR } from '@sprinklr/display-builder/utils/Number/constants';

export default class Field<T> {
    name: T;
    type: FieldType;
    dataType?: FieldDataType;

    constructor(name: T, type: FieldType, dataType?: FieldDataType) {
        this.name = name;

        if (typeof type === 'undefined' || type === null) {
            throw new Error('Field type is required!');
        }

        this.type = type;
        this.dataType = dataType;
    }

    getName(): T {
        return this.name;
    }

    cast(value: any): any {
        if (value === null || typeof value === 'undefined') {
            return null;
        }

        let intVal: number;

        switch (this.type) {
            case 'INTEGER':
                if (
                    value === '' ||
                    value === 0 ||
                    value === null ||
                    value === undefined ||
                    isNaN(value)
                ) {
                    return 0;
                }

                intVal = parseInt(value, 10);
                if (isNaN(intVal)) {
                    throw new Error('"' + value + '" is not a valid integer');
                }

                return intVal;

            case 'TIME_INTERVAL':
            case 'NUMBER':
            case 'DECIMAL':
                if (
                    value === '' ||
                    value === 0 ||
                    value === null ||
                    value === undefined ||
                    isNaN(value)
                ) {
                    return 0;
                }

                const floatVal: number = parseFloat(value);
                if (isNaN(floatVal)) {
                    throw new Error('"' + value + '" is not a valid decimal');
                }

                return floatVal;

            case 'STRING':
                return value === 0 || value ? value + '' : '';

            case 'DATE':
            case 'TIMESTAMP':
                if (value instanceof Date) {
                    return value;
                }

                if (value === '' || value === null || value === undefined) {
                    return null;
                }

                intVal = parseInt(value, 10);
                if (!isNaN(intVal) && intVal + '' === value) {
                    value = intVal;
                }

                if (typeof value === 'string') {
                    const ts = Date.parse(value);
                    if (!isNaN(ts)) {
                        return new Date(ts);
                    }

                    const m = moment(value);
                    if (m.isValid()) {
                        return m.toDate();
                    }

                    if (isNaN(intVal)) {
                        throw new Error('"' + value + '" is not a valid timestamp');
                    }

                    value = intVal;
                }

                if (typeof value === 'number') {
                    return new Date(value);
                }

                throw new Error('"' + value + '" is not a valid timestamp');

            default:
                throw new Error('Unable to cast type ' + this.type);
        }
    }
}

type FieldTypeValueOpts = {
    dateFormat?: XAxisDateFormats;
    decimalFormat?: DecimalFormat;
    currencyFormat?: CurrencyFormat;
    isPercentage?: boolean;
    valueCase?: FontCase;
    dataType?: FieldDataType;
    language?: string;
};

export const getFieldTypeValue = (value, type: FieldType, options: FieldTypeValueOpts) => {
    const { dateFormat, currencyFormat = '', isPercentage, decimalFormat, valueCase } = options;
    switch (type) {
        case 'TIMESTAMP':
        case 'TIME_INTERVAL':
        case 'DATE':
            if ('TIME_DIFFERENCE' === options?.dataType) {
                const interval = getPrettyTimeInterval(value, true, true);
                return formatPrettyTimeInterval(interval);
            }
            const dateValue =
                value.toString() && !moment.isDate(value) ? parseInt(value.toString()) : value;

            const dateInstance = moment(dateValue);
            applyLocaleToMoment(dateInstance, options.language);

            return dateFormat === 'auto'
                ? getPrettyTimestamp(dateInstance.valueOf(), options.language)
                : dateInstance.format(dateFormat);

        case 'STRING':
            if (options.language === 'ar') {
                return EN_DAY_OF_WEEK_MONTH_OF_YEAR_VS_AR[(value + '')?.toUpperCase()] || value;
            }
            return value;
        default:
            return `${currencyFormat}${getPrettyNumberString(value, decimalFormat, valueCase)}${
                isPercentage ? '%' : ''
            }`;
    }
};
