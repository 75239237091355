import { axisBubbleChartOptionStyles } from 'components/_charts/AxisBubbleChart/AxisBubbleChart';
import { WIDGET_TYPES } from 'models/Widget/constants';
import { WidgetTypeId } from '../../models/Widget/types';
import { alertOptionStyles } from '../AutoAlertsWidget/helpers';
import { AutoAlertsWidgetOptionsImpl } from '../AutoAlertsWidget/options';
import { AxisBubbleChartWidgetOptionsImpl } from '../AxisBubbleChartWidget/options';
import { getPostCss, postOptionStyles } from '../PostsWidget/helpers';
import { WidgetConfig } from './types';
import { getWidget } from './WidgetFactory';
import { BarChartWidgetOptionsImpl } from '../BarChartWidget/options';
import { barChartOptionStyles } from '../BarChartWidget/helpers';
import { MetricWidgetOptionsImpl } from '../MetricWidget/options';
import { getSingleMetricCss, metricWidgetOptionStyles } from '../MetricWidget/helpers';
import { ProfileWidgetOptionsImpl } from '../ProfileWidget/options';
import { axisBubbleChartCss } from '../AxisBubbleChartWidget/helpers';
import { PostsWidgetGridV2OptionsImpl } from '../PostsWidget/GridPost/options';
import { PostsWidgetOptionsImpl } from '../PostsWidget/options';
import { PostsWidgetCollageOptionsImpl } from '../PostsWidget/Collage/options';
import { PostsWidgetFilmStripOptionsImpl } from '../PostsWindowWidget/FilmStrip/options';
import { PostsWidgetCinematicOptionsImpl } from '../PostsWindowWidget/Cinematic/options';
import { PostsWidgetTypePosterOptionsImpl } from '../PostsWindowWidget/options';
import { PostsWidgetSingleOptionsImpl } from '../PostsWindowWidget/SinglePost/options';
import { TickerWidgetOptionsImpl } from '../TickerWidget/options';
import { WaterfallWidgetOptionsImpl } from '../WaterfallWidget/options';
import { PostCardWidgetOptionsImpl } from '../PostsWidget/PostCard/options';
import { StoryCardWidgetOptionsImpl } from '../PostsWidget/StoryCard/StoryCardWidgetOptionsImpl';
import { RunOfShowWidgetOptionsImpl } from '../PostsWidget/RunOfShow/options';
import {
    ComboChartWidgetOptionsImpl,
    DualAxisWidgetOptionsImpl,
} from '../ComboChartWidget/options';
import { comboChartOptionStyles, getComboChartCss } from '../ComboChartWidget/helpers';
import { BubblePlotChartWidgetOptionsImpl } from '../BubblePlotChartWidget/options';
import { PieChartWidgetOptionsImpl } from '../PieChartWidget/options';
import { getPieChartCss } from '../PieChartWidget/helpers';
import { getStackedBarChartCss } from '../StackedBarChartWidget/helpers';
import { StackedBarChartWidgetOptionsImpl } from '../StackedBarChartWidget/options';
import { SunburstWidgetOptionsImpl } from '../SunburstWidget/options';
import { getSunburstCss } from '../SunburstWidget/helpers';
import { SmallMultiplesPieChartWidgetOptionsImpl } from '../SmallMultiplesPieChartWidget/options';
import { getSmallMultiplesPieChartCss } from '../SmallMultiplesPieChartWidget/helpers';
// import { SmartClusterWidgetOptionsImpl } from '../SmartClusterWidget/options';
// import { getSmartClusterCss } from '../SmartClusterWidget/helpers';
import { LineChartWidgetOptionsImpl } from '../LineChartWidget/options';
import { getLineChartCss, lineChartOptionStyles } from '../LineChartWidget/helpers';
import { WordCloudWidgetOptionsImpl } from '../WordCloudWidget/options';
import { getWordCloudCss } from '../WordCloudWidget/helpers';
import { BubbleChartWidgetOptionsImpl } from '../BubbleChartWidget/options';
import { BubbleChartCss, bubbleChartOptionStyles } from '../BubbleChartWidget/helpers';
import { MultiMetricWidgetOptionsImpl } from '../MultiMetricWidget/options';
import { getMultiMetricCss, multiMetricWidgetOptionStyles } from '../MultiMetricWidget/helpers';
import { RankedListWidgetOptionsImpl } from '../RankedListWidget/options';
import { DynamicImageWidgetOptionsImpl } from '../DynamicImageWidget/options';
import { DynamicTitleWidgetOptionsImpl } from '../DynamicTitleWidget/options';
import { dynamicTitleCss, dynamicTitleOptionStyles } from '../DynamicTitleWidget/helpers';
import { MetricRollupWidgetOptionsImpl } from '../MetricRollupWidget/options';
import { getMetricRollupCss } from '../MetricRollupWidget/helpers';
import { WorldHeatMapWidgetOptionsImpl } from '../WorldHeatMapWidget/options';
import { worldHeatMapOptionStyles } from '../WorldHeatMapWidget/helpers';
import { GeoStreamWidgetOptionsImpl } from '../GeoStreamWidget/options';
import { geoStreamWidgetOptionStyles } from '../GeoStreamWidget/helpers';
import { HeatMapTableChartWidgetOptionsImpl } from '../HeatMapTableChartWidget/options';
import { heatMapTableCss, heatMapTableOptionStyles } from '../HeatMapTableChartWidget/helpers';
import { RankedTableWidgetOptionsImpl } from '../RankedTableWidget/options';
import { getRankedTableCss, rankedTableOptionStyles } from '../RankedTableWidget/helpers';
import { DataTableWidgetOptionsImpl } from '../DataTableWidget/options';
import { dataTableOptionStyles, getDataTableCss } from '../DataTableWidget/helpers';
import { CustomTableChartWidgetOptionsImpl } from 'components/_charts/CustomTableChartWidget/options';
import { customTableChartOptionStyles } from '../../components/_charts/CustomTableChartWidget/helpers';
import {
    CustomContentIframeOptionsImpl,
    CustomContentImageOptionsImpl,
    CustomContentRichTextOptionsImpl,
    CustomContentSocialPostOptionsImpl,
    CustomContentVideoOptionsImpl,
} from '../CustomContentWidget/options';
import {
    customContentImageOptionStyles,
    customContentRichTextOptionStyles,
    customContentSocialPostOptionStyles,
    customContentVideoOptionStyles,
    getCustomContentCss,
} from '../CustomContentWidget/helpers';
import { TreeMapWidgetOptionsImpl } from '../TreeMapWidget/options';
import { treeMapOptionStyles } from '../TreeMapWidget/helpers';
import { FunnelChartWidgetOptionsImpl } from '../FunnelChartWidget/options';
import { TwitterTrendsWidgetOptionsImpl } from '../TwitterTrendsWidget/options';
import { ShapeWidgetOptionsImpl } from '../ShapeWidget/options';
import { shapeOptionStyles } from '../ShapeWidget/helpers';
import { SpeechBubbleWidgetOptionsImpl } from '../SpeechBubbleWidget/options';
import { speechBubbleOptionStyles } from '../SpeechBubbleWidget/helpers';
import { MetricValueWidgetOptionsImpl } from '../MetricValueWidget/options';
import { MetricValueLockupWidgetOptionsImpl } from '../MetricValueLockupWidget/options';
import { CountUpWidgetOptionsImpl } from '../CountUpWidget/options';
import { GoogleTrendsWidgetOptionsImpl } from '../GoogleTrendsWidget/options';
import { HyperlinkButtonWidgetOptionsImpl } from '../HyperlinkButtonWidget/options';
import { getHyperlinkCss, hyperlinkWidgetOptionStyles } from '../HyperlinkButtonWidget/helpers';
import { HyperSpaceBarChartWidgetOptionsImpl } from '../hyperSpace/HyperSpaceBarChartWidget/options';
import { HyperSpaceColumnChartWidgetOptionsImpl } from '../hyperSpace/HyperSpaceColumnChartWidget/options';
import { HyperSpaceLineChartWidgetOptionsImpl } from '../hyperSpace/HyperSpaceLineChartWidget/options';
import { HyperSpaceAreaChartWidgetOptionsImpl } from '../hyperSpace/HyperSpaceAreaChartWidget/options';
import { HyperSpaceDualAxisChartWidgetOptionsImpl } from '../hyperSpace/HyperSpaceDualAxisChartWidget/options';
import { HyperSpaceComboChartWidgetOptionsImpl } from '../hyperSpace/HyperSpaceComboChartWidget/options';
import { HyperSpaceBubblePlotChartWidgetOptionsImpl } from '../hyperSpace/HyperSpaceBubblePlotChartWidget/options';
import { HyperSpaceAxisBubbleChartWidgetOptionsImpl } from '../hyperSpace/HyperSpaceAxisBubbleChartWidget/options';
import { HyperSpacePieChartWidgetOptionsImpl } from '../hyperSpace/HyperSpacePieChartWidget/options';
import { HyperSpaceStackedBarChartWidgetOptionsImpl } from '../hyperSpace/HyperSpaceStackedBarChartWidget/options';
import { HyperSpaceStackedColumnChartWidgetOptionsImpl } from '../hyperSpace/HyperSpaceStackedColumnChartWidget/options';
import { HyperSpaceTreeMapWidgetOptionsImpl } from '../hyperSpace/HyperSpaceTreeMapWidget/options';
import { HyperspaceSmartClusterWidgetOptionsImpl } from '../hyperSpace/hyperspaceSmartClusterWidget/options';
import { HyperSpaceQuadrantMatrixWidgetOptionsImpl } from '../hyperSpace/HyperSpaceQuadrantMatrixWidget/options';

// post compositions
import postCardCompositions from 'components/Post/PostCardCompositions';
import storyCardCompositions from 'components/Post/StoryCard/StoryCardCompositions';
import tickerCompositions from 'components/Post/TickerCompositions';
import collageCompositions from 'components/Post/CollageCompositions';
import gridCompositions from 'components/Post/GridCompositions';
import filmStripCompositions from 'components/Post/FilmstripCompositions';
import cinematicCompositions from 'components/Post/CinematicCompositions';
import singlePostCompositions from 'components/Post/SinglePostCompositions';

const COMMON_POST_FIELDS = {
    computeOptionStyles: postOptionStyles,
    getStyles: getPostCss,
};

const COMMON_TABLE_FIELDS = {
    options: DataTableWidgetOptionsImpl,
    computeOptionStyles: dataTableOptionStyles,
    getStyles: getDataTableCss,
};

const WIDGET_TYPE_TO_ENHANCED_FACTORY: Spr.StringTMap<Omit<WidgetConfig, 'component' | 'id'>> = {
    [WIDGET_TYPES.autoAlerts]: {
        // options: import(
        //     /* webpackChunkName: autoAlertsOptions */ `../AutoAlertsWidget/options`
        // ).then(module => module.AutoAlertsWidgetOptionsImpl),
        // computeOptionStyles: import(
        //     /* webpackChunkName: autoAlertsComputeOptionStyles */ `../AutoAlertsWidget/helpers`
        // ).then(module => module.alertOptionStyles),
        // getStyles: import(
        //     /* webpackChunkName: autoAlertsGetStyles */ `../PostsWidget/helpers`
        // ).then(module => module.getPostCss),
        // tunables: import(
        //     /* webpackChunkName: autoAlertsTunables */ `../AutoAlertsWidget/AutoAlertsTunables`
        // ).then(module => module.default),
        options: AutoAlertsWidgetOptionsImpl,
        computeOptionStyles: alertOptionStyles,
        getStyles: getPostCss,
        // tunables: AutoAlertsTunables,
    },
    [WIDGET_TYPES.axisBubbleChart]: {
        // options: import(
        //     /* webpackChunkName: axisBubbleOptions */ `../AxisBubbleChartWidget/options`
        // ).then(module => module.AxisBubbleChartWidgetOptionsImpl),
        // computeOptionStyles: import(
        //     /* webpackChunkName: axisBubbleComputeOptionStyles */ `../AxisBubbleChartWidget/helpers`
        // ).then(module => module.getMetricDataSet) as any,
        // getStyles: import(
        //     /* webpackChunkName: axisBubbleGetStyles */ `../AxisBubbleChartWidget/helpers`
        // ).then(module => module.axisBubbleChartCss),
        // tunables: import(
        //     /* webpackChunkName: axisBubbleTunables */ `../AxisBubbleChartWidget/AxisBubbleChartTunables`
        // ).then(module => module.default),
        options: AxisBubbleChartWidgetOptionsImpl,
        computeOptionStyles: axisBubbleChartOptionStyles,
        getStyles: axisBubbleChartCss,
        // tunables: AxisBubbleChartTunables,
        styleSeriesRecipe: [
            {
                incrementor: '.bubble',
                property: 'backgroundColor',
            },
            {
                incrementor: '.legend_item',
                specifier: '.vx-glyph-dot',
                property: 'fill',
            },
        ],
    },
    [WIDGET_TYPES.barChart]: {
        options: BarChartWidgetOptionsImpl,
        computeOptionStyles: barChartOptionStyles,
        getStyles: undefined,
        styleSeriesRecipe: [
            {
                incrementor: '.bar',
                specifier: '.primary_background.bar_ratio_calc',
                property: 'backgroundColor',
            },
            {
                incrementor: '.legend_item',
                specifier: '.legend_item_swatch',
                property: 'backgroundColor',
            },
        ],
    },
    [WIDGET_TYPES.metric]: {
        options: MetricWidgetOptionsImpl,
        computeOptionStyles: metricWidgetOptionStyles,
        getStyles: getSingleMetricCss,
    },
    [WIDGET_TYPES.profile]: {
        options: ProfileWidgetOptionsImpl,
        computeOptionStyles: postOptionStyles,
        getStyles: undefined,
    },
    [WIDGET_TYPES.postsGridV2]: {
        options: PostsWidgetGridV2OptionsImpl,
        getPostComposition: gridCompositions,
        supportsLinkPreview: true,
        supportsQuotedTweetView: true,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.postsGrid]: {
        options: PostsWidgetGridV2OptionsImpl,
        getPostComposition: gridCompositions,
        supportsLinkPreview: true,
        supportsQuotedTweetView: true,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.posts]: {
        options: PostsWidgetOptionsImpl,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.postsCollage]: {
        options: PostsWidgetCollageOptionsImpl,
        getPostComposition: collageCompositions,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.postsFilmStrip]: {
        options: PostsWidgetFilmStripOptionsImpl,
        getPostComposition: filmStripCompositions,
        supportsLinkPreview: true,
        supportsQuotedTweetView: true,
        requestOverrides: (data: any) => {
            if (!data || !data.postRequests || !data.postRequests.length) {
                return;
            }

            const request = data.postRequests[0];
            if (request.format) {
                const options = request.format.options;
                if (options && options.padding && options.padding >= 3) {
                    options.offsetCenter = options.padding - 1;
                }
            }
        },
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.postsCinematic]: {
        options: PostsWidgetCinematicOptionsImpl,
        getPostComposition: cinematicCompositions,
        supportsQuotedTweetView: true,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.postsTypePoster]: {
        options: PostsWidgetTypePosterOptionsImpl,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.postsSinglePost]: {
        options: PostsWidgetSingleOptionsImpl,
        getPostComposition: singlePostCompositions,
        supportsLinkPreview: true,
        supportsQuotedTweetView: true,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.smartTrends]: {
        options: TickerWidgetOptionsImpl,
        getPostComposition: tickerCompositions,
        computeOptionStyles: undefined,
        supportsLinkPreview: true,
        getStyles: undefined,
    },
    [WIDGET_TYPES.postsTicker]: {
        options: TickerWidgetOptionsImpl,
        getPostComposition: tickerCompositions,
        supportsLinkPreview: true,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.postsWaterfall]: {
        options: WaterfallWidgetOptionsImpl,
        supportsQuotedTweetView: true,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.postCard]: {
        options: PostCardWidgetOptionsImpl,
        getPostComposition: postCardCompositions,
        supportsLinkPreview: true,
        supportsQuotedTweetView: true,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.storyCard]: {
        options: StoryCardWidgetOptionsImpl,
        getPostComposition: storyCardCompositions,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.runOfShow]: {
        options: RunOfShowWidgetOptionsImpl,
        getPostComposition: postCardCompositions,
        supportsLinkPreview: true,
        supportsQuotedTweetView: true,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.comboChart]: {
        options: ComboChartWidgetOptionsImpl,
        computeOptionStyles: comboChartOptionStyles,
        getStyles: getComboChartCss,
    },
    [WIDGET_TYPES.dualAxis]: {
        options: DualAxisWidgetOptionsImpl,
        computeOptionStyles: comboChartOptionStyles,
        getStyles: getComboChartCss,
    },
    [WIDGET_TYPES.bubblePlotChart]: {
        options: BubblePlotChartWidgetOptionsImpl,
        computeOptionStyles: axisBubbleChartOptionStyles,
        getStyles: axisBubbleChartCss,
        styleSeriesRecipe: [
            {
                incrementor: '.bubble',
                property: 'backgroundColor',
                specifier: '.bubble',
                joinSpecifier: true,
            },
            {
                incrementor: '.legend_item',
                specifier: '.vx-glyph-dot',
                property: 'fill',
            },
        ],
    },
    [WIDGET_TYPES.pieChart]: {
        options: PieChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: getPieChartCss,
        styleSeriesRecipe: [
            {
                incrementor: '.wedge',
                property: 'fill',
            },
            {
                incrementor: '.label',
                specifier: '.label_line',
                property: 'stroke',
            },
            {
                incrementor: '.legend_item',
                specifier: '.legend_item_swatch',
                property: 'background',
            },
        ],
    },
    [WIDGET_TYPES.barChart]: {
        options: BarChartWidgetOptionsImpl,
        computeOptionStyles: barChartOptionStyles,
        getStyles: undefined,
        styleSeriesRecipe: [
            {
                incrementor: '.bar',
                specifier: '.primary_background.bar_ratio_calc',
                property: 'backgroundColor',
            },
            {
                incrementor: '.legend_item',
                specifier: '.legend_item_swatch',
                property: 'backgroundColor',
            },
        ],
    },
    [WIDGET_TYPES.stackedBarChart]: {
        options: StackedBarChartWidgetOptionsImpl,
        computeOptionStyles: barChartOptionStyles,
        getStyles: getStackedBarChartCss,
        styleSeriesRecipe: [
            {
                incrementor: '.segment',
                property: 'backgroundColor',
            },
            {
                incrementor: '.legend_item',
                specifier: '.legend_item_swatch',
                property: 'backgroundColor',
            },
        ],
    },
    [WIDGET_TYPES.sunburst]: {
        options: SunburstWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: getSunburstCss,
        styleSeriesRecipe: [
            {
                incrementor: '.wedge',
                property: 'fill',
            },
        ],
    },
    [WIDGET_TYPES.smallMultiplesPieChart]: {
        options: SmallMultiplesPieChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: getSmallMultiplesPieChartCss,
        styleSeriesRecipe: [
            {
                incrementor: '.wedge',
                property: 'fill',
            },
            {
                incrementor: '.legend_item',
                specifier: '.legend_item_swatch',
                property: 'background',
            },
        ],
    },
    // [WIDGET_TYPES.smartCluster]: {
    //     options: SmartClusterWidgetOptionsImpl,
    //     computeOptionStyles: undefined,
    //     getStyles: getSmartClusterCss,
    //     styleSeriesRecipe: [
    //         {
    //             incrementor: '.wedge',
    //             // specifier: '.',
    //             property: 'fill',
    //         },
    //         {
    //             incrementor: '.legend_item',
    //             specifier: '.legend_item_swatch',
    //             property: 'background',
    //         },
    //     ],
    // },
    [WIDGET_TYPES.lineChart]: {
        options: LineChartWidgetOptionsImpl,
        computeOptionStyles: lineChartOptionStyles,
        getStyles: getLineChartCss,
        styleSeriesRecipe: [
            {
                incrementor: '.line',
                specifier: '.line.primary_stroke',
                joinSpecifier: true,
                property: 'stroke',
            },
            {
                incrementor: '.prev_period_line',
                specifier: '.line.primary_stroke',
                joinSpecifier: true,
                property: 'stroke',
            },
            {
                incrementor: '.points',
                specifier: '.point_inner',
                property: 'backgroundColor',
            },
            {
                incrementor: '.point_label_link',
                specifier: '.point_label_link',
                joinSpecifier: true,
                property: 'stroke',
            },
            {
                incrementor: '.legend_item',
                specifier: '.legend_item_swatch',
                property: 'backgroundColor',
            },
        ],
    },
    [WIDGET_TYPES.wordCloud]: {
        options: WordCloudWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: getWordCloudCss,
        styleSeriesRecipe: [
            {
                incrementor: '.word',
                property: 'fill',
            },
        ],
    },
    [WIDGET_TYPES.bubbleChart]: {
        options: BubbleChartWidgetOptionsImpl,
        computeOptionStyles: bubbleChartOptionStyles,
        getStyles: BubbleChartCss,
        styleSeriesRecipe: [
            {
                incrementor: '.bubble',
                specifier: '.primary_background.bubble_inner',
                property: 'backgroundColor',
            },
        ],
    },
    [WIDGET_TYPES.multiMetric]: {
        options: MultiMetricWidgetOptionsImpl,
        computeOptionStyles: multiMetricWidgetOptionStyles,
        getStyles: getMultiMetricCss,
        styleSeriesRecipe: [
            {
                incrementor: '.bubble',
                specifier: '.primary_background.bubble_inner',
                property: 'backgroundColor',
            },
        ],
    },
    [WIDGET_TYPES.rankedList]: {
        options: RankedListWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
        styleSeriesRecipe: [
            {
                incrementor: '.item',
                specifier: '.primary_background.item_rank',
                property: 'backgroundColor',
            },
        ],
    },
    [WIDGET_TYPES.dynamicImage]: {
        options: DynamicImageWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.dynamicTitle]: {
        options: DynamicTitleWidgetOptionsImpl,
        computeOptionStyles: dynamicTitleOptionStyles,
        getStyles: dynamicTitleCss,
    },
    [WIDGET_TYPES.metricRollup]: {
        options: MetricRollupWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: getMetricRollupCss,
    },
    [WIDGET_TYPES.worldHeatMap]: {
        options: WorldHeatMapWidgetOptionsImpl,
        computeOptionStyles: worldHeatMapOptionStyles,
        getStyles: undefined,
    },
    [WIDGET_TYPES.geoStream]: {
        options: GeoStreamWidgetOptionsImpl,
        computeOptionStyles: geoStreamWidgetOptionStyles,
        getPostComposition: tickerCompositions,
        supportsLinkPreview: true,
        getStyles: getPostCss,
    },
    [WIDGET_TYPES.geoStreamTicker]: {
        options: TickerWidgetOptionsImpl,
        ...COMMON_POST_FIELDS,
    },
    [WIDGET_TYPES.heatMapTableChart]: {
        options: HeatMapTableChartWidgetOptionsImpl,
        computeOptionStyles: heatMapTableOptionStyles,
        getStyles: heatMapTableCss,
    },
    [WIDGET_TYPES.rankedTable]: {
        options: RankedTableWidgetOptionsImpl,
        computeOptionStyles: rankedTableOptionStyles,
        getStyles: getRankedTableCss,
        styleSeriesRecipe: [
            {
                incrementor: '.row',
                specifier: '.primary_background.icon__container',
                property: 'backgroundColor',
            },
            {
                incrementor: '.cell',
                property: 'backgroundColor',
            },
        ],
    },
    [WIDGET_TYPES.dataTablePivot]: COMMON_TABLE_FIELDS,
    [WIDGET_TYPES.dataTableCrossTab]: COMMON_TABLE_FIELDS,
    [WIDGET_TYPES.dataTableSummary]: COMMON_TABLE_FIELDS,
    [WIDGET_TYPES.dataTableGroupedSummary]: COMMON_TABLE_FIELDS,
    [WIDGET_TYPES.customTable]: {
        options: CustomTableChartWidgetOptionsImpl,
        computeOptionStyles: customTableChartOptionStyles,
        getStyles: undefined,
    },
    [WIDGET_TYPES.customTableRichText]: {
        options: CustomContentRichTextOptionsImpl,
        computeOptionStyles: customContentRichTextOptionStyles,
        getStyles: getCustomContentCss,
    },
    [WIDGET_TYPES.treeMap]: {
        options: TreeMapWidgetOptionsImpl,
        computeOptionStyles: treeMapOptionStyles,
        getStyles: undefined,
        styleSeriesRecipe: [
            {
                incrementor: '.node',
                specifier: '.primary_background.node_inner',
                property: 'backgroundColor',
            },
        ],
    },
    [WIDGET_TYPES.funnelChart]: {
        options: FunnelChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
        styleSeriesRecipe: [
            {
                incrementor: '.bar',
                specifier: '.primary_background.bar_ratio_fill',
                property: 'fill',
                joinSpecifier: true,
            },
        ],
    },
    [WIDGET_TYPES.customContentRichText]: {
        options: CustomContentRichTextOptionsImpl,
        computeOptionStyles: customContentRichTextOptionStyles,
        getStyles: getCustomContentCss,
    },
    [WIDGET_TYPES.customContentImage]: {
        options: CustomContentImageOptionsImpl,
        computeOptionStyles: customContentImageOptionStyles,
        getStyles: getCustomContentCss,
    },
    [WIDGET_TYPES.customContentVideo]: {
        options: CustomContentVideoOptionsImpl,
        computeOptionStyles: customContentVideoOptionStyles,
        getStyles: getCustomContentCss,
    },
    [WIDGET_TYPES.customContentSocialPost]: {
        options: CustomContentSocialPostOptionsImpl,
        computeOptionStyles: customContentSocialPostOptionStyles,
        getStyles: getCustomContentCss,
    },
    [WIDGET_TYPES.customContentIframe]: {
        options: CustomContentIframeOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: getCustomContentCss,
    },
    [WIDGET_TYPES.twitterTrends]: {
        options: TwitterTrendsWidgetOptionsImpl,
        computeOptionStyles: treeMapOptionStyles,
        getStyles: getCustomContentCss,
        styleSeriesRecipe: [
            {
                incrementor: '.node',
                specifier: '.node_inner.primary_background',
                property: 'backgroundColor',
            },
        ],
    },
    [WIDGET_TYPES.shape]: {
        options: ShapeWidgetOptionsImpl,
        computeOptionStyles: shapeOptionStyles,
        getStyles: undefined,
    },
    [WIDGET_TYPES.speechBubble]: {
        options: SpeechBubbleWidgetOptionsImpl,
        computeOptionStyles: speechBubbleOptionStyles,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperlinkButton]: {
        options: HyperlinkButtonWidgetOptionsImpl,
        computeOptionStyles: hyperlinkWidgetOptionStyles,
        getStyles: getHyperlinkCss,
    },

    // hyperspace widgets
    [WIDGET_TYPES.hyperSpaceBarChart]: {
        options: HyperSpaceBarChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceColumnChart]: {
        options: HyperSpaceColumnChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceLineChart]: {
        options: HyperSpaceLineChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceAreaChart]: {
        options: HyperSpaceAreaChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceDualAxisChart]: {
        options: HyperSpaceDualAxisChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceComboChart]: {
        options: HyperSpaceComboChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceBubblePlotChart]: {
        options: HyperSpaceBubblePlotChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceAxisBubbleChart]: {
        options: HyperSpaceAxisBubbleChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpacePieChart]: {
        options: HyperSpacePieChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceStackedBarChart]: {
        options: HyperSpaceStackedBarChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceStackedColumnChart]: {
        options: HyperSpaceStackedColumnChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceTreeMap]: {
        options: HyperSpaceTreeMapWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.smartCluster]: {
        options: HyperspaceSmartClusterWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.hyperSpaceQuadrantMatrix]: {
        options: HyperSpaceQuadrantMatrixWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },

    // widgets below are deprecated but needed for backwards compatibility
    [WIDGET_TYPES.sparkline]: {
        options: LineChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.metricValue]: {
        options: MetricValueWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.metricValueLockup]: {
        options: MetricValueLockupWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.countUp]: {
        options: CountUpWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.legend]: {
        options: LineChartWidgetOptionsImpl,
        computeOptionStyles: undefined,
        getStyles: undefined,
    },
    [WIDGET_TYPES.googleTrends]: {
        options: GoogleTrendsWidgetOptionsImpl,
        computeOptionStyles: treeMapOptionStyles,
        getStyles: undefined,
        styleSeriesRecipe: [
            {
                incrementor: '.node',
                specifier: '.node_inner.primary_background',
                property: 'backgroundColor',
            },
        ],
    },
};

export function getWidgetConfigExternal(widgetType?: WidgetTypeId): WidgetConfig {
    const extras = widgetType
        ? WIDGET_TYPE_TO_ENHANCED_FACTORY[widgetType] || WIDGET_TYPE_TO_ENHANCED_FACTORY.DEFAULT
        : WIDGET_TYPE_TO_ENHANCED_FACTORY.DEFAULT;

    return {
        component: getWidget(widgetType),
        id: widgetType,
        ...extras,
    };
}
