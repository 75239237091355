import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { observable } from 'mobx';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { LabelTextFormat } from '@sprinklr/stories/widget/types';
import { FontCase } from 'models/Theme/Theme';

export interface TwitterTrendsWidgetOptions extends WidgetOptions {
    height?: number;
    width?: number;
}

export class TwitterTrendsWidgetOptionsImpl extends WidgetOptionsImpl
    implements TwitterTrendsWidgetOptions {
    @observable labelSize = 20;

    @observable showMetricName = true;
    @observable metricNameSize = 10;

    @observable showMetricValue = true;
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';
    @observable metricValueSize = 25;

    @observable spacing = 1;
    @observable padding = 8;

    @observable labelTextFormat: LabelTextFormat = 'clamp';
    @observable labelLineClamp = true;
    @observable labelLineClampValue = 2;
    @observable labelWrapText = false;
    @observable textFitMin = 20;
    @observable textFitMax = 400;
}
