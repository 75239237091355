import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';
import { borderStyles } from 'utils/GenerateStyles/GenerateStyles';

export const getMetricRollupCss: GetWidgetTypeStyles = (mergedTheme: Theme) => {
    const { typography: { color = null } = {} } = mergedTheme;
    const borderStyle = borderStyles(color);

    return `.rollup_item {border-bottom: ${borderStyle}}`;
};
