import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { observable } from 'mobx';

export type GeoStreamOptions = WidgetOptions & {
    highlightLocation: HighlightLocation;
};

type HighlightLocation = {
    enabled: boolean;
    color: string;
    line: {
        enabled: boolean;
        size: number;
        animate: boolean;
    };
    post: {
        radius: {
            enabled: boolean;
            size: number;
        };
        border: {
            enabled: boolean;
            size: number;
        };
        shadow: {
            enabled: boolean;
            color: string;
            size: number;
        };
    };
};
export class GeoStreamWidgetOptionsImpl extends WidgetOptionsImpl implements GeoStreamOptions {
    @observable highlightLocation = {
        enabled: true,
        color: '',
        line: {
            enabled: true,
            size: 2,
            animate: true,
        },
        post: {
            radius: {
                enabled: true,
                size: 10,
            },
            border: {
                enabled: true,
                size: 3,
            },
            shadow: {
                enabled: true,
                color: '',
                size: 5,
            },
        },
    };
}
