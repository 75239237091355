import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { TreeMapNode } from 'components/_charts/TreeMap/TreeMap';
import {
    Emoji,
    LabelTextFormat,
    PanelOrientation,
    SmartSize,
} from '@sprinklr/stories/widget/types';
import { observable } from 'mobx';
import { FontCase } from 'models/Theme/Theme';
import { DecimalFormat } from 'utils/Number/NumberUtils';

export interface TreeMapWidgetOptions extends WidgetOptions {
    data?: TreeMapNode[];
    spacing?: number;
    height?: number;
    width?: number;
    animationLength?: number;
    animationDelay?: number;
    panelOrientation?: PanelOrientation;
    blacklist?: string[];
}

export class TreeMapWidgetOptionsImpl extends WidgetOptionsImpl implements TreeMapWidgetOptions {
    @observable labelSize = 20;

    @observable showMetricName = true;
    @observable metricNameSize = 15;
    @observable metricNameCase: FontCase = 'lowercase';

    @observable showMetricValue = true;
    @observable metricValueSize = 35;
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';

    @observable showMetricChange = true;
    @observable metricPercentChangeSize = 20;
    @observable highlightPercentChange: boolean;

    @observable labelTextFormat: LabelTextFormat = 'clamp';
    @observable labelLineClamp = true;
    @observable labelLineClampValue = 2;
    @observable labelWrapText = false;
    @observable textFitMin = 20;
    @observable textFitMax = 400;

    @observable showSentiment = true;
    @observable sentimentSize = 50;
    @observable spacing = 1;
    @observable padding = 13;

    @observable showTimeRemainder = true;
    @observable blacklist: string[] = [];
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            labelSize: 20,
            metricNameSize: 15,
            metricValueSize: 35,
            metricPercentChangeSize: 20,
            sentimentSize: 50,
            padding: 13,
            spacing: 1,
        },
        optionDefaults: {
            showSentiment: true,
            showMetricName: true,
            showMetricValue: true,
            labelEnable: true,
        },
        breakpoints: {
            xs: {
                options: {
                    showSentiment: false,
                    showMetricName: false,
                    showMetricValue: false,
                    labelEnable: false,
                },
            },
            s: {
                options: {
                    showSentiment: false,
                },
            },
        },
    };
    @observable emoji: Emoji = {
        platform: 'default',
    };
}
