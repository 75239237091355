import DataSet, { DimensionDataGroup } from '@sprinklr/stories/analytics/DataSet';
import { Theme } from 'models/Theme/Theme';
import { FieldType } from '@sprinklr/stories/reporting/types';
import {
    AxisBubbleDataPoints,
    AxisBubbleSeries,
} from 'components/_charts/AxisBubbleChart/AxisBubbleChart';
import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import { normalizeLabel } from 'utils/NormalizeLabel/NormalizeLabel';
import { generateColorPalette } from 'utils/GenerateColors/GenerateColors';
import moment from 'moment';
import { BubblePlotChartWidgetOptionsImpl } from './options';
import AccountItem from '@sprinklr/stories/analytics/AccountItem';
import Dimension from '@sprinklr/stories/analytics/Dimension';
import { WIDGET_DAYS_OF_WEEK } from 'models/Widget/constants';
import { AnalyticsSortOrder } from '@sprinklr/stories/analytics/AnalyticsRequest';

export const getMetricDataSet = (
    dataSet: DataSet,
    options: BubblePlotChartWidgetOptionsImpl,
    metricIndex: number,
    mergedTheme: Theme,
    theme: Theme
) => {
    const firstDim = dataSet.getFirstDimension();
    let groupByDimensionIndex = 0;
    const type: FieldType = dataSet.metrics[metricIndex].type;

    switch (firstDim && firstDim.type) {
        case 'DATE':
        case 'TIMESTAMP':
        case 'STRING':
            groupByDimensionIndex = 1;
            break;

        case 'NUMBER':
        default:
            groupByDimensionIndex = 0;
            break;
    }

    let data: AxisBubbleSeries[] = [];

    if (dataSet.dimensions.length === 2) {
        data = dataSet.groupBy(dataSet.dimensions[groupByDimensionIndex]).map(
            (series: DimensionDataGroup): AxisBubbleSeries => {
                const bulkItem =
                    series.value instanceof BulkItem ? (series.value as BulkItem) : null;

                return {
                    name: normalizeLabel(bulkItem ? bulkItem.toString() : series.value),
                    data: series.data.toXYZ(),
                    type,
                    snType:
                        series.value instanceof AccountItem && series.value.snType
                            ? series.value.snType
                            : undefined,
                    color: options.useThemeColor && bulkItem ? bulkItem.toColor() || '' : undefined,
                    image: !!options.useThemeImage && bulkItem ? bulkItem.toImageUrl() : undefined,
                };
            }
        );
    } else if (dataSet.dimensions.length === 1) {
        const bulkItem =
            dataSet.metrics[metricIndex] instanceof BulkItem
                ? ((dataSet.metrics[metricIndex] as any) as BulkItem)
                : null;

        data = [
            {
                name: normalizeLabel(
                    bulkItem ? bulkItem.toString() : dataSet.metrics[metricIndex].name
                ),
                data: dataSet.toXYZ(),
                type,
                color: options.useThemeColor && bulkItem ? bulkItem.toColor() : undefined,
                image: !!options.useThemeImage && bulkItem ? bulkItem.toImageUrl() : undefined,
            },
        ];
    }

    if (data.length === 0) {
        return [];
    }

    // add colors
    const colorCount = Math.min(data.length, options.maxItems);
    const colors: string[] = generateColorPalette(mergedTheme, colorCount, false, theme);
    data.forEach((datum, index) => {
        if (!datum.color || datum.color === '') {
            datum.color = colors[index];
        }
    });

    data.forEach((series: AxisBubbleSeries) => {
        series.data.sort((a: AxisBubbleDataPoints, b: AxisBubbleDataPoints) => {
            if (firstDim.type === 'TIMESTAMP') {
                return moment(a.x).valueOf() - moment(b.x).valueOf();
            } else {
                if (a.x < b.x) {
                    return -1;
                }
                if (a.x > b.x) {
                    return 1;
                }
                return 0;
            }
        });
    });

    if ((data[0].data[0].x as any) instanceof BulkItem) {
        data.forEach(series => {
            series.data.map(points => (points.x = points.x + ''));
        });
    }

    if (firstDim && firstDim.type === 'TIMESTAMP') {
        data.forEach(series => {
            series.data.map(points => (points.x = moment(points.x).valueOf()));
        });
    }
    return data.slice(0, options.maxItems);
};

export const transformData = (
    dataSet: DataSet,
    options: BubblePlotChartWidgetOptionsImpl,
    mergedTheme: Theme,
    theme: Theme
) => {
    if (dataSet.metrics.length === 1) {
        return getMetricDataSet(dataSet, options, 0, mergedTheme, theme);
    } else {
        return dataSet.metrics.map((metric, index) =>
            getMetricDataSet(dataSet, options, index, mergedTheme, theme)
        )[0];
    }
};

export const additionalSort = (
    dimension: Dimension,
    values: any[],
    dir: AnalyticsSortOrder = 'ASC'
) => {
    if (
        ((dimension.type === 'TIMESTAMP' || dimension.type === 'DATE') &&
            (dimension.name === 'minute' ||
                dimension.name.indexOf('_1m') !== -1 ||
                dimension.name === 'hour' ||
                dimension.name.indexOf('_1h') !== -1)) ||
        dimension.name === 'Time Of Day' ||
        dimension.name === 'TIME_OF_DAY'
    ) {
        if (dir === 'ASC') {
            values.sort();
        } else {
            values.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
        }
        return;
    }

    if (['Day Of Week', 'DAY_OF_WEEK'].includes(dimension.name)) {
        values.sort((a, b) => {
            if (dir === 'ASC') {
                return (
                    WIDGET_DAYS_OF_WEEK.indexOf(a.toLowerCase()) -
                    WIDGET_DAYS_OF_WEEK.indexOf(b.toLowerCase())
                );
            } else {
                return (
                    WIDGET_DAYS_OF_WEEK.indexOf(b.toLowerCase()) -
                    WIDGET_DAYS_OF_WEEK.indexOf(a.toLowerCase())
                );
            }
        });
        return;
    }
};
