import {
    ComputedStyle,
    fadedFontColor,
    imageFilterStyles,
    styler,
} from 'utils/GenerateStyles/GenerateStyles';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Measurements } from 'components/Widget/WidgetComponent/types';
import { Theme } from 'models/Theme/Theme';
import { GenerateTransitionStyles } from 'utils/GenerateTransitionStyles/GenerateTransitionStyles';
import merge from 'deepmerge';
import { CustomContentRichTextOptionsImpl } from './options';
import { SpeechBubbleWidgetOptions } from '../SpeechBubbleWidget/types';

export const customContentImageOptionStyles = options => {
    const { offsetX, offsetY, zoom, imageBlendColor, imageBlendMode } = options;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.content_image',
            styles: {
                transform: `translate(${offsetX}%, ${offsetY}%) scale(${zoom * 0.02})`,
                filter: imageFilterStyles(options),
            },
        },
        {
            selector: '.content_image_overlay',
            styles: {
                display: 'block',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                position: 'absolute',
                backgroundColor: imageBlendColor,
                mixBlendMode: imageBlendMode,
            },
        },
    ];

    return [...computedStyles];
};

export const customContentVideoOptionStyles = options => {
    const { offsetX, offsetY, zoom, imageBlendColor, imageBlendMode } = options;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.content_video_container',
            styles: {
                transform: `translate(${offsetX}%, ${offsetY}%) scale(${zoom * 0.02})`,
            },
        },
        {
            selector: '.content_video',
            styles: {
                filter: imageFilterStyles(options),
            },
        },
        {
            selector: '.content_video_overlay',
            styles: {
                display: 'block',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                position: 'absolute',
                backgroundColor: imageBlendColor,
                mixBlendMode: imageBlendMode,
            },
        },
    ];

    return computedStyles;
};

export const customContentSocialPostOptionStyles = options => {
    const { offsetX, offsetY, zoom } = options;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.social_embed_inner',
            styles: {
                transform: `translate(${offsetX}%, ${offsetY}%) scale(${zoom * 0.02})`,
            },
        },
    ];

    return computedStyles;
};

export const customContentRichTextOptionStyles = (
    options: CustomContentRichTextOptionsImpl | SpeechBubbleWidgetOptions,
    theme: Theme,
    mergedTheme: Theme
) => {
    const {
        bulletColor,
        border,
        contentPadding,
        inheritFontCase,
    } = options as CustomContentRichTextOptionsImpl;

    const computedStyles: ComputedStyle[] = [];
    if (!!bulletColor) {
        computedStyles.push(
            {
                selector: '.public-DraftStyleDefault-ul li > div:before',
                styles: {
                    background: `${bulletColor}`,
                },
            },
            {
                selector: '.public-DraftStyleDefault-ol li > div:before',
                styles: {
                    color: `${bulletColor}`,
                },
            }
        );
    }

    if (contentPadding) {
        computedStyles.push({
            selector: '.custom_content_component',
            styles: {
                padding: `${contentPadding * 0.1}em`,
            },
        });
    }

    if (border && border.enabled) {
        const { typography: { color = null } = {} } = merge(theme, mergedTheme) as Theme;
        const borderColor = border.color || fadedFontColor(color);
        computedStyles.push({
            selector: '',
            styles: {
                border: borderColor
                    ? `${styler(border.size, 0.1, 'em', '')} solid ${borderColor}`
                    : 'none',
            },
        });
    }

    if (!inheritFontCase) {
        computedStyles.push({
            // the primary_font_family is needed to override table level font case
            selector: '.custom_content_component.primary_font_family',
            styles: {
                textTransform: 'inherit',
            },
        });
    }

    return computedStyles;
};

export const getCustomContentCss = (
    mergedTheme,
    dataSet,
    widget: Widget,
    cssPrefix: string,
    measurements?: Measurements
) => {
    const { animationLength, animationDelay } = widget.options;

    return GenerateTransitionStyles(
        '.custom_content_component',
        undefined,
        1,
        animationLength,
        animationDelay,
        cssPrefix
    );
};
