import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { HAlign, PercentChangeColor, SmartSize } from '@sprinklr/stories/widget/types';
import { GridCell, GridLayoutImpl, MetricNamePosition } from './types';
import { observable } from 'mobx';
import { FontCase } from 'models/Theme/Theme';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { colors } from 'components/spaceKit';
import { MetricDesignOptions } from '../RankedTableWidget/types';

export interface MultiMetricWidgetOptions extends WidgetOptions {
    widgetStyle?: string;
    highlightPercentChange?: boolean;
    showIcon?: boolean;
    orientation?: string;
    alignment?: HAlign;
    animationDelay?: number;
    animationEnabled?: boolean;
    animationLength?: number;
    gridLayout?: GridLayoutImpl;
    metricNamePosition?: MetricNamePosition;
    metricDesignOptions?: MetricDesignOptions;
}

export class MultiMetricWidgetOptionsImpl extends WidgetOptionsImpl
    implements MultiMetricWidgetOptions {
    @observable showMetricName = true;
    @observable metricNameSize = 20;
    @observable metricNameCase: FontCase = 'capitalize';

    @observable showMetricValue = true;
    @observable metricValueSize = 60;
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';

    @observable showMetricChange = true;
    @observable metricPercentChangeSize = 20;
    @observable highlightPercentChange: boolean;

    @observable showMetricPreviousValue?: boolean = false;
    @observable metricPreviousValueSize?: number = 8;
    @observable previousValueFormat?: DecimalFormat = '1k';
    @observable showTimeRemainder = true;
    @observable alignment: HAlign = 'center';
    @observable percentChangeColor: PercentChangeColor = {
        enabled: false,
        up: colors.sentiments.fern,
        zero: '',
        down: colors.sentiments.blush,
    };

    @observable cell: GridCell = {
        padding: 10,
        background: '',
        border: {
            enabled: true,
            color: '',
            size: 1,
        },
    };

    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            metricValueSize: 60,
            metricNameSize: 20,
            metricPercentChangeSize: 20,
            gridLayout: {
                gap: 20,
            },
        },
    };
    @observable gridLayout = new GridLayoutImpl();
    @observable metricNamePosition: MetricNamePosition = 'top';
}
