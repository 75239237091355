import BulkItem from './BulkItem';

export default class CommunityUser extends BulkItem {
    constructor(
        id: string,
        private fullName: string = null,
        private email: string = null,
        private profileImageUrl: string = null,
        private experienceType: string = null
    ) {
        super(id);
    }

    toString() {
        return this.fullName || this.id; // In some bulklookup results, there is no fullName
    }

    toImageUrl(): string {
        return this.profileImageUrl;
    }

    toEmail(): string {
        return this.email;
    }

    // Construct a CommunityUser from an object, checking for properties first.
    public static fromObject(object: any) {
        const allProps = ['id', 'experienceType', 'fullName'].every(prop =>
            object.hasOwnProperty(prop)
        );

        if (allProps) {
            return new CommunityUser(
                object.id,
                object.fullName,
                object.email,
                object.profileImageUrl,
                object.experienceType
            );
        }
    }

    get sortValue() {
        return this.fullName;
    }
}
