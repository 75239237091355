import { normalizeLabel } from 'utils/NormalizeLabel/NormalizeLabel';
import { SunburstOptions } from './types';
import {
    generateColorPalette,
    generateMonochromeColors,
    get3ColorRange,
    getColorRange,
    getColorRotation,
} from 'utils/GenerateColors/GenerateColors';
import Color from 'color';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { SunburstWidgetOptions } from './options';
import { rgb } from 'd3-color';

export const formatSunburstDataSet = (
    dataSet,
    maxItems: number,
    maxChildItems: number,
    firstDimensionIndex: number
) => {
    const data = {
        label: 'root',
        children: dataSet
            .groupBy(dataSet.dimensions[firstDimensionIndex])
            .map((item, rowIndex: number) => {
                const result: any = {
                    label: normalizeLabel(item.value + ' '),
                    group: rowIndex,
                };
                if (
                    item.data &&
                    item.data.rows &&
                    item.data.rows.length > 0 &&
                    item.data.rows[0].length > 1
                ) {
                    result.children = item.data.rows.map((row: any, index: number) => {
                        return {
                            label: row[0] + ' ',
                            value: row[1],
                            group: rowIndex,
                            index,
                        };
                    });
                } else {
                    result.value = item.data.rows[0];
                }
                return result;
            }),
    };
    data.children = data.children.slice(0, maxItems);
    data.children.forEach(item => {
        if (item.children && item.children.length) {
            item.children = item.children.slice(0, maxChildItems);
        }
    });

    return data;
};

export const generateChildCss = (
    cssOutput: string,
    item,
    index: number,
    colors: string[],
    cssPrefix
) => {
    item.children.forEach((child, i) => {
        cssOutput += `${cssPrefix} .wedge_index_${index + 1}.child_wedge_index_${i + 1}  { fill: ${
            colors[i]
        } }`;
    });

    return cssOutput;
};

export const getChildItemCss = (
    sunburst: SunburstOptions,
    innerColors: string[],
    item: any,
    index: number,
    output: string,
    cssPrefix: string
) => {
    if (item.children) {
        const { colorPalette } = sunburst.childItems;
        const { direction, hue, monochrome } = colorPalette;
        const innerColor = innerColors[index];
        let colors: string[] = [];

        switch (colorPalette.type) {
            case 'hue':
                const rotated = getColorRotation(innerColor, hue.rotation);
                switch (direction) {
                    case 'ascending':
                        colors = getColorRange(rotated.end, rotated.start, item.children.length);
                        break;
                    case 'descending':
                        colors = getColorRange(rotated.start, rotated.end, item.children.length);
                        break;
                    case 'neutral':
                        colors = get3ColorRange(
                            rotated.start,
                            innerColor,
                            rotated.end,
                            item.children.length
                        );
                        break;
                }
                break;
            case 'monochrome':
                const contrast = monochrome.endOffset * 0.01;
                switch (direction) {
                    case 'ascending':
                        colors = generateMonochromeColors(
                            innerColor,
                            contrast,
                            item.children.length,
                            true
                        );
                        break;
                    case 'descending':
                        colors = generateMonochromeColors(
                            innerColor,
                            contrast,
                            item.children.length,
                            false
                        );
                        break;
                    case 'neutral':
                        const start = rgb(
                            Color(innerColor.trim())
                                .lighten(contrast)
                                .toString()
                        ).toString();
                        const stop = rgb(
                            Color(innerColor.trim())
                                .darken(contrast)
                                .toString()
                        ).toString();
                        colors = get3ColorRange(start, innerColor, stop, item.children.length);
                        break;
                    default:
                        break;
                }
            case 'solid':
                // do nothing for solid
                break;
            default:
                break;
        }

        output = generateChildCss(output, item, index, colors, cssPrefix);
    }
    return output;
};

export const getSunburstCss: GetWidgetTypeStyles = (
    theme: Theme,
    dataSet: DataSet,
    widget: Widget,
    cssPrefix: null
): string => {
    let output = '';

    if (!theme || !dataSet || !widget) {
        return output;
    }
    const { sunburst } = widget.options as SunburstWidgetOptions;

    const currentDataSet = dataSet.filterRowsData();
    const firstDimension = currentDataSet.getFirstDimension();
    const firstDimensionIndex = currentDataSet.getDimensionIndex(firstDimension);

    const data = formatSunburstDataSet(
        currentDataSet,
        sunburst.maxItems,
        sunburst.childItems.max,
        firstDimensionIndex
    );
    const innerColors: string[] =
        generateColorPalette(theme, data.children.length, false, widget.theme) || [];

    data.children.forEach((item, index) => {
        output = getChildItemCss(sunburst, innerColors, item, index, output, cssPrefix);
    });
    return output;
};
