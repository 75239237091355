import { observable } from 'mobx';
import { AnimationValues, PostCompositionTypeValues } from '@sprinklr/stories/widget/types';
import { CommentsOptions } from 'components/Post/PostComment';
import { NativeStylingOptionsDefaultImpl } from '../../PostsWidget/options';
import { PostsWindowWidgetOptions, PostsWindowWidgetOptionsImpl } from '../options';

export class PostsWidgetSingleOptionsImpl extends PostsWindowWidgetOptionsImpl
    implements PostsWindowWidgetOptions {
    @observable postLayoutType = 'd';
    @observable postTextOnlySize = 20;
    @observable postImageTextSize = 20;
    @observable userGroupAvatarSize = 27;
    @observable postLayoutOrientation = 'horizontal';
    @observable animationType: AnimationValues = 'rotate';
    @observable socialIconBgColor = true;
    @observable userGroupLayoutType = 'c';
    @observable userGroupPosition = 'bottom';
    @observable showIconInUserGroup = false;
    @observable removeGenericPII = true;
    @observable textOnlyBackgroundColor = '';
    @observable imagePostBackgroundColor = '';
    @observable secondaryOverlayColor = '';
    @observable engagementMetricsPosition = 'top_right';
    @observable maxCharacters?: number = 400;
    @observable postCompositionType: PostCompositionTypeValues = 'a';
    @observable comments: CommentsOptions = {
        enabled: false,
        background: {
            color: 'rgba(0, 0, 0, .3)',
        },
        animation: {
            enabled: true,
            duration: 5,
        },
        comment: {
            background: {
                color: null,
                brandEnabled: false,
            },
            text: {
                color: null,
                size: 35,
                scale: {
                    enabled: true,
                    value: 50,
                },
                format: 'clamp',
                clamp: {
                    enabled: true,
                    lines: 4,
                },
                teleprompter: {
                    speed: 50,
                },
            },
            userGroup: {
                timeStamp: {
                    enabled: false,
                    size: 13,
                    format: 'timeAgo',
                    endianTime: 'D MMM YYYY, h:mm a',
                },
            },
        },
    };
    @observable nativeStyling = {
        ...new NativeStylingOptionsDefaultImpl(),
        ...{ fontScale: 45 },
    };
}
