import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { LegendOrientation, PanelOrientation, SmartSize } from '@sprinklr/stories/widget/types';
import { observable } from 'mobx';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { FontFamilyPreset } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { FontCase } from 'models/Theme/Theme';

export interface PieChartWidgetOptions extends WidgetOptions {
    data?: any;
    legendOrientation?: LegendOrientation;
    maxItems?: number;
    minWedgeRatio?: number;
    legendSize?: number;
    legendWrap?: boolean;
    panelOrientation?: PanelOrientation;
    labelLineSpace?: number;
    blacklist?: string[];
    customRatioSize?: {
        enabled: boolean;
        size: number;
    };
}

export class PieChartWidgetOptionsImpl extends WidgetOptionsImpl implements PieChartWidgetOptions {
    @observable showLegend = true;
    @observable legendOrientation: LegendOrientation = 'right';
    @observable legendWidth = 25;
    @observable legendHeight = 25;
    @observable showLegendRatio = true;
    @observable legendPercentageSize = 32;
    @observable legendPercentageFormat: DecimalFormat = '1.2k';
    @observable showLegendValue = false;
    @observable legendValueSize = 32;
    @observable legendValueFormat: DecimalFormat = '1k';
    @observable legendValueFontFamily: FontFamilyPreset = 'primary_font_family';
    @observable legendValueCase: FontCase = 'lowercase';
    @observable legendRatioFontFamily: FontFamilyPreset = 'primary_font_family';
    @observable legendWrap = false;
    @observable showLegendHeader = true;
    @observable headerFontFamily: FontFamilyPreset = 'secondary_font_family';
    @observable smartLegendHeader = false;
    @observable showLegendName = true;
    @observable legendNameSize = 20;
    @observable legendNameFontFamily: FontFamilyPreset = 'secondary_font_family';

    @observable showTimeRemainder = true;

    @observable showTotalValue = true;
    @observable totalValueSize = 40;
    @observable metricNameCase: FontCase = 'lowercase';
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';
    @observable metricValueSize: number = null;

    @observable showTotalValueMetric = true;
    @observable totalValueMetricSize = 22;

    @observable maxItems = 6;
    @observable zoom = 54;
    @observable offsetX = 0;
    @observable offsetY = 0;
    @observable showOtherItems = true;

    @observable innerRadiusSize = 20;
    @observable showInnerRadius = true;

    @observable lineWidth = 8;

    @observable customLabelMax = 3;
    @observable customLabelSize = 46;
    @observable showCustomLabel = false;

    @observable showCustomRatio = false;
    @observable customRatioFormat: DecimalFormat = '1.2k';
    @observable customRatioOpacity = 40;
    @observable customRatioSize = {
        enabled: false,
        size: 50,
    };

    @observable blacklist: string[] = [];

    // not exposed
    @observable minWedgeRatio = 3;
    @observable minLabelRatio = 3;
    @observable diameterPaddingRatio = 0.2;
    @observable labelRadiusRatio = 0.56;
    @observable labelLineSpace = 10;
    @observable secondaryLineSegmentRatio = 0.5;

    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            totalValueSize: 40,
            legendValueSize: 35,
            legendPercentageSize: 35,

            legendHeight: 25,
            legendNameSize: 20,
            totalValueMetricSize: 22,
            innerRadiusSize: 20,
            lineWidth: 8,
            customLabelSize: 46,
            legendOrientation: 'right',
            showCustomRatio: false,
            showCustomLabel: false,
            portrait: {
                legendPercentageSize: 35,
            },
        },
        optionDefaults: {
            showLegend: true,
            showCustomRatio: false,
            showCustomLabel: false,
            zoom: 54,
            legendWidth: 25,
            innerRadiusSize: 20,
            portrait: {
                zoom: 65,
                legendWidth: 100,
                innerRadiusSize: 18,
                showCustomRatio: false,
                showCustomLabel: false,
                legendOrientation: 'bottom',
            },
        },
        breakpoints: {
            xs: {
                options: {
                    showLegend: false,
                    legendOrientation: 'right',
                    zoom: 60,
                },
            },
            s: {
                options: {
                    legendOrientation: 'right',
                    zoom: 55,
                },
            },
            m: {
                options: {
                    legendOrientation: 'right',
                    zoom: 37,
                },
            },
            ml: {
                options: {
                    legendOrientation: 'right',
                    zoom: 45,
                },
            },
        },
    };
}
